import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { 
  Container, Grid, Box, Typography, Card, CardContent, Button, CircularProgress,
  Snackbar, Alert, createTheme, ThemeProvider, CssBaseline, TextField, 
  Pagination, Select, MenuItem, FormControl, InputLabel, Slider, Dialog, DialogContent, Chip
} from '@mui/material';
import { Search, DirectionsCar, LocalGasStation, Speed, ExpandMore, ExpandLess, FilterList } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import BrandPriorityManager from './BrandPriorityManager';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    background: {
      default: '#F8F9FA',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-5px)',
          },
        },
      },
    },
  },
});

const API_BASE_URL = 'https://admin.aitomotivelab.com/car_stock/api';

const CarCard = ({ car, canEdit }) => (
  <Card sx={{ 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  }}>
    <Box
      sx={{
        height: 200,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      {car.urlmainimage ? (
        <img
          src={car.urlmainimage}
          alt={`${car.brand} ${car.model}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ''; // Reset to show car icon on error
          }}
        />
      ) : (
        <DirectionsCar sx={{ fontSize: 60, color: 'grey.400' }} />
      )}
    </Box>
    <CardContent sx={{ flexGrow: 1, p: 3 }}>
      <Typography 
        gutterBottom 
        variant="h6" 
        component="div" 
        sx={{ 
          fontWeight: 'bold',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {car.brand} {car.model}
      </Typography>
      {/* Improved specs layout */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocalGasStation sx={{ mr: 1, color: 'secondary.main', fontSize: 20 }} />
            <Typography variant="body2">{car.fuel}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Speed sx={{ mr: 1, color: 'secondary.main', fontSize: 20 }} />
            <Typography variant="body2">{car.km?.toLocaleString()} km</Typography>
          </Box>
        </Grid>
      </Grid>
      <Typography 
        variant="h6" 
        color="primary" 
        sx={{ 
          fontWeight: 'bold',
          fontSize: '1.5rem',
          textAlign: 'right' 
        }}
      >
        €{car.saleprice?.toLocaleString()}
      </Typography>
    </CardContent>
    {canEdit && (
      <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
        <Button 
          component={Link} 
          to={`/cars/${car.vehicleid}/edit`} 
          variant="contained" 
          fullWidth
          sx={{ borderRadius: 2 }}
        >
          Edit
        </Button>
      </Box>
    )}
  </Card>
);

const CarStockList = () => {
  const [carStocks, setCarStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    min_price: '',
    max_price: '',
    min_year: '',
    max_year: '',
    min_km: '',
    max_km: '',
    brand: '',
    model: '',
    fuel: '',
    bodystyle: '',
    transmission: '',
    color: '',
  });
  const [filterOptions, setFilterOptions] = useState({
    brands: [],
    fuel_types: [],
    body_styles: [],
    price_range: { min_price: 0, max_price: 100000 },
    year_range: { min_year: 2000, max_year: new Date().getFullYear() },
  });

  const [openBrandPriority, setOpenBrandPriority] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const ITEMS_PER_PAGE = 20;

  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['car_stock'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/carstocks/filter_options/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setFilterOptions(response.data);
    } catch (error) {
      console.error('Error fetching filter options:', error);
      setSnackbarMessage('Failed to fetch filter options. Please refresh the page.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/carstocks/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          params: {
            ...filters,
            search: searchTerm,
            page,
            limit: ITEMS_PER_PAGE
          }
        });

        const newCars = response.data.results;
        
        if (page > 1) {
          setCarStocks(prevCars => [...prevCars, ...newCars]);
        } else {
          setCarStocks(newCars);
        }

        console.log('Car data:', response.data.results[0]);
      } catch (error) {
        console.error('Error fetching car stocks:', error);
        setError('An error occurred while fetching car stocks. Please try again.');
        setSnackbarMessage('Failed to fetch results. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [filters, searchTerm, page]);

  // Load more handler
  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSearchChange = (event) => {
    setCarStocks([]); // Clear existing results
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleFilterChange = (event) => {
    setCarStocks([]); // Clear existing results
    setFilters({ ...filters, [event.target.name]: event.target.value });
    setPage(1);
  };

  const handlePriceChange = (event, newValue) => {
    setCarStocks([]); // Clear existing results
    setFilters({ ...filters, min_price: newValue[0], max_price: newValue[1] });
    setPage(1);
  };

  const handleYearChange = (event, newValue) => {
    setCarStocks([]); // Clear existing results
    setFilters({ ...filters, min_year: newValue[0], max_year: newValue[1] });
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenBrandPriority = () => {
    if (!canEdit) {
      setSnackbarMessage('You do not have permission to manage brand priorities.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setOpenBrandPriority(true);
  };

  const handleCloseBrandPriority = () => {
    setOpenBrandPriority(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        flexGrow: 1, 
        minHeight: '100vh', 
        backgroundColor: '#f8f9fa',
        overflowX: 'hidden'
      }}>
        <Container 
          maxWidth={false}
          sx={{ 
            pt: 4, 
            pb: 6,
            px: { xs: 2, sm: 3, md: 4 },
            maxWidth: {
              xs: '100%',
              lg: '1800px'
            },
            mx: 'auto'
          }}
        >
          {/* Header section */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 'bold', 
                color: 'primary.main', 
                textAlign: 'center',
                mb: 2 
              }}
            >
              Car Stock Management
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              gap: 2 // Add space between buttons
            }}>
              {canEdit && (
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={handleOpenBrandPriority}
                  sx={{ 
                    borderRadius: 2,
                    px: 4,
                    py: 1,
                    fontSize: '1rem'
                  }}
                >
                  Manage Brand Priorities
                </Button>
              )}
            </Box>
          </Box>

          {/* Search and Filter toggle */}
          <Card sx={{ 
            mb: 3, 
            p: { xs: 2, sm: 3 },
            width: '100%',
            boxSizing: 'border-box'
          }}>
            <Grid 
              container 
              spacing={2} 
              alignItems="center"
              sx={{ 
                width: '100%',
                m: 0,
                '& .MuiGrid-item': {
                  pl: { xs: 2, sm: 3 },
                  pr: { xs: 2, sm: 3 },
                  width: '100%',
                  boxSizing: 'border-box'
                }
              }}
            >
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Search cars"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <Search color="action" />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setShowFilters(!showFilters)}
                  startIcon={<FilterList />}
                  endIcon={showFilters ? <ExpandLess /> : <ExpandMore />}
                  sx={{ height: '56px' }} // Match height with search field
                >
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>
              </Grid>
            </Grid>
          </Card>

          {/* Collapsible Filters Section */}
          <Box sx={{
            maxHeight: showFilters ? '2000px' : '0px',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out',
            width: '100%',
            boxSizing: 'border-box'
          }}>
            <Card sx={{ 
              mb: 4, 
              p: { xs: 2, sm: 3 },
              width: '100%',
              boxSizing: 'border-box'
            }}>
              <Grid 
                container 
                spacing={3}
                sx={{ 
                  width: '100%',
                  m: 0,
                  boxSizing: 'border-box',
                  '& .MuiGrid-item': {
                    pl: { xs: 2, sm: 3 },
                    pr: { xs: 2, sm: 3 },
                    boxSizing: 'border-box'
                  }
                }}
              >
                {/* Brand filter */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Brand</InputLabel>
                    <Select
                      name="brand"
                      value={filters.brand}
                      onChange={handleFilterChange}
                      label="Brand"
                    >
                      <MenuItem value="">All Brands</MenuItem>
                      {filterOptions.brands.map((brand) => (
                        <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Model filter */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Model</InputLabel>
                    <Select
                      name="model"
                      value={filters.model}
                      onChange={handleFilterChange}
                      label="Model"
                    >
                      <MenuItem value="">All Models</MenuItem>
                      {filterOptions.models?.map((model) => (
                        <MenuItem key={model} value={model}>{model}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Fuel type filter */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Fuel Type</InputLabel>
                    <Select
                      name="fuel"
                      value={filters.fuel}
                      onChange={handleFilterChange}
                      label="Fuel Type"
                    >
                      <MenuItem value="">All Fuel Types</MenuItem>
                      {filterOptions.fuel_types.map((fuel) => (
                        <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Body style filter */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Body Style</InputLabel>
                    <Select
                      name="bodystyle"
                      value={filters.bodystyle}
                      onChange={handleFilterChange}
                      label="Body Style"
                    >
                      <MenuItem value="">All Body Styles</MenuItem>
                      {filterOptions.body_styles.map((style) => (
                        <MenuItem key={style} value={style}>{style}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Price range slider */}
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom>Price Range (€)</Typography>
                  <Box sx={{ px: 2 }}>
                    <Slider
                      value={[
                        filters.min_price || filterOptions.price_range.min_price,
                        filters.max_price || filterOptions.price_range.max_price
                      ]}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={filterOptions.price_range.min_price}
                      max={filterOptions.price_range.max_price}
                    />
                  </Box>
                </Grid>

                {/* Kilometer range slider */}
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom>Kilometer Range</Typography>
                  <Box sx={{ px: 2 }}>
                    <Slider
                      value={[
                        filters.min_km || 0,
                        filters.max_km || 300000
                      ]}
                      onChange={(event, newValue) => {
                        setFilters({
                          ...filters,
                          min_km: newValue[0],
                          max_km: newValue[1]
                        });
                      }}
                      valueLabelDisplay="auto"
                      min={0}
                      max={300000}
                      step={1000}
                    />
                  </Box>
                </Grid>

                {/* Year range slider */}
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom>Year Range</Typography>
                  <Box sx={{ px: 2 }}>
                    <Slider
                      value={[
                        filters.min_year || filterOptions.year_range.min_year,
                        filters.max_year || filterOptions.year_range.max_year
                      ]}
                      onChange={handleYearChange}
                      valueLabelDisplay="auto"
                      min={filterOptions.year_range.min_year}
                      max={filterOptions.year_range.max_year}
                      step={1}
                    />
                  </Box>
                </Grid>

                {/* Reset filters button */}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setFilters({
                        min_price: '',
                        max_price: '',
                        min_year: '',
                        max_year: '',
                        min_km: '',
                        max_km: '',
                        brand: '',
                        model: '',
                        fuel: '',
                        bodystyle: '',
                        transmission: '',
                        color: '',
                      });
                      setSearchTerm('');
                    }}
                  >
                    Reset Filters
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Box>

          {/* Active Filters Display */}
          {Object.values(filters).some(value => value !== '') && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Active Filters:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.entries(filters).map(([key, value]) => {
                  if (value) {
                    return (
                      <Chip
                        key={key}
                        label={`${key.replace('_', ' ')}: ${value}`}
                        onDelete={() => handleFilterChange({
                          target: { name: key, value: '' }
                        })}
                        sx={{ textTransform: 'capitalize' }}
                      />
                    );
                  }
                  return null;
                })}
              </Box>
            </Box>
          )}

          {/* Loading and results section */}
          {loading && carStocks.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
              <CircularProgress size={60} />
            </Box>
          ) : (
            <>
              {carStocks.length === 0 && !loading ? (
                <Card sx={{ p: 4, textAlign: 'center' }}>
                  <Typography variant="h6" color="text.secondary">
                    No results found. Try adjusting your search or filters.
                  </Typography>
                </Card>
              ) : (
                <>
                  <Grid 
                    container 
                    spacing={{ xs: 2, sm: 3 }}
                    sx={{ 
                      width: '100%',
                      m: 0,
                      '& .MuiGrid-item': {
                        pl: { xs: 2, sm: 3 },
                        pr: { xs: 2, sm: 3 }
                      }
                    }}
                  >
                    {carStocks.map((car, index) => (
                      <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        lg={4} 
                        xl={3} 
                        key={`${car.vehicleid}-${index}`}
                        sx={{ mb: 3 }}
                      >
                        <CarCard car={car} canEdit={canEdit} />
                      </Grid>
                    ))}
                  </Grid>

                  {carStocks.length > 0 && (
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      mt: 4, 
                      mb: 6 
                    }}>
                      <Button
                        variant="contained"
                        onClick={handleLoadMore}
                        disabled={loading}
                        sx={{ 
                          px: 4, 
                          py: 1,
                          borderRadius: 2,
                          minWidth: 200
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          'Load More'
                        )}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Container>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog open={openBrandPriority} onClose={handleCloseBrandPriority} fullWidth maxWidth="md">
          <DialogContent>
            <BrandPriorityManager availableBrands={filterOptions.brands} />
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default CarStockList;