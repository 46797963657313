import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, CssBaseline, ThemeProvider, Grow, Fade, Grid } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useAuth } from '../components/contexts/AuthContext';
import Analytics from '../components/analytics/Analytics';
import useMediaQuery from '@mui/material/useMediaQuery';

const tema = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    background: {
      default: '#F0F2F5',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h2: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 600,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100% !important',
          width: '100%',
          overflowX: 'hidden',
          '@media (max-width:600px)': {
            padding: '0 16px',
            maxWidth: '100%'
          }
        }
      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          overflowX: 'hidden'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden',
          borderRadius: { xs: 12, sm: 16 },
          padding: { xs: 2, sm: 3, md: 4 },
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 12px 30px 0 rgba(0,0,0,0.1)',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: {
            xs: '1.75rem',
            sm: '2rem',
            md: '2.5rem',
          },
          lineHeight: 1.2,
        },
        h6: {
          fontSize: {
            xs: '1rem',
            sm: '1.15rem',
            md: '1.25rem',
          },
        },
      },
    },
  },
});

const Dashboard = () => {
  const [controlloAnimazione, setControlloAnimazione] = useState(false);
  const { user, appAccess } = useAuth();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:960px)');
  const canViewChats = appAccess['ai_app']?.can_view || user.role === 'superadmin';

  useEffect(() => {
    setControlloAnimazione(true);
  }, []);

  // Safely access user properties
  const NomeUtente = user ? `${user.first_name || ''} ${user.last_name || ''}`.trim() : 'Utente';
  const companyName = user?.companies?.[0]?.name || 'Azienda';
  const companyId = user?.companies?.[0]?.id;

  return (
    <ThemeProvider theme={tema}>
      <CssBaseline />
      <Box 
        sx={{ 
          minHeight: '100vh',
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden',
          backgroundColor: tema.palette.background.default,
          pt: { xs: 2, sm: 4, md: 6 },
          pb: { xs: 4, sm: 6, md: 8 }
        }}
      >
        <Container 
          disableGutters
          sx={{ 
            px: { xs: 2, sm: 3, md: 4 },
            width: '100%',
            maxWidth: '100% !important',
            boxSizing: 'border-box',
            overflowX: 'hidden'
          }}
        >
          <Fade in={controlloAnimazione} timeout={1000}>
            <Box sx={{ 
              width: '100%',
              maxWidth: '100%',
              overflowX: 'hidden'
            }}>
              <Typography 
                variant="h2" 
                sx={{ 
                  fontSize: { 
                    xs: '1.5rem',
                    sm: '2.25rem', 
                    md: '2.75rem' 
                  },
                  lineHeight: { xs: 1.3, sm: 1.2 },
                  mb: { xs: 1, sm: 2 },
                  color: tema.palette.primary.main,
                  wordBreak: 'break-word',
                  textAlign: { xs: 'center', sm: 'left' },
                  width: '100%'
                }}
              >
                Bentornato, <Box component="span" sx={{ color: tema.palette.secondary.main }}>{NomeUtente}</Box>
              </Typography>
              
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: { xs: '0.875rem', sm: '1.1rem', md: '1.25rem' },
                  color: 'text.secondary',
                  textAlign: { xs: 'center', sm: 'left' },
                  width: '100%'
                }}
              >
                Ecco la tua dashboard AItomotivelab per {companyName}
              </Typography>
            </Box>
          </Fade>

          <Grid 
            container 
            spacing={{ xs: 2, sm: 3, md: 4 }} 
            sx={{ 
              width: '100%',
              mx: 0,
              maxWidth: '100%',
              overflowX: 'hidden'
            }}
          >
            <Grid item xs={12}>
              <Analytics 
                companyId={companyId} 
                isMobile={isMobile}
                isTablet={isTablet}
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  overflowX: 'hidden',
                  '& .MuiCard-root': {
                    width: '100%',
                    maxWidth: '100%',
                    overflowX: 'hidden'
                  }
                }}
              />
            </Grid>
          </Grid>
          {canViewChats && (
            <Fade in={controlloAnimazione} timeout={1000}>
              <Box>
                {/* Remove or comment out the following line */}
                {/* <Typography variant="h2">Chat Recenti</Typography> */}
                {/* Chat component or content */}
              </Box>
            </Fade>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;