import React, { useState, useMemo } from 'react';
import { 
  Box, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem,
  Button, Collapse, Badge, useMediaQuery, useTheme, Typography
} from '@mui/material';
import { Search, Filter, ChevronDown, ChevronUp, X as CloseIcon } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ChatFiltersSection = ({ filters, handleFilterChange, isMobileDialog, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDateChange = (index, value) => {
    const newDateRange = [...filters.date_range];
    newDateRange[index] = value ? new Date(value) : null;
    handleFilterChange('date_range', newDateRange);
  };

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (filters.is_connected !== null) count++;
    if (filters.human_control !== null) count++;
    if (filters.date_range[0] || filters.date_range[1]) count++;
    return count;
  }, [filters]);

  const handleClearAllFilters = () => {
    // Clear the temporary filters
    setTempFilters({
      ...tempFilters,
      search: '',
      is_connected: null,
      human_control: null,
      date_range: [null, null]
    });

    // Clear the actual filters
    handleFilterChange('search', '');
    handleFilterChange('is_connected', null);
    handleFilterChange('human_control', null);
    handleFilterChange('date_range', [null, null]);

    // Close the filter dialog
    onClose?.();
  };

  // Add new state to track temporary filters
  const [tempFilters, setTempFilters] = useState(filters);

  // New handler for temporary filter changes
  const handleTempFilterChange = (field, value) => {
    setTempFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // New handler for temporary date changes
  const handleTempDateChange = (index, value) => {
    const newDateRange = [...tempFilters.date_range];
    newDateRange[index] = value ? new Date(value) : null;
    setTempFilters(prev => ({
      ...prev,
      date_range: newDateRange
    }));
  };

  // Simplified apply filters handler
  const handleApplyFilters = () => {
    // Apply all filters at once
    Object.entries(tempFilters).forEach(([field, value]) => {
      handleFilterChange(field, value);
    });
    
    // Always close the filter page when applying filters
    onClose?.();
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          {activeFiltersCount > 0 ? `${activeFiltersCount} filter${activeFiltersCount > 1 ? 's' : ''} applied` : 'No filters applied'}
        </Typography>
        {activeFiltersCount > 0 && (
          <Button 
            variant="outlined" 
            color="primary" 
            size="small"
            onClick={handleClearAllFilters}
            startIcon={<CloseIcon size={16} />}
          >
            Remove all filters
          </Button>
        )}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Connection Status</InputLabel>
          <Select
            value={tempFilters.is_connected === null ? '' : tempFilters.is_connected.toString()}
            onChange={(e) => handleTempFilterChange('is_connected', e.target.value === '' ? null : e.target.value === 'true')}
            label="Connection Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="true">Connected</MenuItem>
            <MenuItem value="false">Disconnected</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Human Control</InputLabel>
          <Select
            value={tempFilters.human_control === null ? '' : tempFilters.human_control.toString()}
            onChange={(e) => handleTempFilterChange('human_control', e.target.value === '' ? null : e.target.value === 'true')}
            label="Human Control"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            value={tempFilters.date_range[0] ? tempFilters.date_range[0].toISOString().split('T')[0] : ''}
            onChange={(e) => handleTempDateChange(0, e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            label="End Date"
            type="date"
            value={tempFilters.date_range[1] ? tempFilters.date_range[1].toISOString().split('T')[0] : ''}
            onChange={(e) => handleTempDateChange(1, e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Box>
        
        {/* Add Apply Filters button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyFilters}
          sx={{ mt: 2 }}
        >
          Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default ChatFiltersSection;