import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  IconButton,
  Paper
} from '@mui/material';
import { 
  MessageCircle, 
  Mail, 
  UserPlus, 
  Share2, 
  FileText,
  ChevronRight,
  Car,
  Users,
  X
} from 'lucide-react';

const primaryGoals = [
  { id: 'info', icon: <MessageCircle />, label: 'Richiesta informazioni' },
  { id: 'email', icon: <Mail />, label: 'Rispondere ad una mail' },
  { id: 'lead', icon: <UserPlus />, label: 'Impostare un primo contatto con un lead' },
  { id: 'social', icon: <Share2 />, label: 'Creare un contenuto social' },
  { id: 'summary', icon: <FileText />, label: 'Riassumere una comunicazione' }
];

const secondaryGoals = {
  info: [
    'Informazioni su un veicolo specifico',
    'Confronto tra veicoli',
    'Disponibilità e prezzi',
    'Caratteristiche tecniche'
  ],
  email: [
    'Risposta a richiesta preventivo',
    'Follow-up dopo test drive',
    'Gestione reclamo',
    'Conferma appuntamento'
  ],
  lead: [
    'Primo contatto dopo richiesta online',
    'Qualificazione lead',
    'Invito test drive',
    'Presentazione offerta'
  ],
  social: [
    'Post nuovo arrivo veicolo',
    'Promozione servizi',
    'Storia di successo cliente',
    'Evento showroom'
  ],
  summary: [
    'Sintesi conversazione cliente',
    'Riepilogo trattativa',
    'Report attività settimanale',
    'Analisi feedback clienti'
  ]
};

const PromptStructureDialog = ({ 
  open, 
  onClose, 
  onGoalsSelected,
  selectedCars = [],
  selectedClients = [],
  onRemoveCar,
  onRemoveClient
}) => {
  const [selectedPrimaryGoal, setSelectedPrimaryGoal] = useState('');
  const [selectedSecondaryGoal, setSelectedSecondaryGoal] = useState('');

  const handlePrimaryGoalChange = (event) => {
    setSelectedPrimaryGoal(event.target.value);
    setSelectedSecondaryGoal('');
  };

  const handleSecondaryGoalChange = (event) => {
    setSelectedSecondaryGoal(event.target.value);
  };

  const handleConfirm = () => {
    if (selectedPrimaryGoal && selectedSecondaryGoal) {
      onGoalsSelected({
        primaryGoal: selectedPrimaryGoal,
        secondaryGoal: selectedSecondaryGoal
      });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Seleziona l'obiettivo della conversazione</Typography>
      </DialogTitle>
      
      <DialogContent>
        {/* Knowledge Section */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>Conoscenza Selezionata</Typography>
          
          {/* Cars Knowledge */}
          {selectedCars.length > 0 && (
            <Paper variant="outlined" sx={{ mb: 2, p: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                <Car size={18} />
                Veicoli
              </Typography>
              <List dense>
                {selectedCars.map((car) => (
                  <ListItem key={car.id} sx={{ py: 0.5 }}>
                    <ListItemText 
                      primary={`${car.brand} ${car.model} (${car.registrationyear})`}
                      secondary={`${car.saleprice}€`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        size="small" 
                        onClick={() => onRemoveCar(car)}
                        sx={{ color: 'error.main' }}
                      >
                        <X size={16} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}

          {/* Clients Knowledge */}
          {selectedClients.length > 0 && (
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                <Users size={18} />
                Clienti
              </Typography>
              <List dense>
                {selectedClients.map((client) => (
                  <ListItem key={client.id} sx={{ py: 0.5 }}>
                    <ListItemText 
                      primary={client.name}
                      secondary={client.email}
                    />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        size="small" 
                        onClick={() => onRemoveClient(client)}
                        sx={{ color: 'error.main' }}
                      >
                        <X size={16} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}

          {selectedCars.length === 0 && selectedClients.length === 0 && (
            <Typography color="text.secondary" sx={{ fontStyle: 'italic' }}>
              Nessuna conoscenza selezionata
            </Typography>
          )}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Goals Section */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>Obiettivo Primario</Typography>
          <RadioGroup value={selectedPrimaryGoal} onChange={handlePrimaryGoalChange}>
            {primaryGoals.map((goal) => (
              <FormControlLabel
                key={goal.id}
                value={goal.id}
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {goal.icon}
                    <Typography>{goal.label}</Typography>
                  </Box>
                }
              />
            ))}
          </RadioGroup>

          <Collapse in={!!selectedPrimaryGoal}>
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>Obiettivo Secondario</Typography>
              <RadioGroup value={selectedSecondaryGoal} onChange={handleSecondaryGoalChange}>
                {selectedPrimaryGoal && secondaryGoals[selectedPrimaryGoal].map((goal, index) => (
                  <FormControlLabel
                    key={index}
                    value={goal}
                    control={<Radio />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ChevronRight size={16} />
                        <Typography>{goal}</Typography>
                      </Box>
                    }
                  />
                ))}
              </RadioGroup>
            </Box>
          </Collapse>
        </Box>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Annulla
        </Button>
        <Button 
          onClick={handleConfirm}
          color="primary"
          disabled={!selectedPrimaryGoal || !selectedSecondaryGoal}
        >
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptStructureDialog; 