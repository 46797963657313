import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useChatWebSocket from './websocket';
import { initializeApi, searchCars, searchClients } from './api';

// Import components
import Header from './components/Header';
import ChatPanel from './components/ChatPanel';
import SearchPanel from './components/SearchPanel';
import PromptStructureDialog from './components/PromptStructureDialog';

// Add any utils imports from the correct location
import { formatPrice, truncateText } from "../../utils/utils";

const InternalAssistant = ({ open, onClose, darkMode }) => {
  const [message, setMessage] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
  const [showPromptStructure, setShowPromptStructure] = useState(false);
  const [searchTab, setSearchTab] = useState('cars');
  
  // Search states
  const [carSearchTerm, setCarSearchTerm] = useState('');
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [clientSearchResults, setClientSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    brand: '',
    model: '',
    min_price: '',
    max_price: '',
    min_year: '',
    max_year: '',
    min_km: '',
    max_km: '',
    fuel: '',
  });
  const [filterOptions, setFilterOptions] = useState({
    brands: [],
    models: [],
    fuel_types: [],
    price_range: { min_price: 0, max_price: 200000 },
    year_range: { min_year: 1990, max_year: new Date().getFullYear() }
  });

  const chatContainerRef = useRef(null);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  const {
    messages,
    setMessages,
    isLoading,
    handleSend,
    error,
    initializeWebSocket,
    cleanup
  } = useChatWebSocket();

  // Search debounce timeouts
  const carSearchTimeout = useRef(null);
  const clientSearchTimeout = useRef(null);

  // Add to existing state declarations
  const [systemPrompt, setSystemPrompt] = useState('');
  const [selectedCars, setSelectedCars] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);

  useEffect(() => {
    if (open) {
      initializeWebSocket();
      initializeApi();
    }
    return cleanup;
  }, [open, initializeWebSocket, cleanup]);

  // Handle car search
  useEffect(() => {
    if (carSearchTimeout.current) {
      clearTimeout(carSearchTimeout.current);
    }

    if (carSearchTerm.trim()) {
      setSearchLoading(true);
      carSearchTimeout.current = setTimeout(async () => {
        try {
          const results = await searchCars(carSearchTerm, filters);
          setSearchResults(results);
        } catch (error) {
          console.error('Error searching cars:', error);
        } finally {
          setSearchLoading(false);
        }
      }, 500);
    } else {
      setSearchResults([]);
    }

    return () => {
      if (carSearchTimeout.current) {
        clearTimeout(carSearchTimeout.current);
      }
    };
  }, [carSearchTerm, filters]);

  // Handle client search
  useEffect(() => {
    if (clientSearchTimeout.current) {
      clearTimeout(clientSearchTimeout.current);
    }

    if (clientSearchTerm.trim()) {
      setClientSearchLoading(true);
      clientSearchTimeout.current = setTimeout(async () => {
        try {
          const results = await searchClients(clientSearchTerm);
          setClientSearchResults(results);
        } catch (error) {
          console.error('Error searching clients:', error);
        } finally {
          setClientSearchLoading(false);
        }
      }, 500);
    } else {
      setClientSearchResults([]);
    }

    return () => {
      if (clientSearchTimeout.current) {
        clearTimeout(clientSearchTimeout.current);
      }
    };
  }, [clientSearchTerm]);

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddCarKnowledge = (car) => {
    setSelectedCars(prev => [...prev, car]);
    setSearchResults(prevResults => 
      prevResults.map(result => 
        result.id === car.id ? { ...result, highlighted: true } : result
      )
    );
  };

  const handleAddClientKnowledge = (client) => {
    setSelectedClients(prev => [...prev, client]);
    setClientSearchResults(prevResults => 
      prevResults.map(result => 
        result.id === client.id ? { ...result, highlighted: true } : result
      )
    );
  };

  const handleRemoveCar = (carToRemove) => {
    console.log('Removing car:', carToRemove);
    console.log('Current selected cars:', selectedCars);
    
    setSelectedCars(prevCars => {
      const updatedCars = prevCars.filter(car => car.vehicleid !== carToRemove.vehicleid);
      console.log('Updated selected cars:', updatedCars);
      return updatedCars;
    });
    
    setSearchResults(prevResults => 
      prevResults.map(result => 
        result.vehicleid === carToRemove.vehicleid ? { ...result, highlighted: false } : result
      )
    );
  };

  const handleRemoveClient = (client) => {
    setSelectedClients(prev => prev.filter(c => c.id !== client.id));
    setClientSearchResults(prevResults => 
      prevResults.map(result => 
        result.id === client.id ? { ...result, highlighted: false } : result
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;
    await handleSend(message);
    setMessage('');
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && !file.type.startsWith('image/')) {
      console.error('Please upload only image files');
      return;
    }
    // Handle file upload logic
  };

  const toggleFullscreen = () => {
    if (!isFullscreen && !isSearchOpen) {
      setIsSearchOpen(true);
    }
    setIsFullscreen(!isFullscreen);
  };

  // Add this handler
  const handleGoalsSelected = ({ primaryGoal, secondaryGoal }) => {
    // Define all possible prompts
    const prompts = {
      info: {
        'Informazioni su un veicolo specifico': 'Sei un esperto di automobili che fornisce informazioni dettagliate e precise sui veicoli...',
        'Confronto tra veicoli': 'Sei un consulente automobilistico specializzato nel confrontare veicoli...',
        'Disponibilità e prezzi': 'Sei un consulente commerciale esperto nella gestione di richieste su disponibilità e prezzi...',
        'Caratteristiche tecniche': 'Sei un tecnico specializzato che fornisce informazioni dettagliate sulle caratteristiche tecniche...'
      },
      email: {
        'Risposta a richiesta preventivo': 'Sei un professionista della comunicazione specializzato nella gestione di richieste preventivi...',
        'Follow-up dopo test drive': 'Sei un consulente commerciale specializzato nel follow-up dopo test drive...'
      },
      lead: {
        'Primo contatto': 'Sei un consulente commerciale specializzato nel primo contatto con potenziali clienti...',
        'Qualificazione lead': 'Sei un esperto nella qualificazione dei lead...'
      },
      social: {
        'Post nuovo arrivo veicolo': 'Sei un social media manager specializzato nella creazione di contenuti per nuovi arrivi...',
        'Post promozione': 'Sei un social media manager specializzato nella creazione di contenuti promozionali...',
        'Post evento': 'Sei un social media manager specializzato nella creazione di contenuti per eventi...'
      },
      summary: {
        'Riassunto comunicazione': 'Sei un assistente specializzato nel riassumere comunicazioni...',
        'Punti chiave': 'Sei un analista specializzato nell\'identificazione dei punti chiave...'
      }
    };

    // Check if the prompt exists
    if (!prompts[primaryGoal] || !prompts[primaryGoal][secondaryGoal]) {
      console.error('Prompt not found for:', { primaryGoal, secondaryGoal });
      return;
    }

    // Set the system prompt
    setSystemPrompt(prompts[primaryGoal][secondaryGoal]);
  };

  const handleResetConversation = useCallback(() => {
    // Reset messages
    setMessages([]);
    
    // Reset search results and terms
    setSearchResults([]);
    setClientSearchResults([]);
    setCarSearchTerm('');
    setClientSearchTerm('');
    
    // Reset filters
    setFilters({
      brand: '',
      model: '',
      fuel: '',
      min_price: '',
      max_price: '',
      min_year: '',
      max_year: '',
      min_km: '',
      max_km: '',
    });
    
    // Reset chat ID in localStorage
    localStorage.removeItem('aitomotivelab_personalData_chat_id');
    
    // Reset WebSocket connection
    if (cleanup) {
      cleanup();
    }
    if (initializeWebSocket) {
      initializeWebSocket();
    }
  }, [cleanup, initializeWebSocket, setMessages, setSearchResults, setClientSearchResults, setCarSearchTerm, setClientSearchTerm, setFilters]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: isFullscreen ? '100%' : { 
              xs: '100%', 
              sm: isSearchOpen ? '1200px' : '600px'
            },
            height: { 
              xs: 'calc(100% - 64px)',
              md: 'calc(100% - 80px)'
            },
            top: { xs: '64px', md: '80px' },
            bgcolor: darkMode ? '#1E1E1E' : '#FFFFFF',
            color: darkMode ? '#FFFFFF' : '#000000',
            transition: 'width 0.3s ease-in-out',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          },
        }}
      >
        <Box sx={{ 
          display: 'flex',
          flexGrow: 1,
          overflow: 'hidden',
          flexDirection: 'row-reverse',
          maxHeight: '100%',
          position: 'relative'
        }}>
          {/* Chat Panel */}
          <Box sx={{ 
            width: isFullscreen ? (isSearchOpen ? '50%' : '100%') : { xs: '100%', sm: '600px' },
            display: isMobile && mobileSearchVisible ? 'none' : 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            height: '100%',
            overflow: 'hidden'
          }}>
            <Header 
              isMobile={isMobile}
              setMobileSearchVisible={setMobileSearchVisible}
              setShowPromptStructure={setShowPromptStructure}
              onClose={onClose}
              setIsSearchOpen={setIsSearchOpen}
              toggleFullscreen={toggleFullscreen}
              isFullscreen={isFullscreen}
              darkMode={darkMode}
              onResetConversation={handleResetConversation}
            />
            
            <ChatPanel 
              messages={messages}
              isLoading={isLoading}
              error={error}
              message={message}
              setMessage={setMessage}
              handleSubmit={handleSubmit}
              handleFileUpload={handleFileUpload}
              darkMode={darkMode}
              chatContainerRef={chatContainerRef}
              isMobile={isMobile}
            />
          </Box>

          {/* Search Panel */}
          {(isSearchOpen || mobileSearchVisible) && (
            <Box sx={{ 
              width: isFullscreen ? '50%' : { xs: '100%', sm: '600px' },
              height: '100%',
              overflow: 'auto',
              borderRight: 1,
              borderColor: 'divider',
              ...(isMobile ? {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 2000,
                bgcolor: darkMode ? '#1E1E1E' : '#FFFFFF',
              } : {
                position: 'relative',
                display: isFullscreen ? 'block' : { xs: 'none', sm: 'block' },
              })
            }}>
              <SearchPanel 
                searchTab={searchTab}
                setSearchTab={setSearchTab}
                carSearchTerm={carSearchTerm}
                setCarSearchTerm={setCarSearchTerm}
                clientSearchTerm={clientSearchTerm}
                setClientSearchTerm={setClientSearchTerm}
                searchResults={searchResults}
                clientSearchResults={clientSearchResults}
                searchLoading={searchLoading}
                clientSearchLoading={clientSearchLoading}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                filters={filters}
                filterOptions={filterOptions}
                handleFilterChange={handleFilterChange}
                handleAddCarKnowledge={handleAddCarKnowledge}
                handleAddClientKnowledge={handleAddClientKnowledge}
                isFullscreen={isFullscreen}
                darkMode={darkMode}
                setSearchResults={setSearchResults}
                setClientSearchResults={setClientSearchResults}
                setSearchLoading={setSearchLoading}
                setClientSearchLoading={setClientSearchLoading}
                setFilters={setFilters}
                setFilterOptions={setFilterOptions}
                isMobile={isMobile}
                onClose={() => {
                  setIsSearchOpen(false);
                  setMobileSearchVisible(false);
                }}
                showCloseButton={!isFullscreen}
              />
            </Box>
          )}
        </Box>
      </Drawer>

      <PromptStructureDialog 
        open={showPromptStructure} 
        onClose={() => setShowPromptStructure(false)}
        onGoalsSelected={handleGoalsSelected}
        selectedCars={selectedCars}
        selectedClients={selectedClients}
        onRemoveCar={handleRemoveCar}
        onRemoveClient={handleRemoveClient}
      />
    </>
  );
};

export default React.memo(InternalAssistant); 