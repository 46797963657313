import React from 'react';
import { Box, Typography, Button, Paper, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ChevronDown, Smartphone, Download, Monitor, Apple, Chrome, Share, Plus, MoreVertical } from 'lucide-react';
import { motion } from 'framer-motion';

const InstallGuide = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Paper elevation={0} sx={{ p: 4, borderRadius: 4 }}>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <motion.img 
              src="/AitomotiveLab.svg"
              alt="AITomotive Logo"
              style={{ height: '80px', marginBottom: '2rem' }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            />
            <Typography variant="h3" gutterBottom>
              Grazie per averci scelto
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Segui i passaggi per il tuo dispositivo e scarica la nostra applicazione in men che non si dica!
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Desktop Section */}
            <Accordion>
              <AccordionSummary expandIcon={<ChevronDown />}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Monitor />
                  <Typography variant="h6">Desktop (Chrome, Edge, etc.)</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Per installare l'app sul tuo computer:
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <Chrome size={24} />
                  <Typography>
                    Cerca l'icona di installazione nella barra degli indirizzi →
                  </Typography>
                  <Download size={24} />
                </Box>
                <Box sx={{ pl: 4, borderLeft: '2px solid', borderColor: 'primary.main' }}>
                  <Typography paragraph>
                    1. L'icona apparirà automaticamente quando l'app è installabile
                  </Typography>
                  <Typography paragraph>
                    2. Clicca sull'icona per vedere il prompt di installazione
                  </Typography>
                  <Typography>
                    3. Conferma l'installazione nella finestra di dialogo
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* Android Section */}
            <Accordion>
              <AccordionSummary expandIcon={<ChevronDown />}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Smartphone />
                  <Typography variant="h6">Android</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ pl: 4, borderLeft: '2px solid', borderColor: 'primary.main' }}>
                  <Typography paragraph>
                    <strong>Metodo 1:</strong> Attendi il prompt automatico di installazione
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Download size={24} />
                    <Typography>
                      Clicca sul prompt "Installa app" quando appare
                    </Typography>
                  </Box>
                  <Typography paragraph sx={{ mt: 3 }}>
                    <strong>Metodo 2:</strong> Menu del browser
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <MoreVertical size={24} />
                      <Typography>
                        1. Tocca i tre puntini
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Download size={24} />
                      <Typography>
                        2. Seleziona "Installa app"
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            {/* iOS Section */}
            <Accordion>
              <AccordionSummary expandIcon={<ChevronDown />}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Apple />
                  <Typography variant="h6">iOS (iPhone)</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ pl: 4, borderLeft: '2px solid', borderColor: 'primary.main' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Share size={24} />
                    <Typography>
                      1. Tocca l'icona di condivisione
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Plus size={24} />
                    <Typography>
                      2. Seleziona "Aggiungi a Home"
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Paper>
      </motion.div>
    </Container>
  );
};

export default InstallGuide;
