import React from 'react';
import { 
  Box, Paper, FormControl, InputLabel, 
  Select, MenuItem, Grid, Typography 
} from '@mui/material';
import { LocalGasStation, Speed, CalendarToday, Euro } from '@mui/icons-material';

const SearchFilters = ({
  filters,
  filterOptions,
  handleFilterChange,
  darkMode,
  isFullscreen,
  isMobile
}) => {
  const priceRanges = [
    { label: 'Up to €10,000', min: 0, max: 10000 },
    { label: '€10,000 - €20,000', min: 10000, max: 20000 },
    { label: '€20,000 - €30,000', min: 20000, max: 30000 },
    { label: '€30,000 - €50,000', min: 30000, max: 50000 },
    { label: '€50,000 - €100,000', min: 50000, max: 100000 },
    { label: 'Over €100,000', min: 100000, max: 1000000 },
  ];

  return (
    <Paper sx={{ 
      p: 2, 
      mb: 2,
      ...(isMobile && {
        position: 'relative',
        zIndex: 1200,
        maxHeight: '280px',
        overflowY: 'auto'
      })
    }}>
      <Grid container spacing={2}>
        {/* Brand Filter */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Brand</InputLabel>
            <Select
              value={filters.brand}
              onChange={(e) => handleFilterChange('brand', e.target.value)}
              label="Brand"
            >
              <MenuItem value="">All Brands</MenuItem>
              {filterOptions.brands.map((brand) => (
                <MenuItem key={brand} value={brand}>{brand}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Fuel Type Filter */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Fuel Type</InputLabel>
            <Select
              value={filters.fuel}
              onChange={(e) => handleFilterChange('fuel', e.target.value)}
              label="Fuel Type"
              startAdornment={<LocalGasStation />}
            >
              <MenuItem value="">All Fuel Types</MenuItem>
              {filterOptions.fuel_types.map((fuel) => (
                <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Price Range Filter */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            <Euro sx={{ verticalAlign: 'middle', mr: 1 }} />
            Price Range
          </Typography>
          <Grid container spacing={2}>
            {priceRanges.map((range) => (
              <Grid item xs={6} md={4} key={range.label}>
                <MenuItem
                  onClick={() => {
                    handleFilterChange('min_price', range.min);
                    handleFilterChange('max_price', range.max);
                  }}
                  selected={
                    filters.min_price === range.min && 
                    filters.max_price === range.max
                  }
                  sx={{ width: '100%' }}
                >
                  {range.label}
                </MenuItem>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Year Range Filter */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Min Year</InputLabel>
            <Select
              value={filters.min_year}
              onChange={(e) => handleFilterChange('min_year', e.target.value)}
              label="Min Year"
              startAdornment={<CalendarToday />}
            >
              <MenuItem value="">Any</MenuItem>
              {Array.from(
                { length: filterOptions.year_range.max_year - filterOptions.year_range.min_year + 1 },
                (_, i) => filterOptions.year_range.min_year + i
              ).map((year) => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Max Year</InputLabel>
            <Select
              value={filters.max_year}
              onChange={(e) => handleFilterChange('max_year', e.target.value)}
              label="Max Year"
              startAdornment={<CalendarToday />}
            >
              <MenuItem value="">Any</MenuItem>
              {Array.from(
                { length: filterOptions.year_range.max_year - filterOptions.year_range.min_year + 1 },
                (_, i) => filterOptions.year_range.min_year + i
              ).map((year) => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SearchFilters; 