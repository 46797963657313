import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box, Typography, List, ListItem, ListItemText, ListItemAvatar,
  Avatar, Chip, Dialog, DialogContent, DialogTitle, IconButton,
  Switch, FormControlLabel, Grid, Paper, Badge, CircularProgress,
  Alert, Snackbar, Tooltip, Pagination, Fade, TextField, InputAdornment
} from '@mui/material';
import { MessageCircle, X as CloseIcon, Mail, RefreshCw, Filter, Search, Person } from 'lucide-react';
import ChatFiltersSection from './ChatFiltersSection';
import { useAuth } from '../contexts/AuthContext';
import ChatHistoryDetail from './ChatHistoryDetail';
import { useNavigate } from 'react-router-dom';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://admin.aitomotivelab.com';

const truncateText = (text, maxLength = 30) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const Chats = ({ onChatClick, client = null, initialChatId = null }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authError, setAuthError] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(initialChatId);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(20);
  const [refreshing, setRefreshing] = useState(false);
  const wsRef = useRef(null);
  const reconnectionAttempts = useRef(0);
  const isManuallyClosed = useRef(false);
  const [isPageChanging, setIsPageChanging] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const [filters, setFilters] = useState({
    search: '',
    is_connected: null,
    human_control: null,
    date_range: [null, null],
    client_id: client?.id || null,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { token = localStorage.getItem('token') } = useAuth();

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (filters.is_connected !== null) count++;
    if (filters.human_control !== null) count++;
    if (filters.date_range[0] || filters.date_range[1]) count++;
    if (filters.search) count++;
    return count;
  }, [filters]);

  const updateChat = useCallback((updatedChat) => {
    setChats(prevChats => {
      const chatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(updatedChat.id));
      if (chatIndex > -1) {
        const updatedChats = [...prevChats];
        // Merge the existing chat data with the new data
        updatedChats[chatIndex] = {
          ...updatedChats[chatIndex],
          ...updatedChat,
          // Update client name if provided in the chat object
          ...(updatedChat.chat?.client_name && {
            client__first_name: updatedChat.chat.client_name.split(' ')[0],
            client__last_name: updatedChat.chat.client_name.split(' ').slice(1).join(' '),
          }),
          // Update other fields if provided
          ...(updatedChat.chat?.unique_id && { unique_id: updatedChat.chat.unique_id }),
          ...(updatedChat.chat?.ai_name && { ai_name: updatedChat.chat.ai_name }),
          ...(updatedChat.chat?.summary && { summary: updatedChat.chat.summary }),
          // Update last message if provided
          ...(updatedChat.bot_message && { last_message: updatedChat.bot_message }),
          ...(updatedChat.sender_message && { last_message: updatedChat.sender_message }),
          // Mark as updated
          updated_at: updatedChat.updated_at || new Date().toISOString(),
          is_new: true
        };
        return updatedChats;
      } else {
        // If it's a new chat, add it to the list
        return [{
          id: updatedChat.id,
          client__first_name: updatedChat.chat?.client_name ? updatedChat.chat.client_name.split(' ')[0] : '',
          client__last_name: updatedChat.chat?.client_name ? updatedChat.chat.client_name.split(' ').slice(1).join(' ') : '',
          unique_id: updatedChat.chat?.unique_id || '',
          ai_name: updatedChat.chat?.ai_name || '',
          summary: updatedChat.chat?.summary || '',
          last_message: updatedChat.bot_message || updatedChat.sender_message || '',
          updated_at: updatedChat.updated_at || new Date().toISOString(),
          is_new: true
        }, ...prevChats];
      }
    });
  }, []);

  const updateChatWithNewMessage = useCallback((messageData) => {
    const chatId = messageData.chat_id;
    setChats(prevChats => {
      const chatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(chatId));
      if (chatIndex > -1) {
        const updatedChats = [...prevChats];
        updatedChats[chatIndex] = {
          ...updatedChats[chatIndex],
          updated_at: new Date().toISOString(),
          last_message: messageData.bot_message || messageData.sender_message || messageData.admin_message,
          is_new: true
        };
        return updatedChats;
      }
      return prevChats;
    });
  }, []);

  const updateConnectionStatus = useCallback((data) => {
    setChats(prevChats =>
      prevChats.map(chat =>
        Number(chat.id) === Number(data.chat_id)
          ? {
            ...chat,
            is_connected: data.is_connected,
            last_connected: data.last_connected,
            last_disconnected: data.last_disconnected
          }
          : chat
      )
    );
  }, []);

  const deduplicateChats = useCallback((chatArray) => {
    const seen = new Map();
    return chatArray.filter(chat => {
      const duplicate = seen.has(chat.id);
      seen.set(chat.id, true);
      return !duplicate;
    });
  }, []);

  const handleWebSocketMessage = useCallback((data) => {
    console.log('WebSocket message received:', data);
    const action = data.action || data.type;
    switch (action) {
      case 'get_all_chats':
        if (data.error) {
          console.error('Error fetching chats:', data.error);
          setError(data.error);
          setChats([]);
        } else {
          console.log('Received chats data:', data.data);
          const uniqueChats = deduplicateChats(data.data || []);
          setChats(uniqueChats);
          setTotalPages(data.pagination?.total_pages || 1);
          setCurrentPage(data.pagination?.current_page || 1);
        }
        setLoading(false);
        setRefreshing(false);
        setIsPageChanging(false);
        break;
      case 'set_human_control':
      case 'update_chat':
        console.log("update_chat", data)
        updateChat(data.data);
        break;
      case 'new_message':
      case 'message_complete':
      case 'admin_message':
        updateChatWithNewMessage(data.data);
        break;
      case 'update_connection_status':
        updateConnectionStatus(data.data);
        break;
      case 'chat_joined':
        if (!client) {
          setChats(prevChats => {
            const newChat = data.data;
            const existingChatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(newChat.id));

            if (existingChatIndex > -1) {
              const updatedChats = [...prevChats];
              updatedChats[existingChatIndex] = {
                ...updatedChats[existingChatIndex],
                ...newChat,
              };
              return updatedChats;
            } else {
              return [newChat, ...prevChats];
            }
          });
        }

        break;
      default:
        console.log('Unhandled WebSocket message:', data);
    }
  }, [filters, updateChat, updateChatWithNewMessage, updateConnectionStatus, client, deduplicateChats]);

  const connectWebSocket = useCallback(() => {
    if (!token) {
      setError('Authentication token is missing. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      wsRef.current = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);
      
      wsRef.current.onopen = () => {
        console.log('WebSocket Connected');
        setError(null);
        setAuthError(false);
        reconnectionAttempts.current = 0;
        // Add slight delay before fetching to ensure connection is stable
        setTimeout(fetchChats, 500);
      };

      wsRef.current.onmessage = (event) => {
        let data;
        try {
          data = JSON.parse(event.data);
          console.log('Received data:', data);
        } catch (e) {
          console.error('Failed to parse message', e);
          return;
        }
        if (data.type === 'error' && data.message === 'Authentication required.') {
          setAuthError(true);
          wsRef.current.close();
        } else {
          if (data.type === 'chat.update' && !data.action) {
            data.action = 'update_chat';
          }
          handleWebSocketMessage(data);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setError('Failed to connect to the chat server. Please try again later.');
        setLoading(false);
      };

      wsRef.current.onclose = (event) => {
        console.log('WebSocket Disconnected:', event.code, event.reason);
        if (!authError && !isManuallyClosed.current) {
          reconnectionAttempts.current += 1;
          const delay = Math.min(10000, 1000 * Math.pow(2, reconnectionAttempts.current));
          setTimeout(() => connectWebSocket(), delay);
        }
      };
    } catch (error) {
      console.error('Failed to create WebSocket:', error);
      setError('Failed to establish connection. Please refresh the page.');
      setLoading(false);
    }
  }, [token, handleWebSocketMessage, authError]);

  const fetchChats = useCallback(() => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      const filtersToSend = {};
      
      // Only include filters that have actual values
      if (filters.search) filtersToSend.search = filters.search;
      if (filters.is_connected !== null) filtersToSend.is_connected = filters.is_connected;
      if (filters.human_control !== null) filtersToSend.human_control = filters.human_control;
      if (filters.client_id) filtersToSend.client_id = filters.client_id;
      
      if (filters.date_range?.[0] && filters.date_range?.[1]) {
        filtersToSend.date_range = [
          filters.date_range[0].toISOString(),
          filters.date_range[1].toISOString(),
        ];
      }

      const message = {
        action: 'get_all_chats',
        filters: filtersToSend,
        page: currentPage,
        page_size: pageSize
      };

      console.log('Fetching chats with message:', message);
      wsRef.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket connection state:', wsRef.current?.readyState);
      setError('Connection to server lost. Attempting to reconnect...');
    }
  }, [filters, currentPage, pageSize]);

  useEffect(() => {
    if (client) {
      setFilters(prevFilters => ({
        ...prevFilters,
        client_id: client.id
      }));
    }
  }, [client]);

  useEffect(() => {
    if (!isPageChanging) {
      fetchChats();
    }
  }, [filters, currentPage, isPageChanging, fetchChats]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      isManuallyClosed.current = true;
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [connectWebSocket]);

  useEffect(() => {
    console.log('Current state:', {
      wsState: wsRef.current?.readyState,
      loading,
      chatsCount: chats.length,
      filters,
      currentPage,
      totalPages,
      error
    });
  }, [loading, chats, filters, currentPage, totalPages, error]);

  useEffect(() => {
    if (initialChatId) {
      setSelectedChatId(initialChatId);
    }
  }, [initialChatId]);

  const handleChatClick = (chatId) => {
    setSelectedChatId(chatId);
    if (onChatClick) {
      onChatClick(chatId);
    }
    // Remove 'is_new' flag when chat is clicked
    setChats(prevChats =>
      prevChats.map(chat =>
        Number(chat.id) === Number(chatId) ? { ...chat, is_new: false } : chat
      )
    );
  };

  const handleHumanControlToggle = useCallback((event, chatId) => {
    event.stopPropagation(); // Prevent chat selection when toggling the switch
    const newValue = event.target.checked;
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({
        action: 'set_human_control',
        pk: chatId,
        human_control: newValue
      }));
    } else {
      setError('Unable to update human control. Please check your connection.');
    }
  }, []);

  const handleCloseError = () => {
    setError(null);
  };

  const handlePageChange = (event, value) => {
    setIsPageChanging(true);
    setCurrentPage(value);
    setIsPageChanging(false);
  };

  const handleFilterChange = (filterName, value) => {
    setIsPageChanging(true);
    setCurrentPage(1); // Reset to first page
    
    // If the value is empty or null, remove the filter
    const newValue = value === '' ? null : value;
    
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: newValue
    }));
    
    setIsPageChanging(false);
  };

  const handleRefresh = () => {
    setRefreshing(true);
    // Reset filters when refreshing
    setFilters({
      search: '',
      is_connected: null,
      human_control: null,
      date_range: [null, null],
      client_id: client?.id || null,
    });
    setCurrentPage(1);
    fetchChats();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', bgcolor: 'background.default' }}>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Grid container spacing={0} sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} lg={3} sx={{ height: '100%', overflow: 'hidden' }}>
          <Paper 
            elevation={3} 
            sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              borderRadius: { xs: 0, md: 2 },
              bgcolor: 'background.paper',
              overflow: 'hidden'
            }}
          >
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Chat History
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Tooltip title={`Filters ${activeFiltersCount > 0 ? `(${activeFiltersCount})` : ''}`}>
                    <Badge badgeContent={activeFiltersCount} color="primary">
                      <IconButton onClick={() => setFilterDialogOpen(true)}>
                        <Filter size={20} />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Refresh">
                    <IconButton onClick={handleRefresh} disabled={refreshing}>
                      <RefreshCw className={refreshing ? 'spin' : ''} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search by name, email, or number..."
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <List 
              sx={{ 
                flexGrow: 1,
                overflow: 'auto',
                p: 0,
                m: 0,
                '& .MuiListItem-root': {
                  borderBottom: 1,
                  borderColor: 'divider',
                  p: 2,
                  m: 0,
                  '&:hover': {
                    bgcolor: 'action.hover',
                  }
                }
              }}
            >
              <AnimatePresence mode="sync">
                {chats.map((chat) => (
                  <motion.div
                    key={`${chat.id}-${chat.unique_id}`}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ListItem
                      alignItems="flex-start"
                      onClick={() => handleChatClick(chat.id)}
                      sx={{
                        cursor: 'pointer',
                        transition: 'background-color 0.2s ease',
                        bgcolor: chat.is_new ? 'action.selected' : 'transparent',
                        position: 'relative',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                    >
                      <ListItemAvatar>
                        <Tooltip title={chat.is_connected ? 'Connected' : 'Disconnected'} arrow>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            sx={{
                              '& .MuiBadge-badge': {
                                backgroundColor: chat.is_connected ? 'success.main' : 'error.main',
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                              },
                            }}
                          >
                            <Avatar sx={{ bgcolor: 'primary.main' }}>
                              <MessageCircle />
                            </Avatar>
                          </Badge>
                        </Tooltip>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography component="span" variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                              {chat.client__first_name} {chat.client__last_name}
                            </Typography>
                            <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                              {new Date(chat.updated_at).toLocaleString()}
                            </Typography>
                            {chat.is_new && (
                              <Chip
                                label="New"
                                color="secondary"
                                size="small"
                                sx={{
                                  ml: 2,
                                  position: 'absolute',
                                  top: 8,
                                  right: 8,
                                }}
                              />
                            )}
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography variant="body2" color="text.secondary" noWrap>
                              {chat.last_message}
                            </Typography>
                            <Box mt={1} display="flex" alignItems="center" flexWrap="wrap">
                              <Tooltip title={chat.client__email} arrow>
                                <Chip 
                                  size="small" 
                                  icon={<Mail size={14} />} 
                                  label={truncateText(chat.client__email, 30)} 
                                  sx={{ 
                                    mr: 1, 
                                    mb: 1,
                                    maxWidth: {
                                      xs: '200px',  // for mobile
                                      sm: '250px',  // for tablet
                                      md: '300px'   // for desktop
                                    },
                                    '& .MuiChip-label': {
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap'
                                    }
                                  }} 
                                />
                              </Tooltip>
                              <Box 
                                onClick={(e) => e.stopPropagation()}
                                sx={{ 
                                  display: 'flex',
                                  alignItems: 'center',
                                  '& > *': { pointerEvents: 'none' },
                                  '& .MuiSwitch-root': { pointerEvents: 'auto' }
                                }}
                              >
                                <Tooltip title={chat.human_control ? 'Human Control Active' : 'AI Control Active'} arrow>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        size="small"
                                        checked={chat.human_control}
                                        onChange={(event) => handleHumanControlToggle(event, chat.id)}
                                        color="primary"
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    }
                                    label="Human Control"
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </motion.div>
                ))}
              </AnimatePresence>
            </List>

            <Box 
              sx={{ 
                p: 2, 
                borderTop: 1, 
                borderColor: 'divider',
                bgcolor: 'background.paper'
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                size={isMobile ? "small" : "medium"}
                disabled={loading || refreshing}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9} sx={{ height: '100%', overflow: 'auto', display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ height: '100%', p: { xs: 0, md: 2 } }}>
            {selectedChatId ? (
              <ChatHistoryDetail
                chatId={selectedChatId}
                whatsapp={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.whatsapp}
                humanControl={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.human_control}
                isConnected={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_connected}
                clientName={`${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__first_name || ''} ${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__last_name || ''}`}
                clientEmail={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__email}
                onClose={() => setSelectedChatId(null)}
              />
            ) : (
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                height="100%"
                sx={{ 
                  bgcolor: 'background.paper',
                  borderRadius: { xs: 0, md: 2 },
                  boxShadow: { xs: 0, md: 1 }
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  Select a chat to view details
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {isMobile && (
        <Dialog
          open={!!selectedChatId}
          onClose={() => setSelectedChatId(null)}
          fullScreen
          TransitionComponent={Fade}
        >
          <DialogContent sx={{ p: 0 }}>
            {selectedChatId && (
              <ChatHistoryDetail
                chatId={selectedChatId}
                whatsapp={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.whatsapp}
                humanControl={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.human_control}
                isConnected={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_connected}
                clientName={`${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__first_name || ''} ${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__last_name || ''}`}
                clientEmail={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__email}
                onClose={() => setSelectedChatId(null)}
              />
            )}
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Filters
            <IconButton onClick={() => setFilterDialogOpen(false)} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ChatFiltersSection 
            filters={filters} 
            handleFilterChange={handleFilterChange}
            onClose={() => setFilterDialogOpen(false)}
            isMobileDialog={isMobile}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Chats;
