import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, useMediaQuery, useTheme, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import PropTypes from 'prop-types';

const Layout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [assistantOpen, setAssistantOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const savedAssistantState = localStorage.getItem('assistantOpen');
    if (savedAssistantState) {
      setAssistantOpen(JSON.parse(savedAssistantState));
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarOpen = (open) => {
    setIsOpen(open);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleAssistant = () => {
    const newState = !assistantOpen;
    setAssistantOpen(newState);
    localStorage.setItem('assistantOpen', JSON.stringify(newState));
  };

  const isChatRoute = window.location.pathname.includes('/chats');
  const isCalendarRoute = window.location.pathname.includes('/booking');
  const isStockRoute = window.location.pathname.includes('/car_stock');
  const isClientRoute = window.location.pathname.includes('/clients');
  const needsSpecialPadding = isChatRoute || isCalendarRoute || isStockRoute || isClientRoute;

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      position: 'relative'
    }}>
      <Header 
        toggleSidebar={handleDrawerToggle}
        isSidebarOpen={isOpen}
        darkMode={darkMode}
        assistantOpen={assistantOpen}
        toggleAssistant={toggleAssistant}
      />
      <Box sx={{ 
        display: 'flex',
        flex: 1,
        position: 'relative',
        pt: { xs: '64px', md: '80px' } // Header height offset
      }}>
        <Sidebar 
          mobileOpen={mobileOpen} 
          handleDrawerToggle={handleDrawerToggle}
          isOpen={isOpen}
          handleSidebarOpen={handleSidebarOpen}
          darkMode={darkMode}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: '100%',
            maxWidth: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            p: isMobile ? 0 : (needsSpecialPadding ? 4 : { xs: 1, sm: 2, md: 2 }),
            ml: { 
              xs: 0,
              md: `${isOpen ? 240 : 73}px`
            },
            mr: { 
              xs: 0,
              md: assistantOpen ? '400px' : 0
            },
            transition: theme.transitions.create(['margin', 'width'], {
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;