import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, Chip, Grid, Typography, Paper, Button, MenuItem, TextField, Autocomplete, useTheme, useMediaQuery, Drawer, IconButton, Fab, InputAdornment, Snackbar, Alert, Slide } from '@mui/material';
import { Event as EventIcon, Settings as SettingsIcon, ChevronLeft, ChevronRight, ArrowBackIos, NavigateBefore, KeyboardArrowDown, KeyboardArrowUp, Add as AddIcon, Search as SearchIcon, Fullscreen, FullscreenExit, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';  // Import the Auth Context

import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import ServiceDialog from './ServiceDialog';
import BookingDialog from './BookingDialog';
import BookingDetailsDialog from './BookingDetailsDialog';
import SnackbarComponent from './SnackbarComponent';
import CalendarComponent from './CalendarComponent';

import { format, addDays, startOfWeek, startOfMonth, endOfMonth, isSameMonth, isSameDay } from 'date-fns';
import { subDays, addMonths, subMonths } from 'date-fns';

const localizer = momentLocalizer(moment);

const BOOKING_API_BASE_URL = 'https://admin.aitomotivelab.com/booking';
const CRM_API_BASE_URL = 'https://admin.aitomotivelab.com/crm/api/';

const createApi = (baseURL) => {
  const api = axios.create({ baseURL });
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  return api;
};

const bookingApi = createApi(BOOKING_API_BASE_URL);
const crmApi = createApi(CRM_API_BASE_URL);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[5],
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: theme.shape.borderRadius * 2,
}));

const MobileFilterDrawer = styled(Drawer)(({ theme }) => ({
  '& .MobileFilterDrawerPaper': {
    width: '85%',
    padding: theme.spacing(2),
  },
}));

const MobileActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  width: '100%',
  borderRadius: theme.shape.borderRadius * 2,
}));

const MobileCalendarWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  position: 'relative',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  '& .rbc-calendar': {
    height: '100%',
  },
  '& .rbc-time-view': {
    borderTop: 'none',
  },
  '& .rbc-time-content': {
    height: 'calc(100vh - 200px)', // Adjust based on your header height
  },
  '& .rbc-time-column': {
    minHeight: '1440px',
  },
  '& .rbc-timeslot-group': {
    minHeight: '60px',
  },
  '& .rbc-event': {
    fontSize: '0.8rem',
    padding: '2px 4px',
  },
}));

const MonthViewExpanded = ({ currentDate, onDateSelect, onClose, onMonthChange }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipeDirection, setSwipeDirection] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
    setSwipeDirection(null);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    
    if (touchStart && e.targetTouches[0].clientX) {
      const distance = touchStart - e.targetTouches[0].clientX;
      if (Math.abs(distance) > 30) { // Threshold for swipe detection
        setSwipeDirection(distance > 0 ? 'left' : 'right');
      }
    }
  };

  const handleTouchEnd = () => {
    if (swipeDirection === 'left') {
      onMonthChange('next');
    } else if (swipeDirection === 'right') {
      onMonthChange('prev');
    }
    
    setTouchStart(null);
    setTouchEnd(null);
    setSwipeDirection(null);
  };

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });

  const weeks = [];
  let days = [];
  let day = startDate;

  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  while (day <= monthEnd) {
    for (let i = 0; i < 7; i++) {
      days.push(day);
      day = addDays(day, 1);
    }
    weeks.push(days);
    days = [];
  }

  return (
    <Box
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      sx={{
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        zIndex: 1000,
        boxShadow: 3,
        p: 2
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">
          {format(currentDate, 'MMMM yyyy')}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <KeyboardArrowUp />
        </IconButton>
      </Box>

      <Grid container spacing={1}>
        {weekDays.map(day => (
          <Grid item xs={12/7} key={day}>
            <Typography
              variant="caption"
              align="center"
              sx={{ display: 'block', fontWeight: 'bold' }}
            >
              {day}
            </Typography>
          </Grid>
        ))}

        {weeks.map((week, i) => (
          week.map((day, j) => (
            <Grid item xs={12/7} key={j}>
              <Box
                onClick={() => {
                  onDateSelect(day);
                  onClose();
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 36,
                  cursor: 'pointer',
                  borderRadius: '50%',
                  bgcolor: isSameDay(day, currentDate) ? 'primary.main' : 'transparent',
                  color: isSameDay(day, currentDate) 
                    ? 'white'
                    : !isSameMonth(day, currentDate) 
                      ? 'text.disabled'
                      : 'text.primary',
                  '&:hover': {
                    bgcolor: isSameDay(day, currentDate) 
                      ? 'primary.dark'
                      : 'action.hover'
                  }
                }}
              >
                <Typography variant="body2">
                  {format(day, 'd')}
                </Typography>
              </Box>
            </Grid>
          ))
        ))}
      </Grid>
    </Box>
  );
};

const DateNavigationBar = ({ currentDate, onDateSelect, onMonthChange, isFullScreen, onFullScreenToggle }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <Box 
      sx={{ 
        position: 'relative',
        width: '100%',
        backgroundColor: 'background.paper',
        zIndex: 1,
        borderBottom: 1,
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          position: 'relative'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          width: '100%', 
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1
        }}>
          {isFullScreen ? (
            // Fullscreen header layout
            <>
              <IconButton
                size="small"
                onClick={onFullScreenToggle}
                sx={{ mr: 1 }}
              >
                <ChevronLeft />
              </IconButton>
              <Typography variant="subtitle1" sx={{ flex: 1, textAlign: 'center' }}>
                {format(currentDate, 'MMMM yyyy')}
              </Typography>
              <IconButton 
                size="small" 
                onClick={() => setIsExpanded(!isExpanded)}
                sx={{ ml: 1 }}
              >
                {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </>
          ) : (
            // Normal header layout
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  {format(currentDate, 'MMMM yyyy')}
                </Typography>
                <IconButton 
                  size="small" 
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </Box>
              
              <IconButton
                size="small"
                onClick={onFullScreenToggle}
                sx={{ ml: 1 }}
              >
                <Fullscreen />
              </IconButton>
            </>
          )}
        </Box>
      </Box>

      {/* MonthViewExpanded remains the same */}
      {isExpanded && (
        <MonthViewExpanded
          currentDate={currentDate}
          onDateSelect={onDateSelect}
          onClose={() => setIsExpanded(false)}
          onMonthChange={onMonthChange}
        />
      )}
    </Box>
  );
};

const CalendarPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole, appAccess } = useAuth();  // Access permissions from AuthContext
  const permissions = appAccess['booking'] || {};  // Extract booking-related permissions
  const canView = permissions.can_view || userRole === 'superadmin';  // Allow superadmins unrestricted access
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  const bookingSettingsPermissions = appAccess['booking_settings'] || {};  // Extract booking settings-related permissions
  const setting_canView = bookingSettingsPermissions.can_view || userRole === 'superadmin';  // Allow superadmins unrestricted access
  const setting_canCreate = bookingSettingsPermissions.can_create || userRole === 'superadmin';
  const setting_canEdit = bookingSettingsPermissions.can_edit || userRole === 'superadmin';
  const setting_canDelete = bookingSettingsPermissions.can_delete || userRole === 'superadmin';

  const [events, setEvents] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookingDetailsDialogOpen, setBookingDetailsDialogOpen] = useState(false);
  const [calendarView, setCalendarView] = useState('month');

  // New States for Filtering
  const [selectedStatus, setSelectedStatus] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [bookingClients, setBookingClients] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileFilterDrawerOpen, setMobileFilterDrawerOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [searchTerm, setSearchTerm] = useState('');
  const [editServiceOpen, setEditServiceOpen] = useState(false);
  const [editTimeOpen, setEditTimeOpen] = useState(false);

  const handleEditServiceClick = () => setEditServiceOpen(true);
  const handleEditTimeClick = () => setEditTimeOpen(true);

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (canView) {
      fetchServices();
      fetchEvents();
    }
  }, [canView]);

  useEffect(() => {
    filterEvents();
  }, [events, selectedStatus, selectedClient, searchTerm]);

  const fetchServices = async () => {
    try {
      const response = await bookingApi.get('/services/');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
      setSnackbar({ open: true, message: 'Error fetching services', severity: 'error' });
    }
  };

  const fetchClients = useCallback(async (query) => {
    try {
      const response = await crmApi.get('/Clients/', {
        params: { search: query }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching clients:', error);
      setSnackbar({ open: true, message: 'Error fetching clients', severity: 'error' });
      return [];
    }
  }, []);

  const fetchEvents = async () => {
    try {
      const [bookingsResponse, holidaysResponse] = await Promise.all([
        bookingApi.get('/bookings/'),
        bookingApi.get('/holidays/'),
      ]);

      const bookingEvents = formatBookingEvents(bookingsResponse.data);
      const holidayEvents = formatHolidayEvents(holidaysResponse.data);

      setEvents([...bookingEvents, ...holidayEvents]);

      const uniqueClients = Array.from(new Set(bookingsResponse.data.map((b) => b.client_id)))
        .map((client_id) => {
          const clientBooking = bookingsResponse.data.find((b) => b.client_id === client_id);
          return { id: client_id, name: `${clientBooking.client_name} ${clientBooking.last_name}` };
        });

      setBookingClients(uniqueClients);
    } catch (error) {
      console.error('Error fetching events:', error);
      setSnackbar({ open: true, message: 'Error fetching calendar data', severity: 'error' });
    }
  };

  const formatBookingEvents = (bookings) => {
    return bookings.map((booking) => ({
      title: `${booking.service_name} - ${booking.client_name}`,
      start: new Date(booking.start_time),
      end: new Date(booking.end_time),
      allDay: false,
      resource: {
        type: 'booking',
        bookingId: booking.id,
        status: booking.status,
        client_name: booking.client_name,
        last_name: booking.last_name,
        whatsapp: booking.whatsapp,
        number: booking.number,
        email: booking.email,
        client: booking.client_id,
        additional_information_answers: booking.additional_information_answers
      },
      color: getBookingColor(booking.status),
    }));
  };

  const formatHolidayEvents = (holidays) => {
    return holidays.map((holiday) => ({
      title: `Holiday: ${holiday.name}`,
      start: new Date(holiday.start_date),
      end: new Date(holiday.end_date),
      allDay: true,
      resource: {
        type: 'holiday',
        holidayId: holiday.id,
      },
      color: '#9E9E9E', // Gray color for holidays
    }));
  };

  const getBookingColor = (status) => {
    switch (status) {
      case 'pending':
        return '#FFA500'; // Orange
      case 'confirmed':
        return '#4CAF50'; // Green
      case 'cancelled':
        return '#F44336'; // Red
      default:
        return '#2196F3'; // Blue
    }
  };

  const filterEvents = () => {
    const filtered = events.filter((event) => {
      // Only filter booking events, not holidays
      if (event.resource.type !== 'booking') return true;
      
      const statusMatch = selectedStatus ? event.resource.status === selectedStatus : true;
      const clientMatch = selectedClient ? 
        event.resource.client === selectedClient : true;
      
      // Add search term filtering
      const searchMatch = searchTerm ? 
        (event.resource.client_name + ' ' + event.resource.last_name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) : true;
      
      return statusMatch && clientMatch && searchMatch;
    });
    setFilteredEvents(filtered);
  };

  const handleServiceSelect = async (serviceId) => {
    setSelectedService(serviceId);
    try {
      const response = await bookingApi.get(`/services/${serviceId}/availability/`, {
        params: {
          start_date: startDate,
        },
      });
      setAvailableSlots(response.data);
      setServiceDialogOpen(false);
      setBookingDialogOpen(true);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      setSnackbar({ open: true, message: 'Error fetching available slots', severity: 'error' });
    }
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
  };

  const handleClientSelect = (clientId) => {
    setSelectedClient(clientId);
  };

  const handleBookingSubmit = async (bookingData) => {
    if (!bookingData.slot || !bookingData.clientId) {
      setSnackbar({
        open: true,
        message: !bookingData.slot ? 'Please select a time slot' : 'Please select a client',
        severity: 'warning',
      });
      return;
    }

    try {
      await bookingApi.post('/bookings/', {
        service: selectedService,
        client: bookingData.clientId,
        start_time: bookingData.slot.start_time,
        end_time: bookingData.slot.end_time,
        car_model: bookingData.carModel,
        additional_information_answers: bookingData.additionalInfo,
        notes: bookingData.notes,
      });

      setSnackbar({ open: true, message: 'Booking added successfully!', severity: 'success' });
      fetchEvents();
      setBookingDialogOpen(false);
      setSelectedService('');
      setSelectedClient(null);
      setSelectedSlot(null);
    } catch (error) {
      console.error('Error adding booking:', error);
      setSnackbar({ open: true, message: 'Error adding booking', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        borderRadius: '4px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
      },
    };
  };

  const handleEventClick = (event) => {
    if (event.resource.type === 'booking' && canEdit) {
      setSelectedBooking(event);
      setBookingDetailsDialogOpen(true);
    }
  };

  const handleStatusChange = async (newStatus, emailSubject, emailBody, notificationChannel) => {
    try {
      await bookingApi.patch(`/bookings/${selectedBooking.resource.bookingId}/`, {
        status: newStatus,
        channel: notificationChannel,
        email_subject: emailSubject,
        email_body: emailBody
      });
      setSnackbar({ open: true, message: 'Booking status updated and notification sent successfully!', severity: 'success' });
      fetchEvents();
      setBookingDetailsDialogOpen(false);
    } catch (error) {
      console.error('Error updating booking status and sending notification:', error);
      setSnackbar({ open: true, message: 'Error updating booking status and sending notification', severity: 'error' });
    }
  };

  const calendarViews = useMemo(
    () => ({
      month: true,
      week: true,
      day: true,
      agenda: true,
    }),
    []
  );

  const MobileFilters = () => (
    <MobileFilterDrawer
      anchor="right"
      open={mobileFilterDrawerOpen}
      onClose={() => setMobileFilterDrawerOpen(false)}
      classes={{ paper: 'MobileFilterDrawerPaper' }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Filters
        </Typography>
        <TextField
          select
          fullWidth
          label="Filter by Status"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          sx={{ mb: 2 }}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="confirmed">Confirmed</MenuItem>
          <MenuItem value="cancelled">Cancelled</MenuItem>
        </TextField>

        <Autocomplete
          id="mobile-client-search"
          options={bookingClients}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return `${option.first_name} ${option.last_name}${option.email ? ` - ${option.email}` : ''}`;
          }}
          value={bookingClients.find((client) => client.id === selectedClient) || null}
          onChange={(event, newValue) => {
            setSelectedClient(newValue ? newValue.id : null);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Filter by Client" variant="outlined" fullWidth />
          )}
        />
      </Box>
    </MobileFilterDrawer>
  );

  const MobileToolbar = ({ date, onNavigate, label }) => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      p: 1,
      borderBottom: '1px solid rgba(255,255,255,0.1)',
      bgcolor: 'background.paper'
    }}>
      <IconButton onClick={() => onNavigate('PREV')} size="small">
        <NavigateBefore />
      </IconButton>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1 
      }}>
        <Typography variant="subtitle1">
          {format(date, 'EEEE, d MMMM')}
        </Typography>
      </Box>
      <IconButton onClick={() => onNavigate('NEXT')} size="small">
        <ChevronRight />
      </IconButton>
    </Box>
  );

  const formatEvents = useCallback((events) => {
    if (!events) return [];
    
    return events.map(event => ({
      ...event,
      title: event?.title || 'Untitled Event', // Provide default title
      start: new Date(event.start),
      end: new Date(event.end),
      color: event?.color || '#2196F3' // Provide default color
    }));
  }, []);

  const formattedEvents = useMemo(() => formatEvents(filteredEvents), [filteredEvents, formatEvents]);

  // Add this mobileFormats definition
  const mobileFormats = useMemo(() => ({
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: ({ start, end }) => {
      if (!start || !end) return '';
      return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
    },
    dayRangeHeaderFormat: ({ start, end }) => {
      if (!start || !end) return '';
      return moment(start).format('MMMM DD');
    }
  }), []);

  // Make sure you also have this mobileEventPropGetter if not already defined
  const mobileEventPropGetter = useCallback((event) => ({
    style: {
      backgroundColor: event?.color || '#2196F3',
      color: 'white',
      border: 'none',
      borderRadius: '4px'
    }
  }), []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  // Add this handler for swipe navigation
  const handleSwipe = (direction) => {
    const newDate = direction === 'left' 
      ? addDays(selectedDate, 1) 
      : subDays(selectedDate, 1);
    setSelectedDate(newDate);
  };

  // Add touch event handlers
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      handleSwipe('left');
    }
    if (isRightSwipe) {
      handleSwipe('right');
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  // Add these handlers for the week day list swipe
  const handleWeekSwipe = useCallback((direction) => {
    const newDate = direction === 'left' 
      ? addDays(selectedDate, 7) 
      : subDays(selectedDate, 7);
    setSelectedDate(newDate);
  }, [selectedDate]);

  // Update the calendar time range and remove the scrollToTime prop
  const calendarProps = {
    localizer,
    events: formattedEvents,
    defaultView: "day",
    views: ['day'],
    date: selectedDate,
    onNavigate: date => setSelectedDate(date),
    toolbar: false,
    step: 30,
    timeslots: 1,
    min: new Date(2024, 0, 1, 0, 0, 0), // Start from 00:00
    max: new Date(2024, 0, 1, 23, 59, 59), // End at 23:59
    formats: mobileFormats,
    eventPropGetter: mobileEventPropGetter,
    onSelectEvent: handleEventClick,
    dayLayoutAlgorithm: "no-overlap"
  };

  const handleMonthChange = useCallback((direction) => {
    setSelectedDate(prevDate => 
      direction === 'next' 
        ? addMonths(prevDate, 1)
        : subMonths(prevDate, 1)
    );
  }, []);

  // First, define the dialog props
  const serviceDialogProps = {
    open: serviceDialogOpen,
    services: services,
    startDate: startDate,
    onStartDateChange: (e) => setStartDate(e.target.value),
    onClose: () => setServiceDialogOpen(false),
    onServiceSelect: handleServiceSelect,
    onNext: () => {
      setServiceDialogOpen(false);
      setBookingDialogOpen(true);
    }
  };

  const bookingDialogProps = {
    open: bookingDialogOpen,
    availableSlots: availableSlots,
    selectedSlot: selectedSlot,
    onSlotSelect: handleSlotSelect,
    onClose: () => setBookingDialogOpen(false),
    onSubmit: handleBookingSubmit,
    fetchClients: fetchClients
  };

  const bookingDetailsDialogProps = {
    open: bookingDetailsDialogOpen,
    selectedBooking: selectedBooking,
    onClose: () => setBookingDetailsDialogOpen(false),
    onStatusChange: handleStatusChange
  };

  const handleEditService = (service) => {
    if (!service) return;
    
    // Navigate to the availability management page with the service tab active
    navigate('/booking/availability', { 
      state: { 
        activeTab: 0, // Services tab
        editService: service 
      }
    });
  };

  // Update the header section for both desktop and mobile
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        overflow: 'hidden',
        ...(isMobile && {
          p: 0,
          width: '100vw'
        })
      }}
    >
      {/* Header Section */}
      <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
        <Grid container spacing={2}>
          {/* Title and Status Filter for Mobile */}
          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            {isMobile ? (
              // Mobile Layout
              <>
                <Box sx={{ 
                  width: '100%',
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Typography variant="h6">Gestione Appuntamenti</Typography>
                  
                  {/* Add Settings Button for Mobile */}
                  {setting_canView && (
                    <IconButton
                      onClick={() => navigate('/booking/availability')}
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  )}
                  
                  <TextField
                    select
                    size="small"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    label="Stato"
                    sx={{ 
                      minWidth: 120,
                      ml: 2
                    }}
                  >
                    <MenuItem value="">Tutti gli stati</MenuItem>
                    <MenuItem value="pending">In Attesa</MenuItem>
                    <MenuItem value="confirmed">Confermato</MenuItem>
                    <MenuItem value="cancelled">Annullato</MenuItem>
                  </TextField>
                </Box>
              </>
            ) : (
              // Desktop Layout
              <>
                <Grid item>
                  <Typography variant="h6">Gestione Appuntamenti</Typography>
                </Grid>
                <Grid item>
                  {/* Add Settings Button for Desktop */}
                  {setting_canView && (
                    <Button
                      variant="outlined"
                      startIcon={<SettingsIcon />}
                      onClick={() => navigate('/booking/availability')}
                      sx={{ mr: 2 }}
                    >
                      Calendar Settings
                    </Button>
                  )}
                  {canCreate && (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => setServiceDialogOpen(true)}
                    >
                      Book Appointment
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </Grid>

          {/* Search and Filters */}
          <Grid item xs={12}>
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              flexWrap: 'wrap',
              overflowX: 'auto', 
              pb: 1 
            }}>
              {/* Client Search */}
              <Autocomplete
                size="small"
                freeSolo
                options={bookingClients}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return option.name || '';  // Use the name property we set in bookingClients
                }}
                value={searchTerm}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setSearchTerm(newValue);
                    setSelectedClient(null);
                  } else if (newValue) {
                    setSearchTerm(newValue.name);
                    setSelectedClient(newValue.id);
                  } else {
                    setSearchTerm('');
                    setSelectedClient(null);
                  }
                }}
                onInputChange={async (event, newInputValue) => {
                  setSearchTerm(newInputValue);
                  if (newInputValue.length >= 2) {
                    try {
                      const results = await fetchClients(newInputValue);
                      const formattedClients = results.results.map(client => ({
                        id: client.id,
                        name: `${client.first_name} ${client.last_name}${client.email ? ` - ${client.email}` : ''}`
                      }));
                      setBookingClients(formattedClients);
                    } catch (error) {
                      console.error('Error searching clients:', error);
                      setSnackbar({
                        open: true,
                        message: 'Error searching clients. Please try again.',
                        severity: 'error'
                      });
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search by name or email"
                    sx={{ minWidth: 300, flex: isMobile ? 1 : 'none' }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              {/* Status Filter - Only show on desktop */}
              {!isMobile && (
                <TextField
                  select
                  size="small"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  label="Stato"
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem value="">Tutti gli stati</MenuItem>
                  <MenuItem value="pending">In Attesa</MenuItem>
                  <MenuItem value="confirmed">Confermato</MenuItem>
                  <MenuItem value="cancelled">Annullato</MenuItem>
                </TextField>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Calendar Section */}
      <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        {isMobile ? (
          <>
            <DateNavigationBar 
              currentDate={selectedDate}
              onDateSelect={handleDateSelect}
              onMonthChange={handleMonthChange}
              isFullScreen={isFullScreen}
              onFullScreenToggle={() => setIsFullScreen(!isFullScreen)}
            />
            <MobileCalendarWrapper
              sx={{
                ...(isFullScreen && {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 1300,
                  bgcolor: 'background.paper'
                })
              }}
            >
              {isFullScreen && (
                <FullScreenCloseButton
                  onClick={() => setIsFullScreen(false)}
                  aria-label="close fullscreen"
                >
                  <CloseIcon />
                </FullScreenCloseButton>
              )}
              <Calendar
                localizer={localizer}
                events={formattedEvents}
                defaultView="day"
                views={['day']}
                date={selectedDate}
                onNavigate={date => setSelectedDate(date)}
                components={{
                  toolbar: MobileToolbar
                }}
                step={30}
                timeslots={1}
                min={new Date(2024, 0, 1, 0, 0, 0)}
                max={new Date(2024, 0, 1, 23, 59, 59)}
                formats={mobileFormats}
                eventPropGetter={mobileEventPropGetter}
                onSelectEvent={handleEventClick}
                dayLayoutAlgorithm="no-overlap"
                style={{
                  height: isFullScreen ? '100vh' : 'calc(100vh - 200px)'
                }}
              />
            </MobileCalendarWrapper>
            {/* Add Booking FAB for mobile */}
            {canCreate && !isFullScreen && (
              <BookingFab
                color="primary"
                aria-label="add booking"
                onClick={() => setServiceDialogOpen(true)}
              >
                <AddIcon />
              </BookingFab>
            )}
          </>
        ) : (
          <CalendarComponent
            localizer={localizer}
            events={formattedEvents}
            eventStyleGetter={eventStyleGetter}
            onSelectEvent={handleEventClick}
            views={calendarViews}
            view={calendarView}
            onView={(newView) => setCalendarView(newView)}
            style={{ height: 'calc(100vh - 200px)' }} // Adjust based on your header height
          />
        )}
      </Box>

      {/* Dialogs */}
      {canCreate && <ServiceDialog {...serviceDialogProps} />}
      {canCreate && <BookingDialog {...bookingDialogProps} />}
      {canEdit && <BookingDetailsDialog {...bookingDetailsDialogProps} />}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

// Add this export statement
export default CalendarPage;

const FullScreenCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: 8,
  right: 8,
  zIndex: 1400,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BookingFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(3),
  bottom: theme.spacing(3),
  zIndex: 1200,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
