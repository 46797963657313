import React from 'react';
import { Box, Paper, Typography, TextField, IconButton } from '@mui/material';
import { Send, Upload } from 'lucide-react';

const ChatPanel = ({
  messages,
  isLoading,
  error,
  message,
  setMessage,
  handleSubmit,
  handleFileUpload,
  darkMode,
  chatContainerRef,
  isMobile
}) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      position: 'relative',
      ...(isMobile && {
        zIndex: 1000
      })
    }}>
      {/* Messages Container */}
      <Box
        ref={chatContainerRef}
        sx={{
          height: `calc(100% - ${isMobile ? '64px' : '80px'})`,
          overflowY: 'auto',
          p: isMobile ? 1 : 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        {messages.map((msg, index) => (
          <Paper
            key={index}
            sx={{
              p: 2,
              maxWidth: '80%',
              alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              bgcolor: msg.sender === 'user' ? '#4361EE' : '#F5F5F5',
              color: msg.sender === 'user' ? '#FFFFFF' : '#000000',
              borderRadius: msg.sender === 'user' ? '12px 12px 0 12px' : '12px 12px 12px 0'
            }}
          >
            <Typography>{msg.text}</Typography>
          </Paper>
        ))}
        {isLoading && (
          <Paper sx={{ p: 2, maxWidth: '80%', alignSelf: 'flex-start' }}>
            <Typography>Thinking...</Typography>
          </Paper>
        )}
        {error && (
          <Paper sx={{ p: 2, maxWidth: '80%', alignSelf: 'center', bgcolor: '#FF4444', color: 'white' }}>
            <Typography>{error}</Typography>
          </Paper>
        )}
      </Box>

      {/* Input Area */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: isMobile ? 1 : 2,
          borderTop: 1,
          borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
          display: 'flex',
          gap: 1,
          bgcolor: darkMode ? '#1E1E1E' : '#FFFFFF',
          height: isMobile ? '64px' : '80px',
          minHeight: isMobile ? '64px' : '80px',
          maxHeight: isMobile ? '64px' : '80px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <input
          type="file"
          id="file-upload"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <IconButton
          component="label"
          htmlFor="file-upload"
          sx={{ color: '#4361EE' }}
        >
          <Upload size={20} />
        </IconButton>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          size="small"
          InputProps={{
            endAdornment: (
              <IconButton type="submit" disabled={!message.trim()}>
                <Send size={20} />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default ChatPanel; 