import React from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import { X, Search, FileText, Maximize2, Minimize2, RotateCcw } from 'lucide-react';

const Header = ({
  isMobile,
  setMobileSearchVisible,
  setShowPromptStructure,
  onClose,
  setIsSearchOpen,
  toggleFullscreen,
  isFullscreen,
  darkMode,
  onResetConversation,
}) => {
  return (
    <Box sx={{
      p: 2,
      borderBottom: '1px solid',
      borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <Typography variant="h6">
        {isMobile ? 'Assistant' : 'Assistente Interno'}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {isMobile ? (
          <>
            <IconButton onClick={() => setMobileSearchVisible(true)}>
              <Search size={20} />
            </IconButton>
            <IconButton onClick={() => setShowPromptStructure(true)}>
              <FileText size={20} />
            </IconButton>
            <Tooltip title="Reset conversation">
              <IconButton onClick={onResetConversation}>
                <RotateCcw size={20} />
              </IconButton>
            </Tooltip>
            <IconButton onClick={onClose}>
              <X size={20} />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              onClick={() => setIsSearchOpen(true)}
              sx={{
                transition: 'transform 0.2s',
                '&:hover': { transform: 'scale(1.1)' },
              }}
            >
              <Search size={20} />
            </IconButton>
            <IconButton
              onClick={() => setShowPromptStructure(true)}
              sx={{
                transition: 'transform 0.2s',
                '&:hover': { transform: 'scale(1.1)' },
              }}
            >
              <FileText size={20} />
            </IconButton>
            <Tooltip title="Reset conversation">
              <IconButton
                onClick={onResetConversation}
                sx={{
                  transition: 'transform 0.2s',
                  '&:hover': { transform: 'scale(1.1)' },
                }}
              >
                <RotateCcw size={20} />
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={toggleFullscreen}
              sx={{
                transition: 'transform 0.2s',
                '&:hover': { transform: 'scale(1.1)' },
              }}
            >
              {isFullscreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Header; 