// Property mappings for different tools/views
export const propertyMappings = {
  car_stock_search: {
    id: 'vehicleid',
    title: 'title',
    description: 'description',
    price: 'price',
    image: 'image',
    url: 'url',
    available: 'available',
  },
};

export const mapItems = (items, mappings) => {
  if (!Array.isArray(items) || !mappings) {
    return [];
  }

  return items.map(item => {
    const mappedItem = {};
    Object.entries(mappings).forEach(([key, value]) => {
      mappedItem[key] = item[value];
    });
    return mappedItem;
  });
};

export const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString();
};

export const formatPrice = (price) => {
  if (!price) return '';
  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR'
  }).format(price);
};

export const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
}; 