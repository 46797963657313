import React from 'react';
import { Paper, Box, Typography, Button, Chip, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import { LocalGasStation, Speed, CalendarToday, Euro, DirectionsCar } from '@mui/icons-material';

const CarSearchResult = ({ car, isFullscreen, onAddKnowledge, darkMode, isMobile }) => {
  const compact = !isFullscreen;

  return (
    <Card
      elevation={2}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: compact ? 'row' : 'column',
        bgcolor: darkMode ? '#333' : '#fff',
        border: car.highlighted ? '2px solid #4361EE' : 'none',
        '&:hover': {
          boxShadow: 6,
          transform: 'translateY(-2px)',
          transition: 'all 0.2s ease-in-out'
        },
        ...(isMobile && {
          position: 'relative',
          zIndex: 1100
        })
      }}
    >
      {/* Car Image */}
      <Box
        sx={{
          height: compact ? 140 : 200,
          width: compact ? 140 : '100%',
          backgroundColor: darkMode ? '#222' : '#f5f5f5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          flexShrink: 0,
        }}
      >
        {car.urlmainimage || car.image_url ? (
          <img
            src={car.urlmainimage || car.image_url}
            alt={`${car.brand} ${car.model}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-car.jpg';
            }}
          />
        ) : (
          <DirectionsCar sx={{ fontSize: 60, color: darkMode ? 'grey.600' : 'grey.400' }} />
        )}
      </Box>

      {/* Car Details */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        flex: 1,
        p: compact ? 1.5 : 2,
      }}>
        <Typography 
          variant={compact ? "subtitle1" : "h6"}
          gutterBottom 
          sx={{ 
            color: darkMode ? '#fff' : '#333',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {car.brand} {car.model}
        </Typography>

        {/* Specs */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: compact ? 0.75 : 1.5,
          mb: compact ? 1 : 2
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            color: darkMode ? '#fff' : '#666'
          }}>
            <Euro sx={{ color: '#4361EE', mr: 1, fontSize: compact ? 18 : 24 }} />
            <Typography variant={compact ? "body2" : "body1"} fontWeight="medium">
              {car.saleprice?.toLocaleString()}€
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            color: darkMode ? '#fff' : '#666'
          }}>
            <Speed sx={{ color: '#4361EE', mr: 1, fontSize: compact ? 18 : 24 }} />
            <Typography variant={compact ? "body2" : "body1"}>
              {car.km?.toLocaleString()} km
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            color: darkMode ? '#fff' : '#666'
          }}>
            <CalendarToday sx={{ color: '#4361EE', mr: 1, fontSize: compact ? 18 : 24 }} />
            <Typography variant={compact ? "body2" : "body1"}>
              {car.registrationyear}
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            color: darkMode ? '#fff' : '#666'
          }}>
            <LocalGasStation sx={{ color: '#4361EE', mr: 1, fontSize: compact ? 18 : 24 }} />
            <Typography variant={compact ? "body2" : "body1"}>
              {car.fuel}
            </Typography>
          </Box>
        </Box>

        {/* Additional Details Chips - Only show in fullscreen */}
        {!compact && (
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1, 
            mb: 2,
            mt: 'auto' 
          }}>
            {car.bodystyle && (
              <Chip 
                label={car.bodystyle} 
                size="small" 
                sx={{ 
                  bgcolor: darkMode ? 'rgba(67, 97, 238, 0.2)' : 'rgba(67, 97, 238, 0.1)',
                  color: darkMode ? '#fff' : '#4361EE',
                }} 
              />
            )}
            {car.transmission && (
              <Chip 
                label={car.transmission} 
                size="small" 
                sx={{ 
                  bgcolor: darkMode ? 'rgba(67, 97, 238, 0.2)' : 'rgba(67, 97, 238, 0.1)',
                  color: darkMode ? '#fff' : '#4361EE',
                }} 
              />
            )}
            {car.colour && (
              <Chip 
                label={car.colour} 
                size="small" 
                sx={{ 
                  bgcolor: darkMode ? 'rgba(67, 97, 238, 0.2)' : 'rgba(67, 97, 238, 0.1)',
                  color: darkMode ? '#fff' : '#4361EE',
                }} 
              />
            )}
          </Box>
        )}

        {/* Add to Knowledge Button */}
        <Button
          variant="contained"
          size={compact ? "small" : "medium"}
          onClick={() => onAddKnowledge(car)}
          sx={{ 
            mt: 'auto',
            bgcolor: '#4361EE',
            '&:hover': {
              bgcolor: '#2D3A8C',
            }
          }}
        >
          Add to Knowledge
        </Button>
      </Box>
    </Card>
  );
};

export default CarSearchResult; 