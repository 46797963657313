import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  useTheme, 
  useMediaQuery 
} from '@mui/material';
import { styled } from '@mui/system';
import Chats from './Chats';

const FullHeightBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden'
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledPaper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[3],
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    padding: theme.spacing(2),
  }
}));

const ChatsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { chatId } = useParams();

  const handleChatClick = (selectedChatId) => {
    console.log(`Chat clicked: ${selectedChatId}`);
  };

  return (
    <FullHeightBox>
      <StyledPaper>
        <Chats onChatClick={handleChatClick} initialChatId={chatId} />
      </StyledPaper>
    </FullHeightBox>
  );
};

export default ChatsPage;
