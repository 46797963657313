import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Stepper,
  Step,
  StepLabel,
  Paper,
  CircularProgress,
  Fade,
  useTheme,
  LinearProgress,
  Tooltip,
  IconButton,
  Zoom,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircleOutline, ErrorOutline, Help, ArrowBack, ArrowForward, Send } from '@mui/icons-material';
import ItalianLocationFields from './ItalianLocationFields'; // Importing the new component

const BASE_URL = 'https://admin.aitomotivelab.com/formbuilder';

const PublicFormPage = () => {
  const { formId } = useParams();
  const [formData, setFormData] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [formState, setFormState] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [progress, setProgress] = useState(0);
  const [expanded, setExpanded] = useState(true);

  
  const theme = useTheme();

  useEffect(() => {
    fetchFormData();
  }, [formId]);

  const fetchFormData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/forms/${formId}/`);
      const data = await response.json();
      setFormData(data);
      initializeFormState(data);
    } catch (error) {
      console.log(error)
      console.error('Error fetching form data:', error);
    }
  };

  const initializeFormState = (formData) => {
    const initialState = {};
    formData.pages.forEach(page => {
      page.groups.forEach(group => {
        group.fields.forEach(field => {
          if (field.field_type === 'italian_location') {
            initialState[`${field.field_id}_region`] = '';
            initialState[`${field.field_id}_province`] = '';
            initialState[`${field.field_id}_municipality`] = '';
            initialState[`${field.field_id}_cap`] = '';
          } else if ( field.field_type === 'checkbox' ) {
            initialState[field.field_id] = '';
          } else {
            initialState[field.field_id] = '';
          }
        });
      });
    });
    setFormState(initialState);
  };

  const handleChange = (fieldId, value, fieldType) => {
    if (fieldType === 'checkbox') {
      setFormState(prevState => {
        const currentValues = prevState[fieldId] || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter(v => v !== value)
          : [...currentValues, value];
        return { ...prevState, [fieldId]: newValues };
      });
    } else {
      setFormState(prevState => ({
        ...prevState,
        [fieldId]: value
      }));
    }
  };

  const handleCheckboxChange = (fieldId, value) => {
    setFormState(prevState => {
      const currentValues = prevState[fieldId] ? prevState[fieldId].split(',') : [];
      let newValues;
      if (currentValues.includes(value)) {
        newValues = currentValues.filter(item => item !== value);
      } else {
        newValues = [...currentValues, value];
      }
      return {
        ...prevState,
        [fieldId]: newValues.join(',')
      };
    });
  };

  const handleItalianLocationChange = (fieldId, subFieldId, value) => {
    setFormState(prevState => ({
      ...prevState,
      [`${fieldId}_${subFieldId}`]: value
    }));
  };

  const evaluateCondition = useCallback((condition) => {
    const { field, fieldId, type, value } = condition;
    const conditionFieldId = field || fieldId;
    const fieldValue = formState[conditionFieldId];

    switch (type) {
      case 'equals':
        return fieldValue === value;
      case 'not_equals':
        return fieldValue !== value;
      case 'contains':
        return Array.isArray(fieldValue) ? fieldValue.includes(value) : String(fieldValue).includes(value);
      case 'not_contains':
        return Array.isArray(fieldValue) ? !fieldValue.includes(value) : !String(fieldValue).includes(value);
      case 'greater_than':
        return Number(fieldValue) > Number(value);
      case 'less_than':
        return Number(fieldValue) < Number(value);
      default:
        return true;
    }
  }, [formState]);

  const evaluateFieldConditions = useCallback((conditions) => {
    if (!conditions || conditions === '[]') return true;
    try {
      const parsedConditions = JSON.parse(conditions);
      return parsedConditions.every(conditionGroup => {
        if (conditionGroup.logicalOperator === 'AND') {
          return conditionGroup.conditions.every(condition => evaluateCondition(condition));
        } else if (conditionGroup.logicalOperator === 'OR') {
          return conditionGroup.conditions.some(condition => evaluateCondition(condition));
        }
        return evaluateCondition(conditionGroup);
      });
    } catch (error) {
      console.error('Error parsing field conditions:', error);
      return true;
    }
  }, [evaluateCondition]);

  const evaluateConditions = useCallback((conditions) => {
    if (!conditions || conditions === '[]') return true;
    try {
      const parsedConditions = JSON.parse(conditions);
      return parsedConditions.every(conditionGroup => {
        if (conditionGroup.logicalOperator === 'AND') {
          return conditionGroup.conditions.every(condition => evaluateCondition(condition));
        } else if (conditionGroup.logicalOperator === 'OR') {
          return conditionGroup.conditions.some(condition => evaluateCondition(condition));
        }
        return evaluateCondition(conditionGroup);
      });
    } catch (error) {
      console.error('Error parsing conditions:', error);
      return true;
    }
  }, [evaluateCondition]);

  const evaluatePageConditions = useCallback((conditions) => {
    if (!conditions || conditions === '[]') return true;
    try {
      const parsedConditions = JSON.parse(conditions);
      return parsedConditions.every(condition => evaluateCondition(condition));
    } catch (error) {
      console.error('Error parsing page conditions:', error);
      return true;
    }
  }, [evaluateCondition]);

  const visiblePages = useMemo(() => 
    formData ? formData.pages.filter(page => evaluatePageConditions(page.conditions)) : [],
    [formData, evaluatePageConditions]
  );

  useEffect(() => {
    calculateProgress();
  }, [formState, visiblePages]);

  const calculateProgress = () => {
    const totalFields = visiblePages.reduce((total, page) => 
      total + page.groups.reduce((groupTotal, group) => 
        groupTotal + group.fields.filter(field => evaluateFieldConditions(field.conditions)).length, 0
      ), 0
    );
    
    const filledFields = Object.values(formState).filter(value => value !== '').length;
    const newProgress = (filledFields / totalFields) * 100;
    setProgress(newProgress);
  };

  const renderField = (field) => {
    if (!evaluateFieldConditions(field.conditions)) return null;

    const commonProps = {
      key: field.field_id,
      label: field.label,
      fullWidth: true,
      margin: "normal",
      value: formState[field.field_id] || '',
      onChange: (e) => handleChange(field.field_id, e.target.value),
      required: field.required,
      sx: { mb: 2 }
    };

    const fieldComponent = (() => {
      switch (field.field_type) {
        case 'text':
        case 'email':
        case 'number':
          return <TextField {...commonProps} type={field.field_type} />;
        case 'textarea':
          return <TextField {...commonProps} multiline rows={4} />;
        case 'select':
          return (
            <FormControl {...commonProps}>
              <InputLabel>{field.label}</InputLabel>
              <Select {...commonProps}>
                {field.options.split(',').map((option) => (
                  <MenuItem key={option.trim()} value={option.trim()}>
                    {option.trim()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        case 'radio':
          return (
            <FormControl {...commonProps} component="fieldset">
              <Typography component="legend">{field.label}</Typography>
              <RadioGroup {...commonProps}>
                {field.options.split(',').map((option) => (
                  <FormControlLabel key={option.trim()} value={option.trim()} control={<Radio />} label={option.trim()} />
                ))}
              </RadioGroup>
            </FormControl>
          );
          case 'checkbox':
            const checkedValues = formState[field.field_id] ? formState[field.field_id].split(',') : [];
            return (
              <FormGroup key={field.field_id}>
                <Typography component="legend">{field.label}</Typography>
                {field.options.split(',').map((option) => {
                  const trimmedOption = option.trim();
                  return (
                    <FormControlLabel
                      key={trimmedOption}
                      control={
                        <Checkbox
                          checked={checkedValues.includes(trimmedOption)}
                          onChange={() => handleCheckboxChange(field.field_id, trimmedOption)}
                        />
                      }
                      label={trimmedOption}
                    />
                  );
                })}
              </FormGroup>
            );
        case 'italian_location':
          return (
            <ItalianLocationFields
              onChange={(subFieldId, value) => handleItalianLocationChange(field.field_id, subFieldId, value)}
              disabled={false}
            />
          );
        default:
          return null;
      }
    })();

    return (
      <Box key={field.field_id} sx={{ position: 'relative' }}>
        {fieldComponent}
        {field.description && (
          <Tooltip title={field.description} arrow placement="top-start">
            <IconButton 
              size="small" 
              sx={{ position: 'absolute', top: 0, right: -30 }}
            >
              <Help fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const renderGroup = (group) => {
    if (!evaluateConditions(group.conditions)) return null;
  
    return (
      <motion.div
        key={group.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>{group.label}</Typography>
          {group.description && (
            <Typography variant="body2" paragraph>{group.description}</Typography>
          )}
          {group.fields.map(field => renderField(field))}
        </Paper>
      </motion.div>
    );
  };

  const renderPage = (page) => {
    return (
      <Box key={page.id}>
        <Typography variant="h5" gutterBottom>{page.label}</Typography>
        <Typography variant="body1" paragraph>{page.description}</Typography>
        <AnimatePresence>
          {page.groups.map(group => renderGroup(group))}
        </AnimatePresence>
      </Box>
    );
  };

  const handleNextPage = async () => {
    const nextPageIndex = currentPageIndex + 1;
    if (nextPageIndex < visiblePages.length) {
      setCurrentPageIndex(nextPageIndex);
    } else {
      setIsSubmitting(true);
      try {
        // Construct the field_responses list from formState
        const fieldResponses = formData.pages.flatMap(page =>
          page.groups.flatMap(group =>
            group.fields.map(field => {
              if (field.field_type === 'italian_location') {
                return [
                  { field: field.id, subField: 'region', value: formState[`${field.field_id}_region`] || '' },
                  { field: field.id, subField: 'province', value: formState[`${field.field_id}_province`] || '' },
                  { field: field.id, subField: 'municipality', value: formState[`${field.field_id}_municipality`] || '' },
                  { field: field.id, subField: 'cap', value: formState[`${field.field_id}_cap`] || '' }
                ];
              } else {
                return { field: field.id, value: formState[field.field_id] || '' };
              }
            }).flat()
          )
        );

        const response = await fetch(`${BASE_URL}/api/form-responses/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ form: formData.id, field_responses: fieldResponses }),
        });

        if (response.ok) {
          const result = await response.json();
          console.log('Form submitted:', result);
          setSubmitSuccess(true);
        } else {
          const error = await response.json();
          console.error('Form submission failed:', error);
          setSubmitSuccess(false);
        }
      } catch (error) {
        console.error('Form submission failed:', error);
        setSubmitSuccess(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  if (!formData || visiblePages.length === 0) {
    return <Typography>No form data available or no visible pages.</Typography>;
  }

  const currentPage = visiblePages[currentPageIndex];

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom align="center">{formData.title}</Typography>
      <Typography variant="body1" paragraph align="center">{formData.description}</Typography>
      
      <Box sx={{ mb: 4 }}>
        <Stepper activeStep={currentPageIndex} alternativeLabel>
          {visiblePages.map((page, index) => (
            <Step key={page.id}>
              <StepLabel>{page.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
            Progress:
          </Typography>
          <Box sx={{ width: '60%', mr: 1 }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Typography variant="body2" color="text.secondary">
            {Math.round(progress)}%
          </Typography>
        </Box>
      </Box>

      <Fade in={true}>
        <Box>
          {renderPage(currentPage)}
        </Box>
      </Fade>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Zoom in={currentPageIndex > 0}>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={handlePreviousPage}
            disabled={currentPageIndex === 0}
          >
            Previous
          </Button>
        </Zoom>
        <Zoom in={true}>
          <Button
            variant="contained"
            endIcon={currentPageIndex < visiblePages.length - 1 ? <ArrowForward /> : <Send />}
            onClick={handleNextPage}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : currentPageIndex < visiblePages.length - 1 ? (
              'Next'
            ) : (
              'Submit'
            )}
          </Button>
        </Zoom>
      </Box>

      <AnimatePresence>
        {submitSuccess !== null && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <Paper 
              elevation={3} 
              sx={{ 
                mt: 4, 
                p: 3, 
                borderRadius: 2,
                backgroundColor: submitSuccess ? theme.palette.success.light : theme.palette.error.light,
                color: submitSuccess ? theme.palette.success.contrastText : theme.palette.error.contrastText
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {submitSuccess ? (
                  <CheckCircleOutline sx={{ fontSize: 40, mr: 2 }} />
                ) : (
                  <ErrorOutline sx={{ fontSize: 40, mr: 2 }} />
                )}
                <Typography variant="h6">
                  {submitSuccess ? 'Magazzino creato con successo!' : 'Qualcosa è andato storto. Riprova.'}
                </Typography>
              </Box>
            </Paper>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default PublicFormPage;
