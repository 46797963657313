import React from 'react';
import { Grid, TextField, Button, Typography, Paper } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const ServiceForm = ({ serviceFormData, handleServiceFormChange, handleServiceFormSubmit, isEditingService }) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking_settings'] || {}; // Use booking_settings for permissions
  const setting_canCreate = permissions.can_create || userRole === 'superadmin'; // Create permission
  const setting_canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission

  // Determine if the user has permission to either create or edit
  const canSubmitForm = isEditingService ? setting_canEdit : setting_canCreate;

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {isEditingService ? 'Edit Service' : 'Add New Service'}
      </Typography>
      <form onSubmit={handleServiceFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              label="Service Name"
              value={serviceFormData.name}
              onChange={handleServiceFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="description"
              label="Description"
              value={serviceFormData.description}
              onChange={handleServiceFormChange}
              fullWidth
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="duration"
              label="Duration (minutes)"
              type="number"
              value={serviceFormData.duration}
              onChange={handleServiceFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="price"
              label="Price"
              type="number"
              value={serviceFormData.price}
              onChange={handleServiceFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="color"
              label="Color"
              type="color"
              value={serviceFormData.color}
              onChange={handleServiceFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12}>
            {canSubmitForm && ( // Show the button only if the user has permission to submit
              <Button type="submit" variant="contained" color="primary">
                {isEditingService ? 'Update Service' : 'Add Service'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ServiceForm;
