import React, { useState, useEffect, useRef } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  CircularProgress,
  Chip,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Zoom,
  Slide,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Person as PersonIcon,
  SmartToy as AIIcon,
  DirectionsCar as CarIcon,
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  ThumbUp,
  ThumbDown,
  Comment as CommentIcon,
  ExpandMore as ExpandMoreIcon,
  Label as LabelIcon,
  ArrowBack as ArrowBackIcon,
  Description as DescriptionIcon,
  Close as CloseIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  AudioFile as AudioIcon,
  BrokenImage as BrokenImageIcon,
  DirectionsCar,
  Assignment,
  Schedule,
  NoteAdd,
  Chat,
  TableChart,
  Article,
  Person,
  Mail
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';
import SendMessageForm from './SendMessageForm';
import { useNavigate } from 'react-router-dom';
import MobileStepper from '@mui/material/MobileStepper';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://admin.aitomotivelab.com';
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'https://admin.aitomotivelab.com';

// Styled Components
const RootContainer = styled(Container)(({ theme, isSmallScreen }) => ({
  height: '100vh',
  overflow: 'hidden',
  padding: isSmallScreen ? 0 : theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 0,
  padding: 0,
  margin: 0,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(2),
  margin: 0,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.dark,
  },
}));

const FixedBottomBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 3),
  },
  zIndex: 10,
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'scale(1.2) rotate(5deg)',
    backgroundColor: theme.palette.primary.light,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot, isWhatsApp }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  backgroundColor: isBot
    ? isWhatsApp
      ? '#DCF8C6'
      : theme.palette.primary.light
    : isWhatsApp
    ? '#E2E2E2'
    : theme.palette.background.paper,
  color: isBot
    ? isWhatsApp
      ? '#000000'
      : theme.palette.primary.contrastText
    : theme.palette.text.primary,
  borderRadius: 12,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
  },
  maxWidth: '95%',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
    maxWidth: '80%',
    borderRadius: 16,
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
}));

const SummaryAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const SummaryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2A2F3B' : '#E8F0FE',
  minHeight: 48,
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
}));

const SummaryAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9FAFB',
  padding: theme.spacing(1, 2),
}));

const CarAttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '3px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    overflowX: 'visible',
  },
}));

const CarStockCard = ({ car, isSmallScreen }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    transition={{ duration: 0.2 }}
  >
    <Card
      sx={{
        width: isSmallScreen ? '200px' : '100%',
        flexShrink: 0,
        m: 0,
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        component="img"
        height="120"
        image={car.urlmainimage || '/api/placeholder/345/140'}
        alt={`${car.brand} ${car.model}`}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent sx={{ p: 1.5, '&:last-child': { pb: 1.5 } }}>
        <Typography variant="subtitle2" noWrap>
          {car.brand} {car.model}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          €{car.saleprice?.toLocaleString()}
        </Typography>
        <Typography variant="caption" color="text.secondary" display="block">
          {car.registrationyear} • {car.km?.toLocaleString()} km
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
);

const MobileCarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflowX: 'hidden',
  '& .carousel-content': {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CarouselArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  zIndex: 2,
  boxShadow: theme.shadows[2],
}));

const MobileCarCarousel = ({ cars }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = cars.length;

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <MobileCarouselContainer>
      <Box className="carousel-content" sx={{ width: '100%', position: 'relative' }}>
        <CarouselArrowButton
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ left: 8 }}
        >
          <KeyboardArrowLeft />
        </CarouselArrowButton>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 40px',
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
            >
              <CarStockCard car={cars[activeStep]} isSmallScreen={true} />
            </motion.div>
          </AnimatePresence>
        </Box>

        <CarouselArrowButton
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ right: 8 }}
        >
          <KeyboardArrowRight />
        </CarouselArrowButton>
      </Box>

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          width: '100%',
          justifyContent: 'center',
          background: 'transparent',
          mt: 1,
          '& .MuiMobileStepper-dot': {
            margin: '0 4px',
          },
        }}
      />
    </MobileCarouselContainer>
  );
};

const SummaryTable = ({ summary }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const parseSummary = (text) => {
    const sections = {
      vehicles: '',
      mainRequest: '',
      timing: {
        status: 'REQUIRES CUSTOMER SERVICE ORGANIZATION',
        details: ''
      },
      additionalNotes: '',
      conversationSummary: ''
    };

    // For timing, get everything between "ORGANIZATION -" and "- Note Aggiuntive"
    const timingMatch = text.match(/ORGANIZATION -([^-]+)- Note Aggiuntive/);
    if (timingMatch && timingMatch[1]) {
      sections.timing.details = timingMatch[1].trim();
      sections.timing.status = ''; // Clear the status since we have details
    }

    // Parse other sections normally
    const parts = text.split(/[-\n]+/).map(part => part.trim()).filter(Boolean);
    parts.forEach(part => {
      if (part.startsWith('Veicoli:')) {
        sections.vehicles = part.replace('Veicoli:', '').trim();
      } else if (part.startsWith('Richiesta Principale:')) {
        sections.mainRequest = part.replace('Richiesta Principale:', '').trim();
      } else if (part.startsWith('Note Aggiuntive:')) {
        sections.additionalNotes = part.replace('Note Aggiuntive:', '').trim();
      } else if (part.startsWith('Riassunto conversazionale:')) {
        sections.conversationSummary = part.replace('Riassunto conversazionale:', '').trim();
      }
    });

    return sections;
  };

  const sections = parseSummary(summary);

  console.log('Parsed Sections:', sections);

  return (
    <Box sx={{ 
      width: '100%', 
      overflowX: 'auto',
      borderRadius: 2,
      bgcolor: 'background.paper',
      boxShadow: theme.shadows[3],
      p: isSmallScreen ? 1 : 2,
    }}>
      <Box sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: '1fr',
      }}>
        {[
          { icon: <DirectionsCar />, label: 'Vehicles', value: sections.vehicles },
          { icon: <Assignment />, label: 'Main Request', value: sections.mainRequest },
          { 
            icon: <Schedule />, 
            label: 'Timing',
            value: sections.timing.details || sections.timing.status,
            isStatus: !sections.timing.details
          },
          { icon: <NoteAdd />, label: 'Additional Notes', value: sections.additionalNotes },
          { icon: <Chat />, label: 'Conversation Summary', value: sections.conversationSummary }
        ].map((row, index) => (
          <Paper
            key={index}
            elevation={1}
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              overflow: 'hidden',
              borderRadius: 2,
              transition: 'all 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme.shadows[4],
                bgcolor: theme.palette.background.default,
              },
            }}
          >
            {/* Label Section */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                bgcolor: theme.palette.primary.main,
                color: 'white',
                p: isSmallScreen ? 2 : 3,
                width: isSmallScreen ? '100%' : '280px',
                flexShrink: 0,
                minHeight: '80px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '50%',
                  p: 1,
                  width: 40,
                  height: 40,
                  flexShrink: 0,
                }}
              >
                {React.cloneElement(row.icon, {
                  sx: { fontSize: '1.5rem' }
                })}
              </Box>
              <Typography
                variant={isSmallScreen ? "body1" : "h6"}
                sx={{ 
                  fontWeight: 500,
                  width: '100%',
                  wordBreak: 'break-word',
                  lineHeight: 1.2,
                }}
              >
                {row.label}
              </Typography>
            </Box>

            {/* Content Section */}
            <Box
              sx={{
                flexGrow: 1,
                p: isSmallScreen ? 2 : 3,
                display: 'flex',
                alignItems: 'center',
                bgcolor: theme.palette.background.paper,
                minHeight: '80px',
              }}
            >
              {row.isStatus ? (
                <Chip
                  label={row.value}
                  color="primary"
                  size={isSmallScreen ? "small" : "medium"}
                  sx={{
                    fontWeight: 'medium',
                    fontSize: isSmallScreen ? '0.875rem' : '1rem',
                    py: 2,
                  }}
                />
              ) : (
                <Typography
                  variant={isSmallScreen ? "body2" : "body1"}
                  color={row.value ? 'text.primary' : 'text.secondary'}
                  sx={{
                    fontSize: isSmallScreen ? '0.875rem' : '1rem',
                    lineHeight: 1.6,
                    width: '100%',
                  }}
                >
                  {row.value || 'Not specified'}
                </Typography>
              )}
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

const SummarySection = ({ summary }) => {
  const [summaryViewMode, setSummaryViewMode] = useState('table');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ 
        position: 'absolute', 
        top: isSmallScreen ? 4 : 8, 
        right: isSmallScreen ? 4 : 8, 
        zIndex: 1,
        display: 'flex',
        gap: 1,
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 1,
        boxShadow: theme.shadows[2],
      }}>
        <Tooltip title="Table View">
          <IconButton 
            size={isSmallScreen ? "small" : "medium"}
            onClick={() => setSummaryViewMode('table')}
            color={summaryViewMode === 'table' ? 'primary' : 'default'}
            sx={{
              '&:hover': {
                bgcolor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <TableChart fontSize={isSmallScreen ? "small" : "medium"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Text View">
          <IconButton
            size={isSmallScreen ? "small" : "medium"}
            onClick={() => setSummaryViewMode('text')}
            color={summaryViewMode === 'text' ? 'primary' : 'default'}
            sx={{
              '&:hover': {
                bgcolor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <Article fontSize={isSmallScreen ? "small" : "medium"} />
          </IconButton>
        </Tooltip>
      </Box>
      
      <Fade in={true}>
        <Box sx={{ mt: isSmallScreen ? 5 : 6 }}>
          {summaryViewMode === 'table' ? (
            <SummaryTable summary={summary} />
          ) : (
            <Paper
              elevation={3}
              sx={{
                whiteSpace: 'pre-wrap',
                p: isSmallScreen ? 2 : 3,
                borderRadius: 2,
                fontSize: isSmallScreen ? '0.875rem' : '1rem',
                lineHeight: 1.6,
                '& p': {
                  mb: 2,
                },
              }}
            >
              <Typography variant="body1">
                {summary}
              </Typography>
            </Paper>
          )}
        </Box>
      </Fade>
    </Box>
  );
};

const ClientInfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ClientInformation = ({ clientName, clientEmail }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ClientInfoSection>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Client Information
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isSmallScreen ? 'column' : 'row',
        gap: 2,
        alignItems: isSmallScreen ? 'flex-start' : 'center'
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          minWidth: 200 
        }}>
          <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
            <PersonIcon />
          </Avatar>
          <Typography variant="subtitle1">
            {clientName || 'N/A'}
          </Typography>
        </Box>
        
        {clientEmail && (
          <Chip
            icon={<Mail sx={{ fontSize: 16 }} />}
            label={clientEmail}
            variant="outlined"
            sx={{
              borderRadius: '16px',
              '& .MuiChip-label': {
                px: 1,
              },
            }}
          />
        )}
      </Box>
    </ClientInfoSection>
  );
};

const ChatHistoryDetail = ({ 
  chatId, 
  humanControl, 
  onClose,
  clientName,
  clientEmail 
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');
  const [labelSegmentation, setLabelSegmentation] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'success' });
  const [feedbackDialog, setFeedbackDialog] = useState({ open: false, messageId: null });
  const [feedback, setFeedback] = useState({ category: '', comment: '' });
  const lastMessageRef = useRef(null);
  const [unsentMessage, setUnsentMessage] = useState(null);
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const wsRef = useRef(null);
  const [authError, setAuthError] = useState(false);
  const isManuallyClosed = useRef(false);
  const reconnectionAttempts = useRef(0);
  const tempIdCounter = useRef(0);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [summaryViewMode, setSummaryViewMode] = useState('table');

  const { userRole, appAccess, token = localStorage.getItem('token') } = useAuth();
  const permissions = appAccess['ai_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';

  const FileDisplay = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  }));

  useEffect(() => {
    let isCancelled = false;
    isManuallyClosed.current = false;
    setLoading(true);
    setChatHistory([]);
    setChatSummary('');
    setLabelSegmentation('');

    const connectWebSocket = () => {
      if (wsRef.current) {
        wsRef.current.close();
      }

      try {
        wsRef.current = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);

        wsRef.current.onopen = () => {
          console.log('WebSocket Connected');
          setAuthError(false);
          reconnectionAttempts.current = 0;
          
          // Send chat request with additional context
          const requestData = {
            action: 'full_chat',
            pk: chatId,
            context: {
              user_role: userRole,
              permissions: permissions
            }
          };
          
          wsRef.current.send(JSON.stringify(requestData));
        };

        wsRef.current.onmessage = (event) => {
          if (isCancelled) return;

          let data;
          try {
            data = JSON.parse(event.data);
            console.log('MESSAGE RECEIVED', data);
          } catch (e) {
            console.error('Failed to parse message', e);
            return;
          }

          if (data.type === 'error' && data.message === 'Authentication required.') {
            setAuthError(true);
            setError('Authentication required');
            wsRef.current.close();
          } else if (data.error === 'Permission denied') {
            setError('You do not have permission to view this chat');
            setLoading(false);
          } else {
            handleWebSocketMessage(data);
          }
        };

        wsRef.current.onerror = (error) => {
          if (!isCancelled) {
            console.error('WebSocket Error:', error);
            setError('Error connecting to chat');
            setLoading(false);
          }
        };

        wsRef.current.onclose = (event) => {
          console.log('WebSocket Disconnected', event.code, event.reason);
          if (!authError && !isManuallyClosed.current && !isCancelled) {
            reconnectionAttempts.current += 1;
            const delay = Math.min(10000, 1000 * Math.pow(2, reconnectionAttempts.current));
            setTimeout(() => connectWebSocket(), delay);
          }
        };

      } catch (error) {
        if (!isCancelled) {
          console.error('Failed to create WebSocket:', error);
          setError('Failed to connect to chat server');
          setLoading(false);
        }
      }
    };

    connectWebSocket();

    return () => {
      isCancelled = true;
      isManuallyClosed.current = true;
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [chatId, token, userRole, permissions]);

  const handleWebSocketMessage = (data) => {
    console.log('Received WebSocket message:', data);
    
    switch (data.action || data.type) {
      case 'full_chat':
        if (data.error) {
          console.error('Error fetching chat:', data.error);
          setError(data.error);
          setChatHistory([]);
          setLoading(false);
          return; // Exit early if there's an error
        }
        
        // Ensure data exists and has the expected structure
        if (!data.data) {
          console.error('Invalid data structure received');
          setError('Invalid data received from server');
          setChatHistory([]);
          setLoading(false);
          return;
        }

        try {
          const chatData = Array.isArray(data.data) ? data.data : [];
          console.log('Processing chat data:', chatData);
          
          setChatHistory(chatData);
          
          // Safely extract summary
          const chatInfo = chatData[0]?.chat || {};
          setChatSummary(chatInfo.summary || '');
          setLabelSegmentation(data.label_segmentation || '');
          
        } catch (err) {
          console.error('Error processing chat data:', err);
          setError('Error processing chat data');
          setChatHistory([]);
        }
        
        setLoading(false);
        break;

      case 'new_message':
      case 'update_chat':
        console.log("update_chat", data);
        // Ensure data.data exists before updating
        if (data.data) {
          const incomingMessage = data.data;
          setChatHistory((prevHistory) => {
            if (!prevHistory) return [incomingMessage]; // Handle case where prevHistory is undefined
            
            const index = prevHistory.findIndex(
              (msg) => msg.id === incomingMessage.id || msg.id === incomingMessage.messageId
            );
            if (index !== -1) {
              const updatedHistory = [...prevHistory];
              updatedHistory[index] = { ...updatedHistory[index], ...incomingMessage };
              return updatedHistory;
            } else {
              return [...prevHistory, incomingMessage];
            }
          });
        }
        break;

      case 'message_sent':
        const { chatHistoryId, temp_id } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === temp_id);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              id: chatHistoryId,
              isTemp: false,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        setUnsentMessage(null);
        break;
      case 'message_status_updated':
        const { chatHistoryId: updatedId, sent } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === updatedId);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              sent: sent,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        break;
      default:
        console.log('Unhandled WebSocket message:', data);
    }
  };

  useEffect(() => {
    if (Array.isArray(chatHistory) && chatHistory.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    const lastMessage = chatHistory[chatHistory.length - 1];
    if (lastMessage && lastMessage.sent === false && lastMessage.sender_message) {
      setUnsentMessage(lastMessage);
    } else {
      setUnsentMessage(null);
    }
  }, [chatHistory]);

  const sendWebSocketMessage = (message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open. Cannot send message.');
      setSnackbar({ open: true, message: 'Error: WebSocket is not connected', type: 'error' });
    }
  };

  const handleSendMessage = (message) => {
    // Check if there is a last unsent message
    if (unsentMessage) {
      // Update the last unsent message to sent
      sendWebSocketMessage({
        action: 'update_message_status',
        chatHistoryId: unsentMessage.id,
        sent: true,
      });
    }

    const tempId = `temp-${tempIdCounter.current++}`;
    const tempMessage = {
      id: tempId,
      bot_message: message.text,
      created_at: new Date().toISOString(),
      sent: true,
      isTemp: true,
      car_stocks: message.cars,
    };
    setChatHistory((prevHistory) => [...prevHistory, tempMessage]);

    sendWebSocketMessage({
      action: 'send_message',
      chatId: chatId,
      messageId: tempId,
      message: message.text,
      car_stocks: message.cars,
      sent: true,
      include_registration_form: message.include_registration_form,
    });
  };

  const handleFeedback = (messageId, type) => {
    if (type === 'comment') {
      setFeedbackDialog({ open: true, messageId });
    } else {
      sendWebSocketMessage({
        action: 'send_feedback',
        messageId: messageId,
        feedbackType: type,
      });
    }
  };

  const handleFeedbackSubmit = () => {
    sendWebSocketMessage({
      action: 'send_feedback',
      messageId: feedbackDialog.messageId,
      feedback: feedback,
    });
    setFeedbackDialog({ open: false, messageId: null });
    setFeedback({ category: '', comment: '' });
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
    setEditing(true);
  };

  const handleSave = () => {
    sendWebSocketMessage({
      action: 'edit_message',
      message: editingMessage,
    });
    setEditing(false);
    setEditingMessage(null);
  };

  const handleChange = (e) => {
    setEditingMessage({ ...editingMessage, [e.target.name]: e.target.value });
  };

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Typography variant="h4" gutterBottom {...props} />,
        h2: ({ node, ...props }) => <Typography variant="h5" gutterBottom {...props} />,
        h3: ({ node, ...props }) => <Typography variant="h6" gutterBottom {...props} />,
        p: ({ node, ...props }) => <Typography variant="body1" paragraph {...props} />,
        a: ({ node, ...props }) => <a style={{ color: theme.palette.primary.main }} {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const handleSummaryClick = () => {
    setSummaryDialogOpen(true);
  };

  const handleCloseSummaryDialog = () => {
    setSummaryDialogOpen(false);
  };

  const ChatSummaryAccordion = () => {
    if (isSmallScreen) {
      return (
        <Tooltip title="Chat Summary">
          <IconButton onClick={handleSummaryClick} size="small">
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <SummaryAccordion
        expanded={summaryExpanded}
        onChange={() => setSummaryExpanded(!summaryExpanded)}
        sx={{
          mb: 0,
          '& .MuiAccordionSummary-root': {
            minHeight: 48,
          },
          '& .MuiAccordionSummary-content': {
            margin: '8px 0',
          },
        }}
      >
        <SummaryAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="chat-summary-content"
          id="chat-summary-header"
        >
          <Typography variant="subtitle1">Chat Summary</Typography>
        </SummaryAccordionSummary>
        <SummaryAccordionDetails>
          <SummarySection summary={chatSummary} />
        </SummaryAccordionDetails>
      </SummaryAccordion>
    );
  };

  const getFileUrl = (fileObject) => {
    if (!fileObject) return null;

    // If fileObject is a string, return it directly
    if (typeof fileObject === 'string') return fileObject;

    // If fileObject is an object with a file_url property, return that
    if (typeof fileObject === 'object' && fileObject.file_url) {
      return fileObject.file_url;
    }

    return null;
  };

  const getAbsoluteUrl = (relativeUrl, token, chatId) => {
    if (!relativeUrl) return null;

    const urlString = String(relativeUrl);

    // If URL is already absolute, return it without adding parameters
    if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
      return urlString;
    }

    // Build the base URL
    const baseUrl = `${API_DOMAIN}${urlString.startsWith('/') ? '' : '/'}${urlString}`;

    // Add token and chat_id as query parameters if they exist
    const params = new URLSearchParams();
    if (token) params.append('token', token);
    if (chatId) params.append('chat_id', chatId);

    const queryString = params.toString();
    console.log(queryString);
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  };

  const getFileDetails = (fileUrl) => {
    if (!fileUrl) return { fileName: 'Unknown File', fileExtension: '' };

    const urlString = String(fileUrl);
    const urlWithoutParams = urlString.split('?')[0];
    const urlParts = urlWithoutParams.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';

    return { fileName, fileExtension };
  };

  const renderFile = (fileObject, isBot, currentToken, currentChatId) => {
    const fileUrl = getFileUrl(fileObject);
    if (!fileUrl) {
      console.log('No file URL provided:', fileObject);
      return null;
    }

    // Get file details from the original URL (without parameters)
    const { fileName, fileExtension } = getFileDetails(fileUrl);

    // Generate the absolute URL with parameters
    const absoluteUrl = getAbsoluteUrl(fileUrl, currentToken, currentChatId);
    if (!absoluteUrl) {
      console.error('Unable to generate absolute URL for:', fileUrl);
      return null;
    }

    // Log the URL being used
    console.log('Attempting to load file from:', absoluteUrl);

    // Expanded file type checks
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
    const isImage = imageExtensions.includes(fileExtension.toLowerCase());
    const isAudio = ['mp3', 'wav', 'ogg', 'm4a', 'aac'].includes(fileExtension.toLowerCase());

    // Create URL object to check validity
    let isValidUrl = false;
    try {
      new URL(absoluteUrl);
      isValidUrl = true;
    } catch (e) {
      console.error('Invalid URL:', absoluteUrl, e);
    }

    return (
      <FileDisplay>
        {isImage ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                minHeight: '200px',
                position: 'relative',
                bgcolor: 'background.paper',
                borderRadius: 1,
                overflow: 'hidden',
                boxShadow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isValidUrl && (
                <>
                  <img
                    src={absoluteUrl}
                    alt={fileName}
                    style={{
                      display: 'block',
                      width: '100%',
                      height: 'auto',
                      maxHeight: '400px',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      console.error('Image load error for URL:', absoluteUrl);
                      console.error('File object:', fileObject);
                      console.error('Error event:', e);

                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = '/api/placeholder/400/300';
                      e.target.style.opacity = '0.5'; // Show it's a fallback
                    }}
                    onLoad={(e) => {
                      console.log('Image loaded successfully:', absoluteUrl);
                      e.target.style.opacity = '1';
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      '&.loading': {
                        opacity: 1,
                      },
                    }}
                    className="image-loading"
                  >
                    <CircularProgress size={40} />
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName} {!isValidUrl && '(Invalid URL)'}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download image">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <ImageIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : isAudio ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                bgcolor: 'background.paper',
                borderRadius: 1,
                p: 2,
                boxShadow: 1,
              }}
            >
              <audio
                controls
                style={{ width: '100%' }}
                src={absoluteUrl}
                onError={(e) => {
                  console.error('Audio load error:', e);
                  console.error('Audio URL:', absoluteUrl);
                }}
              >
                Your browser does not support the audio element.
              </audio>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '500px',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download audio">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <AudioIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: 'background.paper',
              borderRadius: 1,
              p: 2,
              boxShadow: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FileIcon color="primary" />
              <Typography variant="body2">{fileName}</Typography>
            </Box>
            {isValidUrl && (
              <Tooltip title="Download file">
                <IconButton
                  component="a"
                  href={absoluteUrl}
                  download={fileName}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <FileIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </FileDisplay>
    );
  };

  const LabelSegmentationAccordion = () => (
    <SummaryAccordion>
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="label-segmentation-content"
        id="label-segmentation-header"
      >
        <Typography variant="h6">
          <LabelIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Label Segmentation
        </Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{labelSegmentation}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );

  const handleCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  if (error)
    return (
      <Typography color="error" variant="h5" align="center">
        {error}
      </Typography>
    );
  if (chatHistory.length === 0)
    return (
      <Typography variant="h5" align="center">
        No chat history found
      </Typography>
    );

  return (
    <Container maxWidth={isSmallScreen ? 'sm' : 'lg'}>
      <StyledPaper elevation={3}>
        {/* Header Section */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box sx={{ 
            p: 2, 
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleCloseClick} sx={{ mr: 2 }} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Chat Details</Typography>
            </Box>
            {/* Add summary icon for mobile */}
            {isSmallScreen && chatSummary && (
              <Tooltip title="View Summary">
                <IconButton onClick={() => setSummaryDialogOpen(true)}>
                  <DescriptionIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {/* Client Information Section */}
          <ClientInformation 
            clientName={clientName}
            clientEmail={clientEmail}
          />

          {/* Chat Summary Section - only show on desktop */}
          {!isSmallScreen && (
            <>
              <ChatSummaryAccordion />
              {labelSegmentation && <LabelSegmentationAccordion />}
            </>
          )}
        </Box>

        {/* Scrollable Message List */}
        <MessageList>
          <AnimatePresence>
            {chatHistory
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .map((message, index) => (
                <motion.div
                  key={message.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                >
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      flexDirection: 'column',
                      padding: theme.spacing(1),
                      width: '100%',
                      [theme.breakpoints.down('sm')]: {
                        padding: theme.spacing(0.5),
                      },
                    }}
                  >
                    {/* Message header with avatar and timestamp */}
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1,
                        [theme.breakpoints.down('sm')]: {
                          mb: 0.5,
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: message.sender_message
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                          width: 24,
                          height: 24,
                        }}
                      >
                        {message.sender_message ? (
                          <PersonIcon sx={{ fontSize: 16 }} />
                        ) : (
                          <AIIcon sx={{ fontSize: 16 }} />
                        )}
                      </Avatar>
                      <Typography variant="caption" color="text.secondary">
                        {new Date(message.created_at).toLocaleString()}
                      </Typography>
                    </Box>

                    {/* Message content */}
                    <Box
                      sx={{
                        width: '100%',
                        [theme.breakpoints.down('sm')]: {
                          pl: 0, // Remove padding on mobile
                        },
                      }}
                    >
                      {message.sender_message && (
                        <MessagePaper elevation={1} isBot={false} isWhatsApp={message.whatsapp}>
                          <MarkdownContent content={message.sender_message} />
                          
                        </MessagePaper>
                      )}

                      {message.sender_file && (
                        <Box sx={{ alignSelf: 'flex-start', maxWidth: '80%' }}>
                          {message.sender_file &&
                            renderFile(message.sender_file, false, token, chatId)}
                        </Box>
                      )}
                      {message.bot_message && (
                        <MessagePaper elevation={1} isBot={true} isWhatsApp={message.whatsapp}>
                          <MarkdownContent content={message.bot_message} />
                          {message.car_stocks && message.car_stocks.length > 0 && (
                            isSmallScreen ? (
                              <MobileCarCarousel cars={message.car_stocks} />
                            ) : (
                              <CarAttachmentsContainer>
                                {message.car_stocks.map((car) => (
                                  <CarStockCard
                                    key={car.vehicleid}
                                    car={car}
                                    isSmallScreen={isSmallScreen}
                                  />
                                ))}
                              </CarAttachmentsContainer>
                            )
                          )}
                        </MessagePaper>
                      )}
                    </Box>

                    {/* Message actions */}
                    {!message.isTemp && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          mt: 1,
                        }}
                      >
                        {canEdit && (
                          <>
                            <Tooltip title="Helpful">
                              <IconButton
                                size="small"
                                onClick={() => handleFeedback(message.id, 'helpful')}
                              >
                                <ThumbUp fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Not Helpful">
                              <IconButton
                                size="small"
                                onClick={() => handleFeedback(message.id, 'not_helpful')}
                              >
                                <ThumbDown fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Add Comment">
                              <IconButton
                                size="small"
                                onClick={() => handleFeedback(message.id, 'comment')}
                              >
                                <CommentIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    )}
                  </ListItem>
                  {index < chatHistory.length - 1 && (
                    <Divider variant="fullWidth" sx={{ my: 2 }} />
                  )}
                </motion.div>
              ))}
          </AnimatePresence>
        </MessageList>

        {/* Fixed Bottom Input Section - Only show if humanControl is true */}
        {canEdit && humanControl && (
          <FixedBottomBox>
            <SendMessageForm onSendMessage={handleSendMessage} unsentMessage={unsentMessage} />
          </FixedBottomBox>
        )}

        {/* Show a message when human control is not activated */}
        {canEdit && !humanControl && (
          <FixedBottomBox>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 1 }}>
              Human control must be activated to send messages
            </Typography>
          </FixedBottomBox>
        )}

        {/* Summary Dialog for mobile */}
        <Dialog
          fullScreen
          open={summaryDialogOpen}
          onClose={() => setSummaryDialogOpen(false)}
          TransitionComponent={Fade}
        >
          <Box sx={{
            p: 2,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h6">Chat Summary</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setSummaryDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <SummarySection summary={chatSummary} />
          </DialogContent>
        </Dialog>
      </StyledPaper>

      {/* Dialogs and Snackbars */}
      <Dialog
        open={feedbackDialog.open}
        onClose={() => setFeedbackDialog({ open: false, messageId: null })}
      >
        {/* ... Dialog content ... */}
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ChatHistoryDetail;
