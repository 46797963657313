import React from 'react';
import { Paper, Box, Typography, Button, Avatar, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { Email, Phone, Event } from '@mui/icons-material';

const StyledClientCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4]
  }
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5),
}));

const ClientSearchResult = ({ client, onAddKnowledge, darkMode, isMobile }) => {
  const getInitials = (firstName, lastName) => {
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  };

  return (
    <StyledClientCard sx={{ 
      bgcolor: darkMode ? '#333' : '#fff',
      border: client.highlighted ? '2px solid #4361EE' : 'none',
      flexDirection: isMobile ? 'column' : 'row',
      gap: isMobile ? 1 : 2,
      padding: isMobile ? 1.5 : 2,
      position: 'relative',
      zIndex: 1100
    }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 2,
        width: '100%'
      }}>
        <Avatar sx={{ 
          width: isMobile ? 40 : 50, 
          height: isMobile ? 40 : 50, 
          bgcolor: 'primary.main' 
        }}>
          {getInitials(client.first_name, client.last_name)}
        </Avatar>
        
        <Box sx={{ flex: 1 }}>
          <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
            {client.name || 'Unknown Client'}
          </Typography>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {client.email && (
              <InfoItem>
                <Email fontSize="small" />
                <Typography variant="body2" sx={{ 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                  {client.email}
                </Typography>
              </InfoItem>
            )}
            {client.number && (
              <InfoItem>
                <Phone fontSize="small" />
                <Typography variant="body2">{client.number}</Typography>
              </InfoItem>
            )}
            {client.last_contact && (
              <InfoItem>
                <Event fontSize="small" />
                <Typography variant="body2">
                  Last Contact: {new Date(client.last_contact).toLocaleDateString()}
                </Typography>
              </InfoItem>
            )}
          </Box>
        </Box>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={() => onAddKnowledge(client)}
        sx={{
          alignSelf: isMobile ? 'stretch' : 'flex-start',
          minWidth: isMobile ? '100%' : '140px',
          borderRadius: '20px',
          textTransform: 'none',
          mt: isMobile ? 1 : 0
        }}
      >
        Add to Knowledge
      </Button>
    </StyledClientCard>
  );
};

export default ClientSearchResult; 