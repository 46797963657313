import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  CircularProgress,
  Slide,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack, Email, Phone, Segment, Feedback, Lock, Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import Analytics from '../analytics/Analytics';

const API_BASE_URL = 'https://admin.aitomotivelab.com/crm/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const IconText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& svg': {
    marginRight: theme.spacing(1),
  },
}));

const MobileCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }
}));

const MobileActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(1),
  }
}));

const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& table': {
      '& td': {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        paddingLeft: '50%',
        position: 'relative',
        '&:before': {
          content: 'attr(data-label)',
          position: 'absolute',
          left: theme.spacing(2),
          fontWeight: 'bold',
        }
      },
      '& th': {
        display: 'none'
      },
      '& tr': {
        marginBottom: theme.spacing(2),
        display: 'block',
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  }
}));

const FormResponsesComponent = ({ formResponses }) => {
  if (!formResponses || formResponses.length === 0) {
    return <Typography>No form responses available.</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formResponses[0].field_responses.map((response) => (
            <TableRow key={response.id}>
              <TableCell>{response.field_label}</TableCell>
              <TableCell>{response.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MobileContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: '0 auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  }
}));

const ClientDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['crm_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';

  const [client, setClient] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editMode, setEditMode] = useState(false);
  const [editedClient, setEditedClient] = useState(null);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [newFeedback, setNewFeedback] = useState({ rating: 5, comment: '' });
  const [hideClientsWithoutNames, setHideClientsWithoutNames] = useState(true);

  useEffect(() => {
    fetchClientDetails();
  }, [id]);

  const fetchClientDetails = async () => {
    try {
      const clientResponse = await api.get(`/Clients/${id}/`);
      setClient(clientResponse.data);
      setEditedClient(clientResponse.data);

      const feedbackResponse = await api.get(`/Client-feedback/?client=${id}`);
      setFeedback(feedbackResponse.data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching client details:', error);
      handleApiError(error);
      setLoading(false);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      setSnackbar({ open: true, message: 'Authentication failed. Please log in again.', severity: 'error' });
      navigate('/login');
    } else {
      setSnackbar({ open: true, message: 'An error occurred. Please try again.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedClient(client);
  };

  const handleSaveEdit = async () => {
    try {
      await api.put(`/Clients/${id}/`, editedClient);
      setClient(editedClient);
      setEditMode(false);
      setSnackbar({ open: true, message: 'Client updated successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error updating client:', error);
      handleApiError(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddFeedback = () => {
    setFeedbackDialogOpen(true);
  };

  const handleFeedbackChange = (event) => {
    const { name, value } = event.target;
    setNewFeedback((prev) => ({ ...prev, [name]: name === 'rating' ? parseInt(value, 10) : value }));
  };

  const handleFeedbackSubmit = async () => {
    try {
      const response = await api.post('/Client-feedback/', {
        ...newFeedback,
        clients: parseInt(id, 10),
      });
      setFeedback([response.data, ...feedback]);
      setFeedbackDialogOpen(false);
      setNewFeedback({ rating: 5, comment: '' });
      setSnackbar({ open: true, message: 'Feedback added successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error adding feedback:', error);
      handleApiError(error);
    }
  };

  const handleToggleHideClients = () => {
    setHideClientsWithoutNames(!hideClientsWithoutNames);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!client) {
    return <Typography>Client not found.</Typography>;
  }

  const shouldDisplayClient = !hideClientsWithoutNames || (client.first_name && client.last_name);

  return (
    <MobileContainer>
      <Box sx={{ maxWidth: '100%', padding: { xs: 2, sm: 3 }, margin: 'auto' }}>
        <MobileActionButton
          onClick={() => navigate('/clients')}
          variant="outlined"
          startIcon={<ArrowBack />}
          sx={{ mb: 2 }}
          fullWidth
        >
          Back to Clients List
        </MobileActionButton>

        <FormControlLabel
          control={
            <Switch
              checked={hideClientsWithoutNames}
              onChange={handleToggleHideClients}
              color="primary"
            />
          }
          label="Hide clients without names"
          sx={{ mb: 2, width: '100%' }}
        />

        {shouldDisplayClient ? (
          <Grid container spacing={{ xs: 1, sm: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Client Details
                {canEdit && (
                  <IconButton onClick={handleEditClick} color="primary" sx={{ ml: 2 }}>
                    <EditIcon />
                  </IconButton>
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <StyledCard>
                <CardContent>
                  {editMode ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="First Name"
                          name="first_name"
                          value={editedClient.first_name}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Last Name"
                          name="last_name"
                          value={editedClient.last_name}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          value={editedClient.email}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          name="number"
                          value={editedClient.number}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Introduction"
                          name="introduzione"
                          value={editedClient.introduzione}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button onClick={handleSaveEdit} variant="contained" color="primary" sx={{ mr: 1 }} disabled={!canEdit}>
                          Save
                        </Button>
                        <Button onClick={handleCancelEdit} variant="outlined">
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Typography variant="h5" gutterBottom>
                        {client.first_name} {client.last_name}
                      </Typography>
                      <IconText variant="body1">
                        <Email />
                        {client.email}
                      </IconText>
                      <IconText variant="body1">
                        <Phone />
                        {client.number}
                      </IconText>
                      <IconText variant="body1">
                        <Segment />
                        Segment: {client.label_segmentation_names.map((segment, index) => (
                          <Chip key={index} label={segment} color="primary" sx={{ ml: 1 }} />
                        ))}
                      </IconText>
                      <Typography variant="body1" paragraph>
                        <strong>Introduction:</strong> {client.introduzione || 'No introduction provided.'}
                      </Typography>
                      <IconText variant="body1">
                        <Lock />
                        GDPR Consent: {client.gdpr_consent ? 'Yes' : 'No'}
                      </IconText>
                    </>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12}>
              <MobileCard>
                <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
                  <Typography variant="h6" gutterBottom>
                    Form Responses
                  </Typography>
                  <ResponsiveTableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Field</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {client.form_responses && client.form_responses.length > 0 ? (
                          client.form_responses[0].field_responses.map((response) => (
                            <TableRow key={response.id}>
                              <TableCell>{response.field_label}</TableCell>
                              <TableCell>{response.value}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2}>No form responses available.</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </ResponsiveTableContainer>
                </CardContent>
              </MobileCard>
            </Grid>

            <Grid item xs={12}>
              <StyledCard>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6" gutterBottom>
                      Feedback
                    </Typography>
                    {canCreate && (
                      <Button startIcon={<AddIcon />} onClick={handleAddFeedback} variant="contained" color="primary">
                        Add Feedback
                      </Button>
                    )}
                  </Box>
                  {feedback.length > 0 ? (
                    <List>
                      {feedback.map((item) => (
                        <React.Fragment key={item.id}>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={
                                <>
                                  <Feedback /> Rating: {item.rating}
                                </>
                              }
                              secondary={
                                <>
                                  <Typography component="span" variant="body2" color="text.primary">
                                    {new Date(item.created_at).toLocaleDateString()}
                                  </Typography>
                                  {` — ${item.comment}`}
                                </>
                              }
                            />
                          </ListItem>
                          <Divider component="li" />
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <Typography>No feedback available for this client.</Typography>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12}>
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Client Analytics
                  </Typography>
                  <Analytics companyId={client.company} client={client.id} />
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h6" align="center" sx={{ mt: 4 }}>
            Client information is hidden. Toggle the switch above to view.
          </Typography>
        )}

        <Dialog open={feedbackDialogOpen} onClose={() => setFeedbackDialogOpen(false)}>
          <DialogTitle>Add New Feedback for {client.first_name} {client.last_name}</DialogTitle>
          <DialogContent>
            <TextField
              name="rating"
              label="Rating"
              type="number"
              value={newFeedback.rating}
              onChange={handleFeedbackChange}
              fullWidth
              margin="normal"
              inputProps={{ min: 1, max: 5 }}
            />
            <TextField
              name="comment"
              label="Comment"
              value={newFeedback.comment}
              onChange={handleFeedbackChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFeedbackDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleFeedbackSubmit} color="primary" variant="contained" disabled={!canCreate}>
              Submit</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </MobileContainer>
  );
};

export default ClientDetailsPage;