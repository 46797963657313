import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem, FormControl, InputLabel, Typography,
  Box, Card, CardContent, Chip, IconButton, Tooltip, Snackbar, Alert, Slide,
  CircularProgress, Pagination, Switch, FormControlLabel, Grid, Avatar, useTheme, Autocomplete
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import debounce from 'lodash.debounce';

// API configuration
const API_BASE_URL = 'https://admin.aitomotivelab.com/crm/api';

// Create an axios instance with default config
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to include the token in every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Styled components
const StyledCard = styled(motion(Card))(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 16px 48px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-4px)',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(2),
  whiteSpace: 'nowrap',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
  },
  transition: 'background-color 0.3s ease-in-out',
  transform: 'none !important',
  position: 'static !important',
  left: '0 !important',
  width: '100% !important',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  border: 0,
  borderRadius: 25,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 40,
  padding: '0 20px',
  margin: theme.spacing(1),
  textTransform: 'none',
}));

const ClientAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.palette.secondary.main,
  fontWeight: 'bold',
}));

const MobileSearchBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
      width: '100%',
    }
  }
}));

const MobileTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(1.5),
    position: 'relative',
    borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
    '&:before': {
      content: 'attr(data-label)',
      display: 'block',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      fontSize: '0.875rem',
      color: theme.palette.text.secondary
    }
  }
}));

const MobileContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: '0 auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  }
}));

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterSegment, setFilterSegment] = useState('');
  const [segments, setSegments] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [hideEmptyNames, setHideEmptyNames] = useState(true);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    number: '',
    label_segmentation: [],
    introduzione: '',
    gdpr: false,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [segmentsLoading, setSegmentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['crm_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';
  const canView = permissions.can_view || userRole === 'superadmin';

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (canView) {
      fetchClients();
    } else {
      console.warn('User does not have permission to view clients.');
    }
  }, [canView]);

  const handleToggleEmptyNames = (event) => {
    const newValue = event.target.checked;
    setHideEmptyNames(newValue);
  };

  const fetchClients = useCallback(async () => {
    try {
        const response = await api.get('/Clients/', {
            params: {
                page,
                segment: filterSegment,
                hide_empty_names: hideEmptyNames,
            }
        });
        setClients(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
        console.error('Error fetching clients:', error);
        handleApiError(error);
    } finally {
        setLoading(false);
    }
  }, [page, filterSegment, hideEmptyNames]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients, hideEmptyNames]);

  const fetchSegments = async () => {
    try {
      setSegmentsLoading(true);
      const response = await api.get('/Client-segments/');
      setSegments(response.data);
      setSegmentsLoading(false);
    } catch (error) {
      console.error('Error fetching segments:', error);
      handleApiError(error);
      setSegmentsLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
    fetchSegments();
  }, [fetchClients]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handleFilterChange = (event) => {
    setFilterSegment(event.target.value);
    setPage(1);
  };

  const handleFormOpen = (client = null) => {
    if (!canCreate && !client) {
      setSnackbar({ open: true, message: 'You do not have permission to add new clients.', severity: 'error' });
      return;
    }
    if (!canEdit && client) {
      setSnackbar({ open: true, message: 'You do not have permission to edit clients.', severity: 'error' });
      return;
    }
    if (client) {
      setFormData(client);
    } else {
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        number: '',
        label_segmentation: [],
        introduzione: '',
        gdpr: false,
      });
    }
    setIsFormOpen(true);
  };

  const handleFormClose = () => {
    setFormData({
      first_name: '',
      last_name: '',
      email: '',
      number: '',
      label_segmentation: [],
      introduzione: '',
      gdpr: false,
    });
    setIsFormOpen(false);
    
    setTimeout(() => {
      const tableRows = document.querySelectorAll('tr');
      tableRows.forEach(row => {
        row.style.transform = 'none';
        row.style.position = 'static';
        row.style.left = '0';
      });
    }, 0);
  };

  const handleFormChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formData.id) {
        await api.put(`/Clients/${formData.id}/`, formData);
        setSnackbar({ open: true, message: 'Client updated successfully!', severity: 'success' });
      } else {
        await api.post('/Clients/', formData);
        setSnackbar({ open: true, message: 'New client added successfully!', severity: 'success' });
      }
      fetchClients();
      handleFormClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      handleApiError(error);
    }
  };

  const handleDeleteClick = (client) => {
    if (!canDelete) {
      setSnackbar({ open: true, message: 'You do not have permission to delete clients.', severity: 'error' });
      return;
    }
    setClientToDelete(client);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (clientToDelete) {
      try {
        await api.delete(`/Clients/${clientToDelete.id}/`);
        setSnackbar({ open: true, message: 'Client deleted successfully!', severity: 'success' });
        fetchClients();
      } catch (error) {
        console.error('Error deleting client:', error);
        handleApiError(error);
      }
    }
    setDeleteConfirmOpen(false);
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      setSnackbar({ open: true, message: 'Authentication failed. Please log in again.', severity: 'error' });
      navigate('/login');
    } else {
      setSnackbar({ open: true, message: 'An error occurred. Please try again.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  const fetchSearchResults = useCallback(async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    
    try {
      setIsSearching(true);
      const response = await api.get('/Clients/', {
        params: {
          search: query,
          page_size: 10 // Limit the number of search results
        }
      });
      setSearchResults(response.data.results);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      fetchSearchResults(query);
    }, 300),
    [fetchSearchResults]
  );

  return (
    <MobileContainer>
      <Box sx={{ 
        maxWidth: '100%', 
        margin: 'auto', 
        padding: { xs: 2, sm: 3 },
        paddingTop: { xs: '60px', sm: 3 },
        paddingBottom: { xs: '80px', sm: 3 },
        width: { xs: '100%', sm: 'auto' }
      }}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  freeSolo
                  options={searchResults}
                  getOptionLabel={(option) => 
                    typeof option === 'string' 
                      ? option 
                      : `${option.first_name} ${option.last_name} (${option.email})`
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ClientAvatar sx={{ mr: 2 }}>
                          {option.first_name[0]}{option.last_name[0]}
                        </ClientAvatar>
                        <Box>
                          <Typography variant="subtitle1">
                            {option.first_name} {option.last_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {option.email}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Clients"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <SearchIcon color="action" />,
                        endAdornment: (
                          <>
                            {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    debouncedSearch(newInputValue);
                  }}
                  onChange={(event, newValue) => {
                    if (newValue && typeof newValue === 'object') {
                      setSelectedClient(newValue);
                      setSearchQuery('');
                      setSearchResults([]);
                    } else if (!newValue) {
                      setSelectedClient(null);
                      setSearchResults([]);
                    }
                  }}
                  filterOptions={(x) => x} // Disable built-in filtering
                  loading={isSearching}
                  loadingText="Searching..."
                  noOptionsText={searchQuery.trim() === '' ? 'Start typing to search' : 'No clients found'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hideEmptyNames}
                      onChange={handleToggleEmptyNames}
                      color="primary"
                    />
                  }
                  label="Hide clients without names"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Filter by Segment</InputLabel>
                  <Select
                    value={filterSegment}
                    onChange={handleFilterChange}
                    label="Filter by Segment"
                    startAdornment={<FilterListIcon color="action" />}
                    disabled={segmentsLoading}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {segments.map((segment) => (
                      <MenuItem key={segment.id} value={segment.id}>
                        {segment.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'row', sm: 'row' },
                    gap: { xs: 1, sm: 2 },
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                >
                  {canCreate && (
                    <StyledButton
                      onClick={() => handleFormOpen()}
                      startIcon={<AddIcon />}
                      size="small"
                      sx={{
                        flex: { xs: 1, sm: 'initial' },
                        fontSize: { xs: '0.7rem', sm: '0.875rem' },
                        padding: { xs: '6px 16px', sm: '8px 24px' },
                        minWidth: { xs: 0, sm: 'auto' },
                        '& .MuiButton-startIcon': {
                          marginRight: { xs: '4px', sm: '8px' }
                        }
                      }}
                    >
                      Add Client
                    </StyledButton>
                  )}
                  <StyledButton
                    component={Link}
                    to="/clients/segments"
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{
                      flex: { xs: 1, sm: 'initial' },
                      fontSize: { xs: '0.7rem', sm: '0.875rem' },
                      padding: { xs: '6px 16px', sm: '8px 24px' },
                      minWidth: { xs: 0, sm: 'auto' },
                      '& .MuiButton-startIcon': {
                        marginRight: { xs: '4px', sm: '8px' }
                      }
                    }}
                  >
                    Add Segment
                  </StyledButton>
                  <Tooltip title="Refresh">
                    <IconButton 
                      onClick={fetchClients} 
                      color="primary"
                      sx={{ 
                        display: { xs: 'none', sm: 'inline-flex' }
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>

            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="300px" mt={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TableContainer component={Paper} sx={{ mt: 2, overflow: 'hidden' }}>
                  <Table sx={{ minWidth: { xs: '100%', sm: 650 } }}>
                    <TableHead sx={{ display: { xs: 'none', sm: 'table-header-group' } }}>
                      <TableRow>
                        <StyledTableCell width="40%">Client</StyledTableCell>
                        <StyledTableCell width="30%">Email</StyledTableCell>
                        <StyledTableCell width="15%">Segment</StyledTableCell>
                        <StyledTableCell width="15%" align="center">Actions</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <AnimatePresence>
                        {clients.map((client) => (
                          <StyledTableRow key={client.id}>
                            <MobileTableCell data-label="Client">
                              <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                width: '100%',
                                mb: { xs: 1, sm: 0 }
                              }}>
                                <ClientAvatar sx={{ mr: 2 }}>
                                  {client.first_name[0]}{client.last_name[0]}
                                </ClientAvatar>
                                <Box>
                                  <Typography variant="subtitle1">
                                    {client.first_name} {client.last_name}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {client.number}
                                  </Typography>
                                </Box>
                              </Box>
                            </MobileTableCell>

                            <MobileTableCell data-label="Email">
                              <Typography variant="body2">
                                {client.email}
                              </Typography>
                            </MobileTableCell>

                            <MobileTableCell data-label="Segment">
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {client.label_segmentation.map((segmentId) => {
                                  const segment = segments.find(s => s.id === segmentId);
                                  return (
                                    <Chip
                                      key={segmentId}
                                      label={segment ? segment.name : 'Unknown'}
                                      color="primary"
                                      variant="outlined"
                                      size="small"
                                      sx={{ mb: 0.5 }}
                                    />
                                  );
                                })}
                              </Box>
                            </MobileTableCell>

                            <MobileTableCell data-label="Actions">
                              <Box sx={{ 
                                display: 'flex', 
                                gap: 1,
                                justifyContent: 'flex-start'
                              }}>
                                <Tooltip title="View Details">
                                  <IconButton 
                                    component={Link} 
                                    to={`/clients/${client.id}`} 
                                    color="primary"
                                    size="small"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                {canEdit && (
                                  <Tooltip title="Edit Client">
                                    <IconButton 
                                      onClick={() => handleFormOpen(client)} 
                                      color="secondary"
                                      size="small"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {canDelete && (
                                  <Tooltip title="Delete Client">
                                    <IconButton 
                                      onClick={() => handleDeleteClick(client)} 
                                      color="error"
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            </MobileTableCell>
                          </StyledTableRow>
                        ))}
                      </AnimatePresence>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mt: 2,
                    pb: { xs: 2, sm: 0 },
                    position: { xs: 'sticky', sm: 'static' },
                    bottom: 0,
                    backgroundColor: 'background.default',
                    zIndex: 1,
                    width: '100%',
                    '& .MuiPagination-ul': {
                      flexWrap: 'nowrap',
                      overflowX: 'auto',
                      pb: 1,
                      px: 2,
                      width: '100%',
                      justifyContent: 'center',
                      '&::-webkit-scrollbar': {
                        display: 'none'
                      }
                    }
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                    sx={{
                      '& .MuiPaginationItem-root': {
                        minWidth: { xs: '32px', sm: '40px' },
                        height: { xs: '32px', sm: '40px' },
                        fontSize: { xs: '0.875rem', sm: '1rem' }
                      },
                      '& .MuiPaginationItem-firstLast': {
                        mx: 0.5
                      }
                    }}
                  />
                </Box>
              </>
            )}
          </CardContent>
        </StyledCard>
      </Box>

      <Dialog 
        open={isFormOpen} 
        onClose={handleFormClose}
        maxWidth="sm" 
        fullWidth
        TransitionProps={{
          onExited: () => {
            setFormData({
              first_name: '',
              last_name: '',
              email: '',
              number: '',
              label_segmentation: [],
              introduzione: '',
              gdpr: false,
            });
            const tableRows = document.querySelectorAll('tr');
            tableRows.forEach(row => {
              row.style.transform = 'none';
              row.style.position = 'static';
              row.style.left = '0';
            });
          }
        }}
      >
        <DialogTitle>{formData.id ? 'Edit Client' : 'Add New Client'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit}>
            <TextField
              name="first_name"
              label="First Name"
              value={formData.first_name}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="last_name"
              label="Last Name"
              value={formData.last_name}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="number"
              label="Phone Number"
              value={formData.number}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Segment</InputLabel>
              <Select
                multiple
                name="label_segmentation"
                value={formData.label_segmentation}
                onChange={handleFormChange}
                disabled={segmentsLoading}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const segment = segments.find(s => s.id === value);
                      return (
                        <Chip key={value} label={segment ? segment.name : 'Unknown'} />
                      );
                    })}
                  </Box>
                )}
              >
                {segments.map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="introduzione"
              label="Introduction"
              value={formData.introduzione}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>GDPR Consent</InputLabel>
              <Select
                name="gdpr"
                value={formData.gdpr}
                onChange={handleFormChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>Cancel</Button>
          <Button onClick={handleFormSubmit} color="primary" variant="contained">
            {formData.id ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this client? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </MobileContainer>
  );
};

export default ClientManagement;