import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import "./App.css";
import App from './App';




if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('SW registered:', registration);
      })
      .catch(error => {
        console.log('SW registration failed:', error);
      });
  });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);


