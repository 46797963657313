import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Typography, 
  Alert, 
  Paper, 
  CircularProgress,
  Box,
  Container,
  List,
  ListItem,
  Avatar,
  CardMedia,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip,
  Fade
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileStepper from '@mui/material/MobileStepper';
import { 
  KeyboardArrowLeft, 
  KeyboardArrowRight,
  Description as DescriptionIcon,
  Close as CloseIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  AudioFile as AudioIcon
} from '@mui/icons-material';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const BASE_URL = 'https://admin.aitomotivelab.com';

// Add styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f5f5f5',
  boxShadow: 'none',
  overflow: 'hidden',
  position: 'relative',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF6B6B, #4ECDC4, #45B7D1)',
  },
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(1),
  paddingTop: `calc(${theme.spacing(8)} + 4px)`,
  paddingBottom: theme.spacing(10),
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(12),
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    opacity: 1,
  },
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.primary.main} transparent`,
  '&:not(:hover)': {
    scrollbarColor: 'transparent transparent',
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  backgroundColor: isBot ? theme.palette.primary.light : theme.palette.background.paper,
  color: isBot ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderRadius: '12px',
  maxWidth: '100%',
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    borderRadius: '8px',
    marginLeft: 0,
    marginRight: 0,
    '& img': {
      borderRadius: '4px',
    }
  }
}));

const CarAttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
  }
}));

const CarCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
  },
}));

const FileDisplay = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  '& audio': {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

const MobileCarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflowX: 'hidden',
  '& .carousel-content': {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    touchAction: 'pan-y pinch-zoom',
  },
}));

const MobileCarCarousel = ({ cars }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = cars.length;
  const touchStart = useRef(null);
  const touchEnd = useRef(null);

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && activeStep < maxSteps - 1) {
      setActiveStep(prev => prev + 1);
    }
    if (isRightSwipe && activeStep > 0) {
      setActiveStep(prev => prev - 1);
    }
  };

  return (
    <MobileCarouselContainer>
      <Box 
        className="carousel-content" 
        sx={{ 
          width: '100%', 
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
              style={{ width: '100%' }}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              <Card sx={{
                width: '94%',
                margin: '0 auto',
                borderRadius: 2,
                overflow: 'hidden',
                backgroundColor: 'background.paper',
                boxShadow: 3,
              }}>
                {cars[activeStep].urlmainimage && (
                  <Box sx={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={cars[activeStep].urlmainimage}
                      alt={`${cars[activeStep].brand} ${cars[activeStep].model}`}
                      sx={{ objectFit: 'cover' }}
                    />
                    <Box sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
                      p: 2,
                    }}>
                      <Typography variant="h6" sx={{ 
                        color: 'white', 
                        textShadow: '1px 1px 2px rgba(0,0,0,0.6)',
                        fontSize: '1.25rem',
                        fontWeight: 'bold'
                      }}>
                        {cars[activeStep].brand} {cars[activeStep].model}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 1
                    }}>
                      <Typography variant="h5" color="primary" sx={{ 
                        fontWeight: 'bold', 
                        fontSize: '1.75rem' 
                      }}>
                        €{cars[activeStep].saleprice?.toLocaleString()}
                      </Typography>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 2, 
                      mt: 1,
                      justifyContent: 'space-between'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                        p: 2,
                        bgcolor: 'background.default',
                        borderRadius: 2
                      }}>
                        <Typography variant="body2" color="text.secondary">Anno</Typography>
                        <Typography variant="h6" fontWeight="medium">
                          {cars[activeStep].registrationyear}
                        </Typography>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                        p: 2,
                        bgcolor: 'background.default',
                        borderRadius: 2
                      }}>
                        <Typography variant="body2" color="text.secondary">Chilometri</Typography>
                        <Typography variant="h6" fontWeight="medium">
                          {cars[activeStep].km?.toLocaleString()} km
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Box>

      {/* Enhanced MobileStepper */}
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 2,
      }}>
        {maxSteps > 1 && (
          <>
            <Typography 
              variant="caption" 
              color="text.secondary" 
              sx={{ mb: 1 }}
            >
              Scorri per vedere altre auto
            </Typography>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{
                background: 'transparent',
                '& .MuiMobileStepper-dots': {
                  gap: '8px',
                },
                '& .MuiMobileStepper-dot': {
                  width: 12,
                  height: 12,
                  margin: 0,
                  transition: 'all 0.3s ease',
                },
                '& .MuiMobileStepper-dotActive': {
                  width: 24,
                  backgroundColor: 'primary.main',
                  borderRadius: '6px',
                },
              }}
            />
          </>
        )}
      </Box>
    </MobileCarouselContainer>
  );
};

// Add this component for desktop grid view
const CarStockCard = ({ car }) => (
  <Card sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    overflow: 'hidden',
    backgroundColor: 'background.paper',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
    },
  }}>
    {car.urlmainimage && (
      <CardMedia
        component="img"
        height="160"
        image={car.urlmainimage}
        alt={`${car.brand} ${car.model}`}
        sx={{ objectFit: 'cover' }}
      />
    )}
    <CardContent sx={{ p: 2, flexGrow: 1 }}>
      <Typography variant="h6" gutterBottom noWrap>
        {car.brand} {car.model}
      </Typography>
      <Typography variant="h5" color="primary" gutterBottom>
        €{car.saleprice?.toLocaleString()}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          p: 1,
          bgcolor: 'background.default',
          borderRadius: 1
        }}>
          <Typography variant="caption" color="text.secondary">Year</Typography>
          <Typography variant="body1" fontWeight="medium">
            {car.registrationyear}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          p: 1,
          bgcolor: 'background.default',
          borderRadius: 1
        }}>
          <Typography variant="caption" color="text.secondary">Mileage</Typography>
          <Typography variant="body1" fontWeight="medium">
            {car.km?.toLocaleString()} km
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

// Add a new styled component for the header
const FixedHeader = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1200,
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backdropFilter: 'blur(8px)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(26, 32, 39, 0.95)'
    : 'rgba(255, 255, 255, 0.95)',
}));

const OpenChat = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const { chatId } = useParams();
  const [chatData, setChatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [chatSummary, setChatSummary] = useState('');

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/ai_app/chat-histories/full_chat/?chat_id=${chatId}`);
        setChatData(response.data);
        setChatSummary(response.data[0]?.chat?.summary || '');
        console.log("Chat history:", response.data);
      } catch (error) {
        console.error("Error fetching chat:", error);
        setError(error.response?.data?.detail || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (chatId) {
      fetchChatData();
    }
  }, [chatId]);

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const getFileUrl = (fileObject) => {
    if (!fileObject) return null;

    // If fileObject is a string, return it directly
    if (typeof fileObject === 'string') return fileObject;

    // If fileObject is an object with a file_url property, return that
    if (typeof fileObject === 'object' && fileObject.file_url) {
      return fileObject.file_url;
    }

    return null;
  };

  const getFileDetails = (fileUrl) => {
    if (!fileUrl) return { fileName: 'Unknown File', fileExtension: '' };

    const urlString = String(fileUrl);
    const urlWithoutParams = urlString.split('?')[0];
    const urlParts = urlWithoutParams.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';

    return { fileName, fileExtension };
  };

  const getAbsoluteUrl = (relativeUrl) => {
    if (!relativeUrl) return null;

    const urlString = String(relativeUrl);

    // If URL is already absolute, return it
    if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
      return urlString;
    }

    // Construct the absolute URL using the API_DOMAIN
    return `${BASE_URL}${urlString.startsWith('/') ? '' : '/'}${urlString}`;
  };

  const renderFile = (fileObject, isBot) => {
    const fileUrl = getFileUrl(fileObject);
    if (!fileUrl) {
      console.log('No file URL provided:', fileObject);
      return null;
    }

    const absoluteUrl = getAbsoluteUrl(fileUrl);
    if (!absoluteUrl) {
      console.error('Unable to generate absolute URL for:', fileUrl);
      return null;
    }

    const { fileExtension } = getFileDetails(fileUrl);
    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'].includes(fileExtension.toLowerCase());

    return (
      <FileDisplay>
        {isImage ? (
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 0.5, sm: 1 }
          }}>
            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
              Immagine non visualizzabile per motivi di sicurezza
            </Typography>
          </Box>
        ) : (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            p: 1,
            borderRadius: 1,
            bgcolor: 'background.default',
            width: 'fit-content'
          }}>
            <FileIcon />
            <Typography variant="body2">
              {getFileDetails(fileUrl).fileName}
            </Typography>
          </Box>
        )}
      </FileDisplay>
    );
  };

  const renderMessageContent = (message) => {
    return (
      <Box sx={{ width: '100%' }}>
        {/* User Message with possible file attachment */}
        {(message.sender_message || message.sender_file) && (
          <MessagePaper
            elevation={1}
            isBot={false}
            sx={{
              ml: 'auto',
              borderRadius: '12px',
              '& img': {
                maxWidth: '100%',
                height: 'auto'
              }
            }}
          >
            <Typography 
              variant="caption" 
              color="text.secondary" 
              display="block" 
              gutterBottom
              sx={{ mb: 1 }}
            >
              Cliente • {new Date(message.created_at).toLocaleString()}
            </Typography>
            
            {/* Display file/image if present */}
            {message.sender_file && message.sender_file.file_url && (
              <Box sx={{ mb: message.sender_message ? 2 : 0 }}>
                {renderFile(message.sender_file, false)}
              </Box>
            )}
            
            {/* Display text message if present */}
            {message.sender_message && (
              <MarkdownContent content={message.sender_message} />
            )}
          </MessagePaper>
        )}

        {/* Assistant Message */}
        {message.bot_message && (
          <MessagePaper
            elevation={1}
            isBot={true}
            sx={{
              mr: 'auto',
              borderRadius: '12px',
              '& img': {
                maxWidth: '100%',
                height: 'auto'
              }
            }}
          >
            <Typography 
              variant="caption" 
              color="text.secondary" 
              display="block" 
              gutterBottom
              sx={{ mb: 1 }}
            >
              Autocentri Giustozzi • {new Date(message.created_at).toLocaleString()}
            </Typography>
            <MarkdownContent content={message.bot_message} />
            
            {/* Car stocks display */}
            {message.car_stocks && message.car_stocks.length > 0 && (
              isSmallScreen ? (
                <MobileCarCarousel cars={message.car_stocks} />
              ) : (
                <CarAttachmentsContainer>
                  {message.car_stocks.map((car) => (
                    <CarStockCard key={car.vehicleid} car={car} />
                  ))}
                </CarAttachmentsContainer>
              )
            )}
          </MessagePaper>
        )}
      </Box>
    );
  };

  const handleSummaryClick = () => {
    setSummaryDialogOpen(true);
  };

  const handleCloseSummaryDialog = () => {
    setSummaryDialogOpen(false);
  };

  const ChatSummaryButton = () => (
    <Tooltip title="Chat Summary">
      <IconButton onClick={handleSummaryClick} size="small">
        <DescriptionIcon />
      </IconButton>
    </Tooltip>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <Alert severity="error" variant="filled">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container 
      maxWidth={false}
      sx={{ 
        p: 0,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <StyledPaper 
        elevation={3}
        sx={{
          height: '100vh',
          borderRadius: 0,
        }}
      >
        <FixedHeader>
          <Typography variant="h5" sx={{ 
            fontSize: { xs: '1.2rem', sm: '1.5rem' },
            fontWeight: 500,
          }}>
            Chat with {chatData[0]?.chat?.client_name || 'User'}
          </Typography>
          <ChatSummaryButton />
        </FixedHeader>

        <MessageList>
          <AnimatePresence>
            {chatData.map((message, index) => (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    flexDirection: 'column',
                    padding: 1,
                    width: '100%',
                    mb: {
                      xs: index === chatData.length - 1 ? 10 : 2,
                      sm: index === chatData.length - 1 ? 2 : 0
                    }
                  }}
                >
                  {renderMessageContent(message)}
                </ListItem>
                {index < chatData.length - 1 && (
                  <Divider variant="fullWidth" sx={{ my: 2 }} />
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </MessageList>

        <Dialog
          fullScreen
          open={summaryDialogOpen}
          onClose={handleCloseSummaryDialog}
          TransitionComponent={Fade}
        >
          <Box sx={{
            p: 2,
            backgroundColor: 'background.paper',
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h6">Chat Summary</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseSummaryDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
              {chatSummary}
            </Typography>
          </DialogContent>
        </Dialog>
      </StyledPaper>
    </Container>
  );
};

export default OpenChat;