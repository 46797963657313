import React, { useEffect, useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Grid, 
  CircularProgress, 
  Typography,
  Paper,
  Tabs,
  Tab,
  IconButton
} from '@mui/material';
import { 
  ListFilter, 
  ChevronUp, 
  X,
  RefreshCcw,
  Car,
  UserRound
} from 'lucide-react';
import CarSearchResult from './CarSearchResult';
import ClientSearchResult from './ClientSearchResult';
import SearchFilters from './SearchFilters';
import axios from 'axios';

const API_BASE_URL = 'https://admin.aitomotivelab.com/car_stock/api';
const CRM_API_URL = 'https://admin.aitomotivelab.com/crm/api';

const SearchPanel = ({ 
  searchTab,
  setSearchTab,
  carSearchTerm,
  setCarSearchTerm,
  showFilters,
  setShowFilters,
  clientSearchTerm,
  setClientSearchTerm,
  searchResults,
  setSearchResults,
  clientSearchResults,
  setClientSearchResults,
  searchLoading,
  setSearchLoading,
  clientSearchLoading,
  setClientSearchLoading,
  isFullscreen,
  handleAddCarKnowledge,
  handleAddClientKnowledge,
  darkMode,
  filters,
  setFilters,
  filterOptions,
  setFilterOptions,
  onClose,
  isMobile,
  showCloseButton = false,
}) => {

  // Add useEffect to handle body scroll
  useEffect(() => {
    if (isMobile) {
      // Prevent background scroll when panel is open
      document.body.style.overflow = 'hidden';
      
      // Cleanup when component unmounts
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isMobile]);

  // Fetch car data
  const fetchCarData = async (term) => {
    if (!term && !Object.values(filters).some(value => value !== '')) {
      setSearchResults([]);
      return;
    }

    try {
      setSearchLoading(true);
      const response = await axios.get(`${API_BASE_URL}/carstocks/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          search: term,
          brand: filters.brand || undefined,
          min_price: filters.min_price || undefined,
          max_price: filters.max_price || undefined,
          min_year: filters.min_year || undefined,
          max_year: filters.max_year || undefined,
          min_km: filters.min_km || undefined,
          max_km: filters.max_km || undefined,
          fuel: filters.fuel || undefined,
        }
      });
      setSearchResults(response.data.results || []);
    } catch (error) {
      console.error('Error fetching car data:', error);
    } finally {
      setSearchLoading(false);
    }
  };

  // Fetch client data
  const fetchClientData = async (term) => {
    if (!term.trim()) {
      setClientSearchResults([]);
      return;
    }

    try {
      setClientSearchLoading(true);
      const response = await axios.get(`${CRM_API_URL}/Clients/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          search: term,
          page_size: 10
        }
      });
      
      const clients = response.data.results || [];
      setClientSearchResults(clients.map(client => ({
        id: client.id,
        name: `${client.first_name} ${client.last_name}`.trim(),
        email: client.email,
        phone: client.number,
        last_contact: client.last_contact,
        first_name: client.first_name,
        last_name: client.last_name,
        number: client.number
      })));
    } catch (error) {
      console.error('Error fetching client data:', error);
      setClientSearchResults([]);
    } finally {
      setClientSearchLoading(false);
    }
  };

  // Fetch filter options when component mounts
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/filter-options/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setFilterOptions(response.data);
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    };
    
    fetchFilterOptions();
  }, [setFilterOptions]);

  // Update search when car search term or filters change
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchCarData(carSearchTerm);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [carSearchTerm, filters]);

  // Update search when client search term changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchClientData(clientSearchTerm);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [clientSearchTerm]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      ...(isMobile && {
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
      })
    }}>
      {/* Header with Title */}
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        position: 'sticky',
        top: 0,
        zIndex: 1300,
        bgcolor: darkMode ? '#1E1E1E' : '#fff',
      }}>
        <Typography variant="h6">
          {isMobile ? 'Search' : 'Knowledge Base'}
        </Typography>
      </Box>

      {/* Tabs and Search Content */}
      <Paper sx={{ 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        bgcolor: darkMode ? '#333' : '#fff',
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
        }}>
          <Tabs
            value={searchTab}
            onChange={(e, newValue) => {
              setSearchTab(newValue);
              setShowFilters(false);
            }}
            sx={{ mb: 2 }}
          >
            <Tab value="cars" label="Cars" icon={<Car className="h-4 w-4" />} iconPosition="start" />
            <Tab value="clients" label="Clients" icon={<UserRound />} iconPosition="start" />
          </Tabs>
          
          {(isMobile || showCloseButton) && (
            <IconButton 
              onClick={onClose}
              sx={{ 
                color: darkMode ? '#fff' : '#333',
                ml: 2
              }}
            >
              <X size={20} />
            </IconButton>
          )}
        </Box>

        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%'
        }}>
          {searchTab === 'cars' ? (
            <>
              <TextField
                fullWidth
                size="small"
                placeholder="Search cars..."
                value={carSearchTerm}
                onChange={(e) => setCarSearchTerm(e.target.value)}
              />
              <Box sx={{ 
                display: 'flex', 
                gap: 1,
                width: isMobile ? '100%' : 'auto'
              }}>
                <Button
                  fullWidth={isMobile}
                  variant="outlined"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  startIcon={<ListFilter className="h-4 w-4" />}
                  endIcon={showFilters ? <ChevronUp /> : <ChevronUp />}
                >
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>
                <Button
                  fullWidth={isMobile}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setCarSearchTerm('');
                    setFilters({
                      min_price: '',
                      max_price: '',
                      min_year: '',
                      max_year: '',
                      min_km: '',
                      max_km: '',
                      brand: '',
                      model: '',
                      fuel: '',
                      bodystyle: '',
                      transmission: '',
                      color: '',
                    });
                  }}
                  startIcon={<RefreshCcw size={16} />}
                >
                  Reset
                </Button>
              </Box>
            </>
          ) : (
            <TextField
              fullWidth
              size="small"
              placeholder="Search clients..."
              value={clientSearchTerm}
              onChange={(e) => setClientSearchTerm(e.target.value)}
            />
          )}
        </Box>
      </Paper>

      {/* Filters */}
      {searchTab === 'cars' && showFilters && (
        <SearchFilters 
          filters={filters}
          filterOptions={filterOptions}
          handleFilterChange={handleFilterChange}
          isFullscreen={isFullscreen}
          darkMode={darkMode}
        />
      )}

      {/* Results */}
      <Box sx={{ 
        mt: 2,
        flex: 1,
        overflowY: 'auto',
        height: showFilters ? 'calc(100% - 280px)' : 'calc(100% - 180px)',
        px: 2,
        ...(isMobile && {
          WebkitOverflowScrolling: 'touch',
          position: 'relative',
          zIndex: 1100
        })
      }}>
        {searchTab === 'cars' ? (
          <Grid container spacing={2.5}>
            {searchResults.map((car) => (
              <Grid 
                item 
                xs={12} 
                md={isFullscreen ? 6 : 12} 
                lg={isFullscreen ? 4 : 12} 
                xl={isFullscreen ? 3 : 12} 
                key={car.id}
              >
                <CarSearchResult 
                  car={car} 
                  isFullscreen={isFullscreen} 
                  onAddKnowledge={handleAddCarKnowledge}
                  darkMode={darkMode}
                />
              </Grid>
            ))}
            {searchLoading && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {clientSearchTerm && clientSearchResults.length === 0 && !clientSearchLoading ? (
              <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary" align="center">
                  No clients found matching your search.
                </Typography>
              </Grid>
            ) : (
              clientSearchResults.map((client) => (
                <Grid item xs={12} key={client.id}>
                  <ClientSearchResult 
                    client={client}
                    onAddKnowledge={handleAddClientKnowledge}
                    darkMode={darkMode}
                    isMobile={isMobile}
                  />
                </Grid>
              ))
            )}
            {clientSearchLoading && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default SearchPanel; 