import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Tooltip,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Grid,
  Container,
  Card,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Add, Delete, Edit, DragIndicator, Save, Visibility, VisibilityOff, KeyboardBackspaceRounded, SettingsRounded, InsertDriveFileRounded } from '@mui/icons-material';
import PropertyDialog from './PropertyDialog';
import PropertyGroupDialog from './PropertyGroupDialog';


import { Trash2, Pencil, Bolt, StickyNote } from 'lucide-react';


const API_BASE_URL = 'https://admin.aitomotivelab.com/gestionale/api';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    third: {
      main: '#4CC9F0',
    },
    background: {
      main: 'rgba(163, 174, 208, 0.05)'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#757575',


          '&: hover': {
            boxShadow: 'none',
            color: '#4895EF',
            backgroundColor: 'rgba(163, 174, 208, 0.05)',
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.05)',
        }
      },
    }
  }
});

const WarehouseBuilder = () => {
  const { warehouseId } = useParams();
  const navigate = useNavigate();

  console.log(warehouseId)
  const [warehouse, setWarehouse] = useState({
    name: '',
    description: '',
    public: false,
    categories: [],
    sharedPropertyGroups: []
  });

  const [isEditing, setIsEditing] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isAddingProperty, setIsAddingProperty] = useState(false);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isAddingPropertyGroup, setIsAddingPropertyGroup] = useState(false);
  const [isEditingPropertyGroup, setIsEditingPropertyGroup] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  // const [WarehouseTitle, setWarehouseTitle] = useState('');

  const [currentProperty, setCurrentProperty] = useState({
    property_id: '',
    name: '',
    description: '',
    property_type: 'text',
    options: [],
    required: false,
    property_group: '',
  });

  const [currentCategory, setCurrentCategory] = useState({
    category_id: '',
    name: '',
    order: 0,
  });

  const [currentPropertyGroup, setCurrentPropertyGroup] = useState({
    property_group_id: '',
    name: '',
    description: '',
    conditions: [],
    rules: {
      is_visible: true,
      is_collapsible: false,
      is_required: false,
      is_repeatable: false,
    },
    order: 0,
    properties: [],
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (warehouseId) {
      setIsEditing(true);
      fetchWarehouse();
    }
  }, [warehouseId]);

  

  const fetchWarehouse = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/warehouses/${warehouseId}/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
  
      const fetchedWarehouse = response.data;
      console.log(fetchedWarehouse)
      setWarehouse({
        ...fetchedWarehouse,
        public: fetchedWarehouse.public || false,
        sharedPropertyGroups: fetchedWarehouse.categories[0]?.property_groups || [],
        categories: fetchedWarehouse.categories.map(category => ({
          ...category,
          property_groups: category.property_groups || []
        }))
      });
      setSelectedCategory(fetchedWarehouse.categories[0]?.category_id || null);
      setIsLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching warehouse data', severity: 'error' });
      setIsLoading(false);
    }
  };

  const generateNewId = (existingIds) => {
    if (existingIds.length === 0) return 1;
    return Math.max(...existingIds) + 1;
  };

  const handleAddProperty = (groupId) => {
    setCurrentProperty({
      property_id: null,
      name: '',
      description: '',
      property_type: 'text',
      options: [],
      required: false,
      property_group_id: groupId,
    });
    setIsAddingProperty(true);
  };

  const handleAddCategory = () => {
    const newCategoryId = generateNewId(warehouse.categories.map(c => c.category_id));
    setCurrentCategory({
      category_id: newCategoryId,
      name: '',
      order: warehouse.categories.length + 1,
    });
    setIsAddingCategory(true);
  };
  
  const handleAddPropertyGroup = () => {
    const newGroupId = generateNewId(warehouse.sharedPropertyGroups.map(g => g.property_group_id));
    setCurrentPropertyGroup({
      property_group_id: newGroupId,
      name: '',
      description: '',
      conditions: [],
      rules: {
        is_visible: true,
        is_collapsible: false,
        is_required: false,
        is_repeatable: false,
      },
      order: warehouse.sharedPropertyGroups.length + 1,
      properties: [],
    });
    setIsAddingPropertyGroup(true);
  };

  const handleEditPropertyGroup = (propertyGroupId) => {
    const propertyGroup = warehouse.sharedPropertyGroups.map(pg => pg.property_group_id === propertyGroupId);
    setCurrentPropertyGroup({ ...propertyGroup });
    setIsEditingPropertyGroup(true);
  };

  const handleSaveCategory = () => {
    if (currentCategory.name) {
      setWarehouse(prevWarehouse => ({
        ...prevWarehouse,
        categories: [...prevWarehouse.categories, currentCategory]
      }));
      setSelectedCategory(currentCategory.category_id);
      setCurrentCategory({ category_id: null, name: '', order: 0 });
      setIsAddingCategory(false);
      setSnackbar({ open: true, message: 'Magazzino aggiunto', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: 'Il nome del magazzino è obbligatorio', severity: 'error' });
    }
  };

  const handleSavePropertyGroup = (group) => {
    if (group.name.trim() === '') {
      setSnackbar({ open: true, message: 'Property Group name is required', severity: 'error' });
      return;
    }
    
    setWarehouse(prevWarehouse => {
      let updatedPropertyGroups;
      
      if (isEditingPropertyGroup) {
        updatedPropertyGroups = prevWarehouse.sharedPropertyGroups.map(pg =>
          pg.property_group_id === group.property_group_id ? group : pg
        );
      } else {
        updatedPropertyGroups = [...prevWarehouse.sharedPropertyGroups, group];
      }
      
      return {
        ...prevWarehouse,
        sharedPropertyGroups: updatedPropertyGroups
      };
    });
    
    setIsAddingPropertyGroup(false);
    setIsEditingPropertyGroup(false);
    setSnackbar({ open: true, message: `Property Group ${isEditingPropertyGroup ? 'updated' : 'added'} successfully`, severity: 'success' });
  };

  const handleSaveProperty = (propertyData) => {
    if (propertyData.name.trim() === '') {
      setSnackbar({ open: true, message: 'Property name is required', severity: 'error' });
      return;
    }
    setWarehouse(prevWarehouse => {
      const updatedPropertyGroups = prevWarehouse.sharedPropertyGroups.map(group => {
        if (group.property_group_id === propertyData.property_group_id) {
          let updatedProperties;
          if (propertyData.property_id) {
            updatedProperties = group.properties.map(prop =>
              prop.property_id === propertyData.property_id ? propertyData : prop
            );
          } else {
            const newPropertyId = (Math.max(0, ...group.properties.map(p => p.property_id)) + 1).toString();
            const newProperty = { ...propertyData, property_id: newPropertyId };
            updatedProperties = [...group.properties, newProperty];
          }
          return { ...group, properties: updatedProperties };
        }
        return group;
      });
      return { ...prevWarehouse, sharedPropertyGroups: updatedPropertyGroups };
    });
    
    setIsAddingProperty(false);
    setSnackbar({ open: true, message: `Property ${propertyData.property_id ? 'updated' : 'added'} successfully`, severity: 'success' });
  };

  const handleDeleteProperty = (propertyGroupId, propertyId) => {
    setWarehouse(prevWarehouse => ({
      ...prevWarehouse,
      sharedPropertyGroups: prevWarehouse.sharedPropertyGroups.map(group =>
        group.property_group_id === propertyGroupId
          ? { ...group, properties: group.properties.filter(prop => prop.property_id !== propertyId) }
          : group
      )
    }));
    setSnackbar({ open: true, message: 'Property deleted successfully', severity: 'success' });
  };

  const handleDeletePropertyGroup = (propertyGroupId) => {
    setWarehouse(prevWarehouse => ({
      ...prevWarehouse,
      sharedPropertyGroups: prevWarehouse.sharedPropertyGroups.filter(group => group.property_group_id !== propertyGroupId)
    }));
    setSnackbar({ open: true, message: 'Property Group deleted successfully', severity: 'success' });
  };

  const handleEditProperty = (propertyGroupId, propertyId) => {
    const propertyToEdit = warehouse.sharedPropertyGroups
      .find(group => group.property_group_id === propertyGroupId)
      .properties.find(prop => prop.property_id === propertyId);

    setCurrentProperty({
      ...propertyToEdit,
      property_group_id: propertyGroupId
    });
    setIsAddingProperty(true);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;
  
    if (!destination) return;
  
    const sourceGroupId = source.droppableId;
    const destGroupId = destination.droppableId;
  
    setWarehouse(prevWarehouse => {
      const newWarehouse = JSON.parse(JSON.stringify(prevWarehouse)); // Deep clone
      const sourceGroup = newWarehouse.sharedPropertyGroups.find(group => group.property_group_id === sourceGroupId);
      const destGroup = newWarehouse.sharedPropertyGroups.find(group => group.property_group_id === destGroupId);
  
      // Remove the dragged item from the source
      const [reorderedItem] = sourceGroup.properties.splice(source.index, 1);
  
      // Add the dragged item to the destination
      if (sourceGroupId === destGroupId) {
        sourceGroup.properties.splice(destination.index, 0, reorderedItem);
      } else {
        reorderedItem.property_group_id = destGroupId;
        destGroup.properties.splice(destination.index, 0, reorderedItem);
      }
  
      // Update the order property for all items in affected groups
      newWarehouse.sharedPropertyGroups.forEach(group => {
        group.properties.forEach((prop, index) => {
          prop.order = index + 1;
        });
      });
  
      return newWarehouse;
    });
  };

  const handleDeleteCategory = (categoryId) => {
    setWarehouse(prevWarehouse => {
      const newWarehouse = {
        ...prevWarehouse,
        categories: prevWarehouse.categories.filter(category => category.category_id !== categoryId)
      };
      if (selectedCategory === categoryId) {
        setSelectedCategory(newWarehouse.categories.length > 0 ? newWarehouse.categories[0].category_id : null);
      }
      return newWarehouse;
    });
    setSnackbar({ open: true, message: 'Category deleted successfully', severity: 'success' });
  };

  const handleSaveWarehouse = async () => {
    if (!warehouse.name) {
      setSnackbar({ open: true, message: 'Warehouse name is required', severity: 'error' });
      return;
    }
    setIsLoading(true);
  
    try {
      const warehouseToSave = {
        ...warehouse,
        public: warehouse.public, // Explicitly include the public property
        categories: warehouse.categories.map(category => ({
          ...category,
          property_groups: warehouse.sharedPropertyGroups.map(group => ({
            ...group,
            properties: group.properties.map(property => ({
              ...property,
              property_id: property.property_id.toString() // Ensure property_id is a string
            }))
          }))
        }))
      };
      delete warehouseToSave.sharedPropertyGroups;
  
      let response;
      if (isEditing) {
        response = await axios.put(`${API_BASE_URL}/warehouses/${warehouseId}/`, warehouseToSave, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }
        });
      } else {
        response = await axios.post(`${API_BASE_URL}/warehouses/`, warehouseToSave, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }
        });
      }
      console.log('Warehouse saved:', response.data);
      setSnackbar({ open: true, message: `Warehouse ${isEditing ? 'updated' : 'saved'} successfully`, severity: 'success' });
      navigate('/warehouse');
    } catch (error) {
      console.error('Error saving warehouse:', error);
      setSnackbar({ open: true, message: 'Error saving warehouse', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllFields = useMemo(() => {
    return warehouse.sharedPropertyGroups.flatMap(group => 
      group.properties.map(property => ({
        property_id: property.property_id,
        name: property.name,
      }))
    );
  }, [warehouse.sharedPropertyGroups]);
  
  const renderPreview = () => (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>Anteprima</Typography>
      {warehouse.categories.map((category) => (
        <Paper key={category.category_id} elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6">{category.name}</Typography>
          {category.property_groups.map((group) => (
            <Box key={group.property_group_id} sx={{ mb: 3 }}>
              <Typography variant="subtitle1">{group.name}</Typography>
              <Typography variant="body2" gutterBottom>{group.description}</Typography>
              {group.properties.map((property) => (
                <Box key={property.property_id} sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    label={property.name}
                    type={property.property_type}
                    required={property.required}
                    disabled={true}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Paper>
      ))}
    </Box>
  );
  

  return (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.main }}>
     <Grid container spacing={2} component="main" sx={{ p: {xs: 4, md: 10}, mt: {xs: 10, md: 0} }}>

    {/* Titolo della pagina (dinamico) */}

     <Box sx={{ mb: {xs: 5, md: 10}}}>
            <Button onClick={() => navigate('/warehouse')} startIcon={<KeyboardBackspaceRounded />}>Indietro</Button>
            <Typography variant="h4" gutterBottom>
              {warehouse.name || "Inserisci un titolo..."}
            </Typography>
          </Box> 


      <Grid container>
        <Container sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 5 }}>
            <Card sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'column'}, gap: 5, p: 3, mb: 3 }}>

      {/* Impostazioni Generali */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}}>
                <Bolt size={50} />
                <Typography variant="h4">Impostazioni Generali</Typography>
              </Box>
              <Typography color="#858585">Titolo e descrizione del magazzino.</Typography>
            </Box>

      {/*Titolo e descrizione  */}

      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField fullWidth label="Nome" value={warehouse.name} onChange={(e) => setWarehouse({ ...warehouse, name: e.target.value })}/>
        <TextField fullWidth label="Descrizione" value={warehouse.description} onChange={(e) => setWarehouse({ ...warehouse, description: e.target.value })} multiline rows={3}/>
       </Box>

      {/* Checkbox per rendere pubblico il magazzino */}


      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1}}>
        <Typography color="#858585">Rendi il form accessibile al pubblico</Typography>
        <FormControlLabel control={ <Checkbox checked={warehouse.public} onChange={(e) => setWarehouse({ ...warehouse, public: e.target.checked })} /> } label="Pubblico" />
      </Box>

      <Divider variant="middle" />

      {/* Pagine e gruppi */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}}>
            <StickyNote size={50} />
            <Typography variant="h4">Impostazioni Pagina</Typography>
          </Box>
        <Typography color="#858585">Le pagine dei magazzini utilizzate per differenziare le sedi.</Typography>
      </Box>
      
      <Box sx={{ mb: 2 }}>
      <Typography color="#858585" sx={{ mb: 1 }}>Crea le tue sedi qui.</Typography>
        {warehouse.categories.map(category => (
          <Chip
            key={category.category_id}
            label={category.name}
            onClick={() => setSelectedCategory(category.category_id)}
            onDelete={() => handleDeleteCategory(category.category_id)}
            color={category.category_id === selectedCategory ? 'primary' : 'default'}
          />
        ))}
        <Button startIcon={<Add />} onClick={handleAddCategory}>
          Aggiungi magazzino
        </Button>
      </Box>

          


      <Box>
        <TextField
          label="Nome Categoria"
          value={warehouse.categories.find(category => category.category_id === selectedCategory)?.name || ''}
          onChange={(e) => setWarehouse(prevWarehouse => ({
            ...prevWarehouse,
            categories: prevWarehouse.categories.map(category =>
              category.category_id === selectedCategory
              ? { ...category, name: e.target.value }
              : category
            )
          }))}
          fullWidth
          margin="normal"
        />

        <TextField
          fullWidth
          label="Descrizione Categoria"
          value={warehouse.categories.find(category => category.category_id === selectedCategory)?.description || ''}
          onChange={(e) => setWarehouse(prevWarehouse => ({
            ...prevWarehouse,
            categories: prevWarehouse.categories.map(category =>
              category.category_id === selectedCategory
              ? { ...category, description: e.target.value }
                : category
              )
          }))}
          sx={{ mb: 6 }}
        />

      <Divider variant="middle" sx={{ mb: 6 }}/>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Proprietà personalizzate dei prodotti</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddPropertyGroup}
          >
            Aggiungi gruppo di Proprietà
          </Button>
        </Box>

        <DragDropContext onDragEnd={handleDragEnd}>
          {warehouse.sharedPropertyGroups.map(group => (
            <Droppable key={group.property_group_id} droppableId={group.property_group_id}>
              {(provided) => (
                <Paper elevation={0} sx={{ p: 2, mb: 2, border: '2px solid #4895EF' }} ref={provided.innerRef} {...provided.droppableProps}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle1">{group.name}</Typography>
                    <Box sx={{ display: 'flex', gap: 5 }}>
                      <Tooltip title="Edit property group">
                        <IconButton size="small" onClick={() => handleEditPropertyGroup(group.property_group_id)}>
                          <Pencil />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete property group">
                        <IconButton size="small" onClick={() => handleDeletePropertyGroup(group.property_group_id)}>
                          <Trash2 />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <List>
                    {group.properties?.map((property, index) => (
                      <Draggable key={property.property_id.toString()} draggableId={property.property_id.toString()} index={index}>
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListItemText
                              primary={property.name}
                              secondary={`Type: ${property.property_type} | ${property.required ? 'Required' : 'Optional'}`}
                            />
                            <Box sx={{ display: 'flex', gap: 5 }}>
                              <Tooltip title="Edit property">
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditProperty(group.property_group_id, property.property_id)}>
                                  <Pencil />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete property">
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteProperty(group.property_group_id, property.property_id)}>
                                  <Trash2 />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Drag to reorder">
                                <IconButton>
                                  <DragIndicator />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                  <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => handleAddProperty(group.property_group_id)}
                    sx={{ mt: 1 }}
                  >
                    Aggiungi proprietà
                  </Button>
                </Paper>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 3, alignSelf: 'end' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveWarehouse}
        startIcon={<Save />}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : (isEditing ? 'Salva' : 'Salva')}
      </Button>
    </Box>
        </Card>
      </Container>
    </Grid>
    

    {isPreviewMode && renderPreview()}

    <PropertyDialog
      isOpen={isAddingProperty}
      onClose={() => {
        setIsAddingProperty(false);
        setCurrentProperty({
          property_id: '',
          name: '',
          description: '',
          property_type: 'text',
          options: [],
          required: false,
          property_group_id: '',
        });
      }}
      property={currentProperty}
      setProperty={setCurrentProperty}
      onSave={handleSaveProperty}
      propertyGroups={warehouse.sharedPropertyGroups}
    />

    <Dialog open={isAddingCategory} onClose={() => setIsAddingCategory(false)}>
      <DialogTitle>Aggiungi magazzino</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nome magazzino"
          type="text"
          fullWidth
          value={currentCategory.name}
          onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsAddingCategory(false)}>Cancella</Button>
        <Button onClick={handleSaveCategory}>Aggiungi magazzino</Button>
      </DialogActions>
    </Dialog>

    <PropertyGroupDialog
      isOpen={isAddingPropertyGroup || isEditingPropertyGroup}
      onClose={() => {
        setIsAddingPropertyGroup(false);
        setIsEditingPropertyGroup(false);
      }}
      propertyGroup={currentPropertyGroup}
      setPropertyGroup={setCurrentPropertyGroup}
      onSave={handleSavePropertyGroup}
      isEditing={isEditingPropertyGroup}
      fields={getAllFields}
      // fields={warehouse.sharedPropertyGroups.flatMap(group => group.properties)}
      categories={warehouse.categories || []}
    />

    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
    >
      <Alert
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
        sx={{ width: '100%' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
    </Grid>
  </Box>
  </ThemeProvider>);
};

export default WarehouseBuilder;