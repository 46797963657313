import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://admin.aitomotivelab.com/user_management/api';

export const fetchCompanies = async () => {
    try {
        const response = await axios.get(`${API_URL}/companies/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
    }
};

export const createCompany = async (companyData) => {
    try {
        const response = await axios.post(`${API_URL}/companies/`, companyData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating company:', error);
        throw error;
    }
};

export const updateCompany = async (companyId, companyData) => {
    try {
        const response = await axios.put(`${API_URL}/companies/${companyId}/`, companyData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating company:', error);
        throw error;
    }
};

export const deleteCompany = async (companyId) => {
    try {
        await axios.delete(`${API_URL}/companies/${companyId}/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
    } catch (error) {
        console.error('Error deleting company:', error);
        throw error;
    }
};

export const fetchCurrentUserRole = async () => {
    try {
        const response = await axios.get(`${API_URL}/users/current_user_role/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching current user role:', error);
        throw error;
    }
};

export const uploadLogo = async (companyId, logoFile) => {
    try {
      const formData = new FormData();
      formData.append('logo', logoFile);
  
      const response = await axios.post(`${API_URL}/companies/${companyId}/upload_logo/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error uploading logo:', error);
      throw error;
    }};


    export const fetchLocation = async () => {
        try {
          const response = await axios.get(`${API_URL}/locations/`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          return response.data;
        } catch (error) {
          console.error('Error fetching locations:', error);
          throw error;
        }
      };

export const getTaskProgress = async (taskId) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://admin.aitomotivelab.com/monitoring/task-progress/${taskId}/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        console.log("PORCODIO",response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching task progress:', error);
        throw error;
    }
};