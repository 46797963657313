import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Grid, Box, Typography, List, ListItem, ListItemText, 
  ListItemSecondaryAction, IconButton, Button, Dialog, DialogTitle, 
  DialogContent, DialogActions, CircularProgress, Snackbar, Alert, 
  createTheme, ThemeProvider, CssBaseline, Card, CardContent, Grow, 
  TextField, Fab, Zoom, Switch, FormControlLabel, Tooltip, AppBar, Toolbar, Collapse
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { Trash2, Pencil, Bolt, StickyNote, Eye, Archive } from 'lucide-react';


const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    third: {
      main: '#4CC9F0',
    },
    background: {
      main: 'rgba(163, 174, 208, 0.05)'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#757575',


          '&: hover': {
            boxShadow: 'none',
            color: '#4895EF',
            backgroundColor: 'rgba(163, 174, 208, 0.05)',
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.05)',
        }
      },
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#4CC9F0' },
    secondary: { main: '#4895EF' },
    background: { default: '#1F2023', paper: '#2D2F34' },
  },
});

const API_BASE_URL = 'https://admin.aitomotivelab.com/gestionale/api';

const WarehouseList = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [warehouseToDelete, setWarehouseToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('alphabetical');
  const [darkMode, setDarkMode] = useState(false);
  const [showFab, setShowFab] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  useEffect(() => {
    fetchWarehouses();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    setShowFab(window.scrollY > 100);
  };

  const fetchWarehouses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/warehouses`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setWarehouses(response.data);
    } catch (error) {
      console.error('Error fetching warehouses:', error);
      setError('An error occurred while fetching warehouses.');
    } finally {
      setLoading(false);
    }
  };

  const sortWarehouses = (warehouses) => {
    return warehouses.sort((a, b) => {
      if (sortOrder === 'alphabetical') {
        return a.name.localeCompare(b.name);
      } else if (sortOrder === 'creationDate') {
        return new Date(b.created_at) - new Date(a.created_at);
      }
      return 0;
    });
  };

  const handleDeleteClick = (warehouse) => {
    setWarehouseToDelete(warehouse);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/warehouses/${warehouseToDelete.id}/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
      setWarehouses(warehouses.filter(w => w.id !== warehouseToDelete.id));
      setDeleteDialogOpen(false);
      setWarehouseToDelete(null);
      setSnackbarMessage('Warehouse deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting warehouse:', err);
      setSnackbarMessage('Failed to delete warehouse');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredWarehouses = sortWarehouses(warehouses.filter(warehouse => 
    warehouse.name.toLowerCase().includes(searchTerm.toLowerCase())
  ));

  const theme = darkMode ? darkTheme : lightTheme;

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

        <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.main }}>
            <Grid container spacing={2} component="main" sx={{ p: { xs: 4, md: 10 }, mt: { xs: 10, md: 0 } }}>
              <Collapse in={checked} timeout={1000}>
                <Box sx={{ mb: { xs: 5, md: 10 } }}>
                  <Typography variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}> Magazzini </Typography>
                  <Typography variant="h7" sx={{ pl: 1, color: '#858585', display: { xs: 'none', md: 'block' } }}> Non ci sono notifiche da visualizzare </Typography>
                  <Button component={Link} to="/warehouses/new" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Crea Nuovo
                  </Button>
                </Box>
              </Collapse>

      {/* Barra di Ricerca */}


      <Grid container justifyContent="center">
              <Container sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Grow in={checked} timeout={1000}>
                <Box sx={{ width: '100%', borderRadius: '50%' }}>
                  <TextField
                    width="40px"
                    label="Cerca Magazzini"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Box>
                </Grow>

        {/* Ordinamento */}
                <Box spacing={2}>

                  <Button sx={{mr: 2}} md={2} variant={sortOrder === 'alphabetical' ? 'contained' : 'outlined'} onClick={() => setSortOrder('alphabetical')}>
                    Ordine Alfabetico
                  </Button>
                  <Button md={2} variant={sortOrder === 'creationDate' ? 'contained' : 'outlined'} onClick={() => setSortOrder('creationDate')}>
                    Ordina per Data
                  </Button>

                </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <AnimatePresence>
            <List>
              {filteredWarehouses.map((warehouse, index) => (
                <Grow in={checked} timeout={index * 200} key={warehouse.id}>
                  <Card sx={{ mb: 2, borderRadius: '16px', overflow: 'hidden' }}>
                    <CardContent>
                      <ListItem>
                        <ListItemText
                          primary={warehouse.name}
                          secondary={`Created: ${new Date(warehouse.created_at).toLocaleDateString()}`}
                        />
                        <ListItemSecondaryAction sx={{ display: 'flex', gap: 5 }}>
                          <Tooltip title="View Products">
                            <IconButton component={Link} to={`/warehouses/${warehouse.id}/products-by-category`}>
                              <Eye />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Warehouse">
                            <IconButton component={Link} to={`/warehouses/${warehouse.id}/edit`}>
                              <Pencil />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Warehouse">
                            <IconButton onClick={() => handleDeleteClick(warehouse)}>
                              <Trash2 />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View Inventory">
                            <IconButton component={Link} to={`/warehouses/${warehouse.id}/products`}>
                              <Archive />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </CardContent>
                  </Card>
                  </Grow>
              ))}
            </List>
          </AnimatePresence>
        )}

        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the warehouse "{warehouseToDelete?.name}"? This action cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="error">Confirm</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {/* Bottone Add New */}
        
        <Zoom in={true}>
          <Fab color="primary" aria-label="add" component={Link} to="/warehouses/new" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
            <Add />
          </Fab>
        </Zoom>

         </Container>
        </Grid>
      </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default WarehouseList;