import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button, Box, Typography, Tabs, Tab, Container, FormControl, Select, MenuItem, InputLabel, Grid, List, ListItem, ListItemText, IconButton
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';
import ServiceForm from './components/ServiceForm';
import ServiceList from './components/ServiceList';
import AvailabilityForm from './components/AvailabilityForm';
import AvailabilityList from './components/AvailabilityList';
import HolidayForm from './components/HolidayForm';
import HolidayList from './components/HolidayList';
import AddtionalInformationForm from './components/AddtionalInformationForm';
import AddtionalInformationList from './components/AddtionalInformationList';
import EmailTemplateForm from './components/EmailTemplateForm';
import EmailTemplateList from './components/EmailTemplateList';
import SnackbarNotification from './components/SnackbarNotification';
import TabPanel from './components/TabPanel';
import { useMediaQuery, useTheme } from '@mui/material';

const API_BASE_URL = 'https://admin.aitomotivelab.com/booking';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AvaibilityManagementPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [services, setServices] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [addtionalInformation, setAddtionalInformation] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [serviceFormData, setServiceFormData] = useState({ name: '', description: '', duration: '', price: '', color: '#FFFFFF' });
  const [availabilityFormData, setAvailabilityFormData] = useState({ day_of_week: '', start_time: '', end_time: '', max_slots: '' });
  const [holidayFormData, setHolidayFormData] = useState({ name: '', start_date: '', end_date: '', services: [] });
  const [addtionalInformationFormData, setAddtionalInformationFormData] = useState({ name: '', description: '' });
  const [emailTemplateFormData, setEmailTemplateFormData] = useState({
    service: '',
    channel: 'email',
    status: 'pending',
    subject: '',
    body: '',
  });
  const [isEditingService, setIsEditingService] = useState(false);
  const [isEditingAvailability, setIsEditingAvailability] = useState(false);
  const [isEditingHoliday, setIsEditingHoliday] = useState(false);
  const [isEditingAddtionalInformation, setIsEditingAddtionalInformation] = useState(false);
  const [isEditingEmailTemplate, setIsEditingEmailTemplate] = useState(false);
  const [editServiceId, setEditServiceId] = useState(null);
  const [editAvailabilityId, setEditAvailabilityId] = useState(null);
  const [editHolidayId, setEditHolidayId] = useState(null);
  const [editAddtionalInformationId, setEditAddtionalInformationId] = useState(null);
  const [editEmailTemplateId, setEditEmailTemplateId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchServices();
    fetchHolidays();
    fetchEmailTemplates();
  }, []);

  useEffect(() => {
    if (selectedService) {
      fetchAvailabilities(selectedService);
      fetchAddtionalInformation(selectedService);
    }
  }, [selectedService]);

  useEffect(() => {
    if (location.state?.editService) {
      const service = location.state.editService;
      setServiceFormData({
        name: service.name,
        description: service.description,
        duration: service.duration.replace('PT', '').replace('M', ''),
        price: service.price.toString(),
        color: service.color
      });
      setIsEditingService(true);
      setEditServiceId(service.id);
      setActiveTab(location.state.activeTab || 0);
    }
  }, [location.state]);

  const fetchServices = async () => {
    try {
      const response = await api.get('/services/');
      setServices(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching services', severity: 'error' });
    }
  };

  const fetchAvailabilities = async (serviceId) => {
    try {
      const response = await api.get(`/availabilities/?service=${serviceId}`);
      setAvailabilities(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching availabilities', severity: 'error' });
    }
  };

  const fetchHolidays = async () => {
    try {
      const response = await api.get('/holidays/');
      setHolidays(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching holidays', severity: 'error' });
    }
  };

  const fetchAddtionalInformation = async (serviceId) => {
    try {
      const response = await api.get(`/addtionalinformation/?service=${serviceId}`);
      setAddtionalInformation(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching additional information', severity: 'error' });
    }
  };

  const fetchEmailTemplates = async () => {
    try {
      const response = await api.get('/email-templates/');
      setEmailTemplates(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching email templates', severity: 'error' });
    }
  };

  const handleServiceFormChange = (event) => {
    const { name, value } = event.target;
    setServiceFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAvailabilityFormChange = (event) => {
    const { name, value } = event.target;
    setAvailabilityFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleHolidayFormChange = (event) => {
    const { name, value } = event.target;
    setHolidayFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddtionalInformationFormChange = (event) => {
    const { name, value } = event.target;
    setAddtionalInformationFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEmailTemplateFormChange = (event) => {
    const { name, value } = event.target;
    setEmailTemplateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleHolidayServiceChange = (event) => {
    const { value } = event.target;
    setHolidayFormData((prevData) => ({ ...prevData, services: value }));
  };

  const handleServiceFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const formattedData = {
        ...serviceFormData,
        duration: `PT${serviceFormData.duration}M`,
        price: parseFloat(serviceFormData.price),
        color: serviceFormData.color,
      };

      if (isEditingService) {
        await api.put(`/services/${editServiceId}/`, formattedData);
        setSnackbar({ open: true, message: 'Service updated successfully!', severity: 'success' });
      } else {
        await api.post('/services/', formattedData);
        setSnackbar({ open: true, message: 'Service added successfully!', severity: 'success' });
      }
      fetchServices();
      resetServiceForm();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting service', severity: 'error' });
    }
  };

  const handleAvailabilityFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const formattedData = {
        ...availabilityFormData,
        service: selectedService,
        day_of_week: parseInt(availabilityFormData.day_of_week),
        max_slots: parseInt(availabilityFormData.max_slots),
      };

      if (isEditingAvailability) {
        await api.put(`/availabilities/${editAvailabilityId}/`, formattedData);
        setSnackbar({ open: true, message: 'Availability updated successfully!', severity: 'success' });
      } else {
        await api.post('/availabilities/', formattedData);

        setSnackbar({ open: true, message: 'Availability added successfully!', severity: 'success' });
      }
      fetchAvailabilities(selectedService);
      resetAvailabilityForm();
    } catch (error) {
      console.log(error)
      setSnackbar({ open: true, message: 'Error submitting availability', severity: 'error' });
    }
  };

  const handleHolidayFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const holidayData = {
        ...holidayFormData,
        services: holidayFormData.services,
      };

      if (isEditingHoliday) {
        await api.put(`/holidays/${editHolidayId}/`, holidayData);
        setSnackbar({ open: true, message: 'Holiday updated successfully!', severity: 'success' });
      } else {
        await api.post('/holidays/', holidayData);
        setSnackbar({ open: true, message: 'Holiday added successfully!', severity: 'success' });
      }
      fetchHolidays();
      resetHolidayForm();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting holiday', severity: 'error' });
    }
  };

  const handleAddtionalInformationFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const formattedData = {
        ...addtionalInformationFormData,
        service: selectedService,
      };

      if (isEditingAddtionalInformation) {
        await api.put(`/addtionalinformation/${editAddtionalInformationId}/`, formattedData);
        setSnackbar({ open: true, message: 'Information updated successfully!', severity: 'success' });
      } else {
        await api.post('/addtionalinformation/', formattedData);
        setSnackbar({ open: true, message: 'Information added successfully!', severity: 'success' });
      }
      fetchAddtionalInformation(selectedService);
      resetAddtionalInformationForm();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting information', severity: 'error' });
    }
  };

  const handleEmailTemplateFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isEditingEmailTemplate) {
        await api.put(`/email-templates/${editEmailTemplateId}/`, emailTemplateFormData);
        setSnackbar({ open: true, message: 'Email template updated successfully!', severity: 'success' });
      } else {
        await api.post('/email-templates/', emailTemplateFormData);
        setSnackbar({ open: true, message: 'Email template added successfully!', severity: 'success' });
      }
      fetchEmailTemplates();
      resetEmailTemplateForm();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting email template', severity: 'error' });
    }
  };

  const handleEditService = (service) => {
    const durationInMinutes = service.duration.split(':').reduce((acc, time) => (60 * acc) + +time);

    setServiceFormData({
      name: service.name,
      description: service.description,
      duration: durationInMinutes.toString(),
      price: service.price.toString(),
      color: service.color,
    });
    setIsEditingService(true);
    setEditServiceId(service.id);
  };

  const handleEditAvailability = (availability) => {
    setAvailabilityFormData({
      day_of_week: availability.day_of_week.toString(),
      start_time: availability.start_time,
      end_time: availability.end_time,
      max_slots: availability.max_slots.toString(),
    });
    setIsEditingAvailability(true);
    setEditAvailabilityId(availability.id);
  };

  const handleEditHoliday = (holiday) => {
    setHolidayFormData({
      name: holiday.name,
      start_date: holiday.start_date,
      end_date: holiday.end_date,
      services: holiday.services,
    });
    setIsEditingHoliday(true);
    setEditHolidayId(holiday.id);
  };

  const handleEditAddtionalInformation = (info) => {
    setAddtionalInformationFormData({
      name: info.name,
      description: info.description,
    });
    setIsEditingAddtionalInformation(true);
    setEditAddtionalInformationId(info.id);
  };

  const handleEditEmailTemplate = (template) => {
    setEmailTemplateFormData({
      service: template.service,
      channel: template.channel,
      status: template.status,
      subject: template.subject,
      body: template.body,
    });
    setIsEditingEmailTemplate(true);
    setEditEmailTemplateId(template.id);
  };

  const handleDeleteService = async (id) => {
    try {
      await api.delete(`/services/${id}/`);
      setSnackbar({ open: true, message: 'Service deleted successfully!', severity: 'success' });
      fetchServices();
      if (selectedService === id) {
        setSelectedService('');
        setAvailabilities([]);
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting service', severity: 'error' });
    }
  };

  const handleDeleteAvailability = async (id) => {
    try {
      await api.delete(`/availabilities/${id}/`);
      setSnackbar({ open: true, message: 'Availability deleted successfully!', severity: 'success' });
      fetchAvailabilities(selectedService);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting availability', severity: 'error' });
    }
  };

  const handleDeleteHoliday = async (id) => {
    try {
      await api.delete(`/holidays/${id}/`);
      setSnackbar({ open: true, message: 'Holiday deleted successfully!', severity: 'success' });
      fetchHolidays();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting holiday', severity: 'error' });
    }
  };

  const handleDeleteAddtionalInformation = async (id) => {
    try {
      await api.delete(`/addtionalinformation/${id}/`);
      setSnackbar({ open: true, message: 'Information deleted successfully!', severity: 'success' });
      fetchAddtionalInformation(selectedService);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting information', severity: 'error' });
    }
  };

  const handleDeleteEmailTemplate = async (id) => {
    try {
      await api.delete(`/email-templates/${id}/`);
      setSnackbar({ open: true, message: 'Email template deleted successfully!', severity: 'success' });
      fetchEmailTemplates();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting email template', severity: 'error' });
    }
  };

  const resetServiceForm = () => {
    setServiceFormData({ name: '', description: '', duration: '', price: '', color: '#FFFFFF' });
    setIsEditingService(false);
    setEditServiceId(null);
  };

  const resetAvailabilityForm = () => {
    setAvailabilityFormData({ day_of_week: '', start_time: '', end_time: '', max_slots: '' });
    setIsEditingAvailability(false);
    setEditAvailabilityId(null);
  };

  const resetHolidayForm = () => {
    setHolidayFormData({ name: '', start_date: '', end_date: '', services: [] });
    setIsEditingHoliday(false);
    setEditHolidayId(null);
  };

  const resetAddtionalInformationForm = () => {
    setAddtionalInformationFormData({ name: '', description: '' });
    setIsEditingAddtionalInformation(false);
    setEditAddtionalInformationId(null);
  };

  const resetEmailTemplateForm = () => {
    setEmailTemplateFormData({
      service: '',
      channel: 'email',
      status: 'pending',
      subject: '',
      body: '',
    });
    setIsEditingEmailTemplate(false);
    setEditEmailTemplateId(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const dayOfWeekOptions = [
    { value: 0, label: 'Monday' },
    { value: 1, label: 'Tuesday' },
    { value: 2, label: 'Wednesday' },
    { value: 3, label: 'Thursday' },
    { value: 4, label: 'Friday' },
    { value: 5, label: 'Saturday' },
    { value: 6, label: 'Sunday' },
  ];

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        p: isMobile ? 1 : 2,
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <Box sx={{ 
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <IconButton 
          edge="start"
          onClick={() => navigate('/booking')}
          sx={{ display: isMobile ? 'flex' : 'none' }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={isMobile ? 'h6' : 'h5'}>
          Calendar Settings
        </Typography>
      </Box>

      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons={isMobile ? "auto" : false}
        sx={{
          mb: 2,
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            fontSize: isMobile ? '0.875rem' : 'inherit',
            minWidth: isMobile ? 'auto' : 120,
            px: isMobile ? 2 : 3
          }
        }}
      >
        <Tab label="Services" />
        <Tab label="Availability" />
        <Tab label="Holidays" />
        <Tab label="Additional Info" />
        <Tab label="Email Templates" />
      </Tabs>

      <Box sx={{ 
        '& .MuiFormControl-root': {
          mb: 2,
          width: '100%'
        },
        '& .MuiButton-root': {
          width: isMobile ? '100%' : 'auto',
          mb: isMobile ? 1 : 0
        }
      }}>
        <TabPanel value={activeTab} index={0}>
          <ServiceForm
            serviceFormData={serviceFormData}
            handleServiceFormChange={handleServiceFormChange}
            handleServiceFormSubmit={handleServiceFormSubmit}
            isEditingService={isEditingService}
            isMobile={isMobile}
          />
          <ServiceList
            services={services}
            handleEditService={handleEditService}
            handleDeleteService={handleDeleteService}
            isMobile={isMobile}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <FormControl fullWidth>
            <InputLabel id="service-select-label">Select Service</InputLabel>
            <Select
              labelId="service-select-label"
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedService && (
            <>
              <AvailabilityForm
                availabilityFormData={availabilityFormData}
                handleAvailabilityFormChange={handleAvailabilityFormChange}
                handleAvailabilityFormSubmit={handleAvailabilityFormSubmit}
                isEditingAvailability={isEditingAvailability}
                dayOfWeekOptions={dayOfWeekOptions}
              />
              <AvailabilityList
                availabilities={availabilities}
                handleEditAvailability={handleEditAvailability}
                handleDeleteAvailability={handleDeleteAvailability}
                dayOfWeekOptions={dayOfWeekOptions}
              />
            </>
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={2}>
          <HolidayForm
            holidayFormData={holidayFormData}
            handleHolidayFormChange={handleHolidayFormChange}
            handleHolidayServiceChange={handleHolidayServiceChange}
            handleHolidayFormSubmit={handleHolidayFormSubmit}
            isEditingHoliday={isEditingHoliday}
            services={services}
          />
          <HolidayList
            holidays={holidays}
            handleEditHoliday={handleEditHoliday}
            handleDeleteHoliday={handleDeleteHoliday}
            services={services}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={3}>
          <FormControl fullWidth>
            <InputLabel id="service-select-label">Select Service</InputLabel>
            <Select
              labelId="service-select-label"
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedService && (
            <>
              <AddtionalInformationForm
                addtionalInformationFormData={addtionalInformationFormData}
                handleAddtionalInformationFormChange={handleAddtionalInformationFormChange}
                handleAddtionalInformationFormSubmit={handleAddtionalInformationFormSubmit}
                isEditingAddtionalInformation={isEditingAddtionalInformation}
              />
              <AddtionalInformationList
                addtionalInformation={addtionalInformation}
                handleEditAddtionalInformation={handleEditAddtionalInformation}
                handleDeleteAddtionalInformation={handleDeleteAddtionalInformation}
              />
            </>
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={4}>
        <EmailTemplateForm
          emailTemplateFormData={emailTemplateFormData}
          handleEmailTemplateFormChange={handleEmailTemplateFormChange}
          handleEmailTemplateFormSubmit={handleEmailTemplateFormSubmit}
          isEditingEmailTemplate={isEditingEmailTemplate}
          services={services}
        />
        <EmailTemplateList
          emailTemplates={emailTemplates}
          handleEditEmailTemplate={handleEditEmailTemplate}
          handleDeleteEmailTemplate={handleDeleteEmailTemplate}
          services={services}
        />
      </TabPanel>
      </Box>

      <SnackbarNotification snackbar={snackbar} handleSnackbarClose={handleSnackbarClose} />
    </Container>
  );
};

export default AvaibilityManagementPage;