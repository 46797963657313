import axios from 'axios';

const API_URL = 'https://admin.aitomotivelab.com/user_management/api/';

export const fetchRoles = async () => {
  const response = await axios.get(`${API_URL}roles/`);
  console.log("fetchRoles",response)
  return response.data;
};

export const createRole = async (roleData) => {
  const response = await axios.post(`${API_URL}roles/`, roleData);
  return response.data;
};

export const updateRole = async (id, roleData) => {
  console.log("CIAOCIAO",roleData)

  const response = await axios.put(`${API_URL}roles/${id}/`, roleData);
  return response.data;
};

export const deleteRole = async (id) => {
  await axios.delete(`${API_URL}roles/${id}/`);
};

export const fetchCurrentUserRole = async () => {
  const response = await axios.get(`${API_URL}current_user_role/`);
  return response.data;
};

export const fetchCompanies = async () => {
  const response = await axios.get(`${API_URL}companies/`);
  return response.data;
};

export const fetchAvailableApps = async () => {
  const response = await axios.get(`${API_URL}apps/`);
  return response.data;
};

export const fetchCarBrands = async () => {
  const response = await axios.get(`${API_URL}car_brands/`);
  return response.data;
};

export const fetchCustomerCategories = async (companyId) => {
  const response = await axios.get(`${API_URL}customer_categories/${companyId ? `?company=${companyId}` : ''}`);
  return response.data;
};

export const fetchRegistrationFields = async () => {
  const response = await axios.get(`${API_URL}registration_fields/`);
  return response.data;
};

export const fetchDistinctValues = async (field, companyId) => {
  try {
    const response = await axios.get(
      `${API_URL}car_stock_distinct_values/${field}/${companyId ? `?company=${companyId}` : ''}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching distinct values for ${field}:`, error);
    return [];
  }
};

// New functions to fetch filter types and operators

export const fetchFilterTypes = async () => {
  try {
    const response = await axios.get(`${API_URL}custom-filters/filter_types/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching filter types:', error);
    return {};
  }
};

export const fetchOperators = async () => {
  try {
    const response = await axios.get(`${API_URL}custom-filters/operators/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching operators:', error);
    return {};
  }
};

// Alternative: Fetch both filter types and operators in one request

export const fetchFilterOptions = async () => {
  try {
    const response = await axios.get(`${API_URL}custom-filters/filter_options/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching filter options:', error);
    return { filter_types: {}, operators: {} };
  }
};

export const fetchLocation = async (companyId) => {
  const response = await axios.get(
    `${API_URL}locations/${companyId ? `?company_id=${companyId}` : ''}`
  );
  return response.data;
};